import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  certificateFix: {
    width: 914,
    height: 646,
    margin: '50px auto',
    position: "relative",

    '& img':{
      display: 'block',
      width: '100%',
    }
  },
  infosCertificate: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  studentCertificate: {
    display: 'block',
    margin: '0 auto',
    marginTop: 280,
    fontSize: 35,
    textTransform: "uppercase",
  },
  courseCertificate: {
    display: 'flex',
    flexDirection: "column",
    '& span': {
      margin: '0 auto',
      marginTop: 16,
      marginLeft: 395,
      fontSize: 18,
      textTransform: "uppercase",

      '&:last-child':{
        marginTop: 7,
      }
    },
    '& > span > label': {
      fontWeight: 'bold'
    },
  },
  dateCertificate: {
    display: 'flex',
    flexDirection: "column",
    marginLeft: 315,
    marginTop: 28,
    '& > span:first-child': {
      fontWeight: 700,
      fontSize: 25,
    }
  },
  btnPrint: {
    backgroundColor: '#F47133',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#E84E07'
    }
  }
}))

export default useStyles
