import React, { useEffect, useState } from 'react'

import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core'

import Profile from '~/Components/Profile'

import useStyles from './style'
import { store } from '~/store'
import api from '~/Services/api'

import { format } from 'date-fns'
import { DateTime } from "luxon";
DateTime.local().setZone('America/Sao_Paulo')

const CoursesITaught = () => {
  const [courses, setCourses] = useState([])
  const [notFound, setNorfound] = useState(false)

  const { profile } = store.getState().user

  const getCourses = async () => {
    const res = await api.get(`/teacher_courses_realized/${profile.id}`)
    if (res.data.length > 0) {
      setCourses(res.data)
    } else {
      setNorfound(true)
    }
  }

  useEffect(() => {
    getCourses()
  }, [])

  const style = useStyles()
  return (
    <Profile title="Aulas realizadas" >
      {!notFound ?
        <TableContainer className={style.root}>
          <Table >
            <TableHead>
              <TableRow className={style.headerTable}>
                <TableCell align="center">Nome do curso</TableCell>
                <TableCell align="center">data do curso</TableCell>
                <TableCell align="center">Horario</TableCell>
                <TableCell align="center">Total de vagas</TableCell>
                <TableCell align="center">Total de inscritos</TableCell>
                <TableCell align="center">Alunos Presentes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={style.bodyTable}>
              {courses.map((course) => (
                <TableRow
                  key={course.id}
                >
                  <TableCell align="center" component="th" scope="row" className={style.textCapitalize}>
                    {course.name}
                  </TableCell>
                  <TableCell align="center">{format(new Date(course.date_of_course), 'dd/MM/yyyy')}</TableCell>
                  <TableCell align="center">{`${DateTime.fromSQL(course.hour_start).setLocale('pt').toFormat('HH:mm')} há ${DateTime.fromSQL(course.hour_end).setLocale('pt').toFormat('HH:mm')}`}</TableCell>
                  <TableCell align="center">{course.totalQuantity}</TableCell>
                  <TableCell align="center">{course.quantity}</TableCell>
                  <TableCell align="center">{course?.quantityPresent || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        :
        <p className={style.message}>Você não possui nenhum cursos</p>
      }
    </Profile>
  )
}

export default CoursesITaught
