import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import TableCategory from '~/Components/Tables/TableCategory';

export default function ListCategory() {

  return (
    <DashAdmin content={<TableCategory />} title={'Lista de Categorias'} width={'650px'} maxWidth={'100%'} />
  );
}
