import React from 'react'
import './styles.css'
import logoWhite from '~/Assets/img/logo-white.svg'
import temporaryBanner from '~/Assets/img/temporary-banner.png'
import instagram from '~/Assets/img/instagram.svg'
import youtube from '~/Assets/img/youtube.svg'
import shoppingCart from '~/Assets/img/shopping-cart.svg'

const title = 'Cursos de 2025 em construção!'

const message = 'Não se preocupe, voltaremos em Janeiro. Estamos aprimorando nosso site e nossos espaços de curso, para proporcionar uma experiência ainda melhor aos nossos alunos!'

const links = [
  {
    name: 'lives',
    label: 'Acompanhe nossas lives',
    css: {
      background: 'linear-gradient(180deg, #E75989 0%, #E1306C 100%)'
    },
    image: instagram,
    url: 'https://instagram.com/lojasantoantonio/'
  },
  {
    name: 'youtube',
    label: 'Inscreva-se no Youtube',
    css: {
      background: 'linear-gradient(180deg, #FF3333 0%, #FF0000 100%)',
    },
    image: youtube,
    url: 'https://www.youtube.com/user/lojasantoantonio/'
  },
  {
    name: 'lives',
    label: 'Compre em nossa loja virtual',
    css: {
      background: 'linear-gradient(180deg, #FF710A 0%, #E66508 100%)',
      gridColumn: '1 / span 2'
    },
    image: shoppingCart,
    url: 'https://www.lojasantoantonio.com.br/'
  }
]

export default function CheckBox() {
  return (
    <div className="temporary-container" style={{ backgroundImage: `url(${temporaryBanner})`}}>
      <img src={logoWhite} alt='Logo' className='temporary-logo' />
      <h1 className='temporary-title'>{ title }</h1>
      <h2 className='temporary-message'>{ message }</h2>
      <div className='temporary-links-list'>
        { links.map((link) => 
            <a href={link.url} alt={link.name} target="_blank" className="temporary-link" style={link.css}>
              <img src={link.image} />
              <span>{link.label}</span>
            </a>
          )
        }
      </div>
    </div>
  )
}
