import React, { useState, useEffect } from 'react';
import Header from '~/Components/Header';
import globalStyles from '~/Assets/css/globalStyles';
import { Container, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NewHeader from '~/Components/NewHeader';
import Footer from '~/Components/Footer';
import useStyles from './style'

export default function Questions() {
  const pattern = globalStyles()

  const [expanded, setExpanded] = useState(false);

  const handleExpansion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const style = useStyles()

  return (
    <>
      <NewHeader />
      <div className={`${pattern.content} ${style.content}`}>
        <Container maxWidth={'md'}>
          <h1 className={style.title}>Dúvidas</h1>
          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question1'} onChange={handleExpansion('question1')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question1-content"
              id="question1-header"
            >
              <Typography>Quanto tempo antes posso fazer minha inscrição?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                As inscrições iniciam 15 dias antes da data do curso. Por exemplo, se o curso for dia 20, as inscrições abrem dia 5.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question2'} onChange={handleExpansion('question2')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question2-content"
              id="question2-header"
            >
              <Typography className={''}>Como fazer inscrição para os Cursos?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Clique no curso que você deseja e faça seu cadastro em nosso agendamento on-line.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question3'} onChange={handleExpansion('question3')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question3-content"
              id="question3-header"
            >
              <Typography className={''}>Quais são as formas de pagamento?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Os pagamentos podem ser feitos em dinheiro, cartão de débito ou pix. Dependendo do professor, é possível fazer o pagamento parcelado no crédito.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question4'} onChange={handleExpansion('question4')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question4-content"
              id="question4-header"
            >
              <Typography className={''}>As aulas são práticas?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Algumas aulas são práticas, mas a maioria é teórica. Todas as aulas possuem identificação se é prática ou teórica.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question5'} onChange={handleExpansion('question5')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question5-content"
              id="question5-header"
            >
              <Typography className={''}>Tem degustação?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, os professores sempre servem o que é ensinado em aula.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question6'} onChange={handleExpansion('question6')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question6-content"
              id="question6-header"
            >
              <Typography className={''}>O que devo levar nos Cursos?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                A maioria dos Cursos são teóricos, pedimos que traga apenas uma caneta para fazer anotações na apostila de receitas que
                fornecemos. Caso seja necessário trazer algum material, os mesmos estarão relacionados na descrição do curso no momento
                da inscrição.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question7'} onChange={handleExpansion('question7')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question7-content"
              id="question7-header"
            >
              <Typography className={''}>Quanto tempo de tolerância para garantir minha vaga?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              O ideal é chegar pelo menos 15 minutos antes. Após o início do curso, damos 15 minutos de tolerância.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question8'} onChange={handleExpansion('question8')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question8-content"
              id="question8-header"
            >
              <Typography className={''}>Os Cursos são todos na loja física em São Paulo?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, ainda não temos cursos on-line.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question9'} onChange={handleExpansion('question9')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question9-content"
              id="question9-header"
            >
              <Typography className={''}>Qual o endereço da loja?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Rua Serra de Juréa, 736 – Bairro: Tatuapé - São Paulo/ SP
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question10'} onChange={handleExpansion('question10')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question10-content"
              id="question10-header"
            >
              <Typography className={''}>A partir de que idade pode fazer o curso?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                A partir de 12 anos de idade.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question11'} onChange={handleExpansion('question11')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question11-content"
              id="question11-header"
            >
              <Typography className={''}>Posso levar acompanhante ou meu filho menor de 12 anos?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Não é permitida a entrada de acompanhantes.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question12'} onChange={handleExpansion('question12')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question12-content"
              id="question12-header"
            >
              <Typography className={''}>Tem estacionamento?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Sim, possuímos um estacionamento próprio para clientes, localizado do lado da nossa loja. Mas é preciso consumir algo na loja e mostrar o comprovante para os nossos manobristas, para que o estacionamento seja gratuito.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question14'} onChange={handleExpansion('question14')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question14-content"
              id="question14-header"
            >
              <Typography className={''}>Posso tirar foto na aula?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                São permitidas apenas fotos sem flash com a autorização do professor.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question15'} onChange={handleExpansion('question15')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question15-content"
              id="question15-header"
            >
              <Typography className={''}>Quantos tempo dura o curso?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Os cursos teóricos duram em média, 2 horas e 30 minutos. Os cursos práticos variam. Mas as informações de início e término, estão na descrição de cada aula.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question16'} onChange={handleExpansion('question16')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question16-content"
              id="question16-header"
            >
              <Typography className={''}>Tem certificado?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, você pode emitir o seu certificado de participação após avaliar o curso em nosso site.
                Entre com o seu login e senha e clique no curso assistido para avaliar e imprimir o certificado.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question17'} onChange={handleExpansion('question17')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question17-content"
              id="question17-header"
            >
              <Typography className={''}>Posso marcar curso na loja?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Sim, você pode fazer sua inscrição na loja, mas caso o curso seja no mesmo dia, pode ser que não haja mais vagas.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question18'} onChange={handleExpansion('question18')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question18-content"
              id="question18-header"
            >
              <Typography className={''}>Posso marcar cursos antes dos 15 dias?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              Não, as vagas iniciam apenas nos 15 dias que antecedem o curso.

              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question19'} onChange={handleExpansion('question19')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question19-content"
              id="question19-header"
            >
              <Typography className={''}>Posso ir sem marcar e tentar uma vaga?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, às vezes ocorrem desistências ou faltas. Mas não podemos garantir essa informação.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question20'} onChange={handleExpansion('question20')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question20-content"
              id="question20-header"
            >
              <Typography className={''}>Se eu não puder ir ao curso, preciso desmarcar? Quanto tempo antes?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, o ideal é desmarcar até com 24h de antecedência para que outra pessoa que esteja interessada no curso possa
                comparecer. Caso você falte sem avisar em até 3 cursos a sua conta vai ser desabilitada, impedindo novas inscrições.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question21'} onChange={handleExpansion('question21')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question21-content"
              id="question21-header"
            >
              <Typography className={''}>Quantos alunos cabem na sala?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
              A sala comporta até 50 alunos.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question22'} onChange={handleExpansion('question22')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question22-content"
              id="question22-header"
            >
              <Typography className={''}>Fazem lista de espera?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Apenas presencialmente no dia do curso, caso tenha vagas, liberamos por ordem de chegada.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question23'} onChange={handleExpansion('question23')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question23-content"
              id="question23-header"
            >
              <Typography className={''}>Vocês ligam para confirmar o curso?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Não, no momento da inscrição você recebera um e-mail confirmando a sua vaga.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question24'} onChange={handleExpansion('question24')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question24-content"
              id="question24-header"
            >
              <Typography className={''}>Vocês fazem atestado de horas para a Faculdade?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, disponibilizamos um certificado de participação.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question25'} onChange={handleExpansion('question25')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question25-content"
              id="question25-header"
            >
              <Typography className={''}>Nos cursos grátis precisa levar alguma coisa?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Sim, pedimos a doação de alimentos que são doados para uma instituição carente em São Paulo no bairro da Vila Brasilândia.
                O alimento é solicitado no momento da inscrição.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel className={style.expansionPanel} expanded={expanded === 'question26'} onChange={handleExpansion('question26')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="question26-content"
              id="question26-header"
            >
              <Typography className={''}>Os Cursos são para profissionais ou iniciantes?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                São para todos os públicos, como são cursos livres, eles não exigem um conhecimento aprofundado.
                Contamos com alunos que estão iniciando no ramo e também Chefs profissionais que buscam novidades,
                tendências e reciclagem.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

        </Container>
      </div>
      <Footer />
    </>
  )
}
