import React, { useState, useEffect } from 'react';

import api from '~/Services/api';

import { TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Table, Avatar, makeStyles,Box, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography } from '@material-ui/core';
import { Edit, ArrowBack, ArrowForward } from '@material-ui/icons';
import { CustomButton } from '../Custom';

const useStyles = makeStyles(theme => ({
  areaBtnProgress: {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
  },
  btnOrange: {
    backgroundColor: 'orange',
    color: '#fff',
    cursor: 'pointer',
    minWidth: 157,
    height: 40,
    '&:hover': {
      backgroundColor: '#ff8800'
    },
  },
  loadingPass: {
    width: '28px !important',
    height: '28px !important',
    margin: '6px 8px',
    position: 'absolute',
    left: 50,
    display: 'none',
    color: '#fff'
  },

  edit: {
    fill: '#ff9800'
  },

  tableHeader: {

    '& .MuiTableRow-head': {
      background: '#d01717',

      '& .MuiTableCell-head': {
        color: '#fff',
        fontSize: 18,
        fontWeight: '400',
      }
    },
  },

  message: {
    textAlign: 'center',
    padding: '25px 0',
    fontSize: '4vw',

  }
}))

export default function TableTeacher() {
  const classe = useStyles()
  const [teacher, setTeacher] = useState([])
  const [configTable, setConfigTable] = useState({
    page: 1,
    pages: 0,
    limit: 10,
  })
  const [fullnameTeacher, setFullnameTeacher] = useState('')
  const [notFound, setNotFound] = useState(false)

  const maskCpf = (cpf) =>
    cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  async function getTeacher() {
    const res = await api.get(`users?type_user=teacher&page=${configTable.page}&limit=${configTable.limit}`)
    setConfigTable({ ...configTable, pages: res.data.pages })

    setTeacher(res.data.users)
  }

  function handlePrevNext(type) {
    if (type === 'prev') {
      setConfigTable({ ...configTable, page: --configTable.page })
    }
    if (type === 'next') {
      setConfigTable({ ...configTable, page: ++configTable.page })
    }
    getTeacher()
  }

  function handleLimit(value) {
    setConfigTable({ ...configTable, limit: value })
  }

  async function handleSearch(e) {
    const { value } = e.target
    setFullnameTeacher(value)
    const teacher = await api.get(`users?type_user=teacher&page=${configTable.page}&limit=${configTable.limit}&searchFullname=${fullnameTeacher}`)
    if (teacher.data.users.length > 0) {
      setNotFound(false)
      setConfigTable({ ...configTable, pages: teacher.data.pages })
      setTeacher(teacher.data.users)
    } else {
      setNotFound(true)
    }
  }

  // async function imunizationStudent(id, status) {
  //   await api.put(`users/${id}`, { status })
  //   getStudent()
  // }

  // async function requestPassword(e, index, cpf) {
  //   const area = document.querySelectorAll(`.btn_progress`)
  //   area[index].children[1].style.display = 'block'
  //   area[index].children[0].innerText = ''
  //   const res = await api.post('forgot_password', { cpf })
  //   setTimeout(() => {
  //     if(res.data.sucess){
  //       area[index].children[1].style.display = 'none'
  //       area[index].children[0].innerText = 'enviado'
  //       area[index].children[0].style.backgroundColor = 'green'
  //     }
  //   }, 2000);
  // }

  useEffect(() => {
    getTeacher()
  }, [])


  useEffect(() => {
    getTeacher()
  }, [configTable.limit])


  return (
    <TableContainer component={Paper}>
      <Grid container>
        <Grid item xs={12} sm={4} md={3} style={{ padding: 15 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="limit">Limite por página</InputLabel>
            <Select
              labelId="limit"
              id="limit"
              value={configTable.limit}
              onChange={e => handleLimit(e.target.value)}
              label="Limite por página"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid className={classe.search} item xs={12} sm={4} style={{ padding: 15 }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              value={fullnameTeacher}
              onChange={(e) => handleSearch(e)}
              label="Buscar pelo nome"
              type="search"
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      {!notFound ?
        <Table>
          <TableHead className={classe.tableHeader}>
            <TableRow>
              <TableCell>Imagem</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>cpf</TableCell>
              <TableCell>Editar professsor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teacher.map((t) => (
              <TableRow key={t.cpf}>
                <TableCell><Avatar src={t.path_image} /></TableCell>
                <TableCell>{t.fullname}</TableCell>
                <TableCell>{t.email}</TableCell>
                <TableCell>{t.cpf}</TableCell>
                <TableCell>
                  <a href={`/admin/editar-professor/${t.id}`}><Edit className={classe.edit} /></a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        :
        <Typography className={classe.message}>Professor não encontrado</Typography>
      }
      {!notFound ?
        <Box display='flex' justifyContent='space-between' alignItems='center' style={{ padding: '15px 5px' }}>

          <CustomButton onClick={e => handlePrevNext('prev')} className={classe.btnPrevNext} disabled={configTable.page === 1}> <ArrowBack /> Anterior</CustomButton>

          <div>
            <span>{configTable.page}</span>/<span>{configTable.pages}</span>
          </div>

          <CustomButton onClick={e => handlePrevNext('next')} className={classe.btnPrevNext} disabled={configTable.page === configTable.pages} >Próximo <ArrowForward /></CustomButton>

        </Box>
        :
        ''
      }
    </TableContainer>
  );
}
