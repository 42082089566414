import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { signOut } from '~/store/modules/auth/actions';
// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Storefront from '@material-ui/icons/Storefront';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SettingsIcon from '@material-ui/icons/Settings';
import { Add } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: '#fff',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  titlePage: {
    fontSize: '20px',
    color: '#b01010',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#b01010',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    //maxWidth: '850px',
    margin: '0 auto',
  },
  btnVisitSite: {
    height: '63px',
  },
  btnColorPrimary: {
    color: '#b01010',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  menuLateral: {
    '& .MuiSvgIcon-root': {
      color: '#b01010',
    },
  },
}));

function DashAdmin({ content, title, container, width }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState({
    cursos: false,
    alunos: false,
    professores: false,
    depoimentos: false,
    categorias: false,
  });

  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCollapse = field => {
    setCollapse({ ...collapse, [field]: !collapse[field] });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const drawer = (
    <div className={classes.menuLateral}>
      <Box
        display="flex"
        className={classes.btnVisitSite}
        style={{ width: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          className={classes.btnColorPrimary}
          startIcon={<Storefront />}
          href="/"
        >
          Visualizar site
        </Button>
      </Box>
      <Divider />
      {/* Lista de presença */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('presença');
          }}
          button
        >
          <ListItemIcon>
            <DoneAllIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Presença'} />
          {collapse.presença ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.presença} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Listar curso */}
            <ListItemLink
              href="/admin/lista-de-presenca"
              className={classes.nested}
            >
              <ListItemIcon>
                <DoneAllIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Dar presença" />
            </ListItemLink>
          </List>
        </Collapse>
      </List>
      <Divider />
      {/* Cursos */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('cursos');
          }}
          button
        >
          <ListItemIcon>
            <ImportContactsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Cursos'} />
          {collapse.cursos ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.cursos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Cadastrar curso */}
            <ListItemLink href="/admin/novo-curso" className={classes.nested}>
              <ListItemIcon>
                <AddIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Novo Curso" />
            </ListItemLink>
            {/* Listar curso */}
            <ListItemLink
              href="/admin/listar-cursos"
              className={classes.nested}
            >
              <ListItemIcon>
                <FormatListBulletedIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Listar Curso" />
            </ListItemLink>
          </List>
        </Collapse>
      </List>
      <Divider />
      {/* Alunos */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('alunos');
          }}
          button
        >
          <ListItemIcon>
            <PeopleIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Alunos'} />
          {collapse.alunos ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.alunos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Listar curso */}
            <ListItemLink
              href="/admin/listar-alunos"
              className={classes.nested}
            >
              <ListItemIcon>
                <FormatListBulletedIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Listar Aluno" />
            </ListItemLink>
          </List>
        </Collapse>
      </List>
      <Divider />
      {/* Professores */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('professores');
          }}
          button
        >
          <ListItemIcon>
            <SchoolIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Professores'} />
          {collapse.professores ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.professores} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Cadastrar curso */}
            <ListItemLink
              href="/admin/novo-Professor"
              className={classes.nested}
            >
              <ListItemIcon>
                <AddIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Novo Professor" />
            </ListItemLink>
            {/* Listar curso */}
            <ListItemLink
              href="/admin/listar-professores"
              className={classes.nested}
            >
              <ListItemIcon>
                <FormatListBulletedIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Listar Professor" />
            </ListItemLink>
          </List>
        </Collapse>
      </List>
      <Divider />
      {/* Depoimentos */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('depoimentos');
          }}
          button
        >
          <ListItemIcon>
            <StarIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Depoimentos'} />
          {collapse.depoimentos ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.depoimentos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Listar curso */}
            <ListItemLink href="/admin/depoimentos" className={classes.nested}>
              <ListItemIcon>
                <FormatListBulletedIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Depoimentos" />
            </ListItemLink>
          </List>
        </Collapse>
      </List>
      <Divider />

      {/* Categorias */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('categorias');
          }}
          button
        >
          <ListItemIcon>
            <BookmarksIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Categorias'} />
          {collapse.categorias ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.categorias} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Listar curso */}
            <ListItemLink
              href="/admin/listar-categorias"
              className={classes.nested}
            >
              <ListItemIcon>
                <FormatListBulletedIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Listar Categorias" />
            </ListItemLink>
            <ListItemLink
              href="/admin/nova-categoria"
              className={classes.nested}
            >
              <ListItemIcon>
                <Add color="primary" />
              </ListItemIcon>
              <ListItemText primary="Nova Categoria" />
            </ListItemLink>
          </List>
        </Collapse>
      </List>
      <Divider />

      {/* Configurações */}
      <List>
        <ListItem
          onClick={() => {
            handleCollapse('configuracoes');
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={'Configurações'} />
          {collapse.configuracoes ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse.configuracoes} timeout="auto" unmountOnExit>
          <ListItemLink
            href="/admin/configuracoes/novo-banner"
            className={classes.nested}
          >
            <ListItemIcon>
              <Add color="primary" />
            </ListItemIcon>
            <ListItemText primary="Novo Banner Tv" />
          </ListItemLink>
          <ListItemLink
            href="/admin/configuracoes/lista"
            className={classes.nested}
          >
            <ListItemIcon>
              <FormatListBulletedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Lista de Banners" />
          </ListItemLink>
        </Collapse>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box
            display="flex"
            style={{ width: '100%' }}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* menu mobile and title */}
            <Box display="flex" alignItems="center">
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Box
                className={classes.titlePage}
                display="flex"
                alignItems="center"
              >
                {title}
              </Box>
            </Box>

            {/* logout */}
            <Box>
              <Button
                className={classes.btnColorPrimary}
                startIcon={<ExitToAppIcon />}
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sair
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} style={{ maxWidth: `${width}` }}>
        <div className={classes.toolbar} />

        {content}
      </main>
    </div>
  );
}

DashAdmin.propTypes = {
  title: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired,
  content: PropTypes.object.isRequired,
  container: PropTypes.any,
};

export default memo(DashAdmin);
