import { createTheme, makeStyles } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',

  },

  datas: {
    maxWidth: 483,
    width: '100%',
    minHeight: 550,
    border: '1px solid #daa3a3',
    borderRadius: 8,
    marginRight: 48,
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      fontSize: 18,
      fontWight: '700',
      color: '#54080B',
      textAlign: 'center',
      margin: '24px 0 25px 0'
    },

    '& form ': {

      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      '& > button': {
        alignSelf: 'flex-end',
        margin: '0 24px 24px 0',
        padding: '6px 0',

        '& :nth-child(1)': {
          color: '#D81717',
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 2,

          "&:before": {
            content: '""',
            width: '100%',
            borderBottom: '3px solid #D81717',
            position: 'absolute',
            bottom: 0,
            left: 0,
            transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
          },

          '&:hover:before': {
            animation: `$animationBottom .8s`,
          },
        },

        '&:hover': {
          background: 'none',
        }
      }

    },

    [responsive.breakpoints.down('sm')]: {
      margin: '0 16px 32px 16px',
      maxWidth: '100%',
      height: 'auto',
    }
  },

  password: {
    maxWidth: 483,
    width: '100%',
    minHeight: 366,
    maxHeight: 430,
    border: '1px solid #daa3a3',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,

    '& h1': {
      fontSize: 18,
      fontWight: '700',
      color: '#54080B',
      textAlign: 'center',
      margin: '24px 0 25px 0'
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',

      '& > button': {
        alignSelf: 'flex-end',
        margin: '0 24px 24px 0',
        padding: '6px 0',


        '& :nth-child(1)': {
          color: '#D81717',
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 2,

          "&:before": {
            content: '""',
            width: '100%',
            borderBottom: '3px solid #D81717',
            position: 'absolute',
            bottom: 0,
            left: 0,
            transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
          },

          '&:hover:before': {
            animation: `$animationBottom .8s`,
          },
        },

        '&:hover': {
          background: 'none',
        },
      }
    },

    [responsive.breakpoints.down('sm')]: {
      margin: '0 16px 32px 16px',
      maxWidth: '100%',
      height: 'auto',
    }
  },

  form: {

    maxWidth: 435,
    margin: '0 25px 0px 25px',

    '& label': {
      fontSize: 14,
      fontWight: '400',
      marginBottom: 4,
      color: '#5D5656',
    },

    '& .MuiFormControl-fullWidth': {
      marginTop: 4,
    },

    '& .MuiInputBase-formControl': {
      height: 40,
    },

    '& .MuiOutlinedInput-adornedEnd': {
      width: '100%',
      height: 40,
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '0 10px 32px 10px',
    }
  },

  "@keyframes animationBottom": {
    '0%': {
      width: '100%'
    },
    '100%': {
      width: '0%'
    },
  },
}))

export default useStyles
