import axios from 'axios';
import history from './history';

const api = axios.create({
  baseURL: 'https://api-cursos.santolabs.com.br/api/cursos/',
  // baseURL: 'https://santolabs.com.br/api/cursos/',

});

// api.interceptors.request.use(
//   config => {
//     let persistor = JSON.parse(localStorage.getItem('persist:cursos'));
//     let auth = JSON.parse(persistor.auth);
//     const token = auth.token;

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   }
// );

// api.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response.status === 401) {
//       history.push('/');
//     }

//     return Promise.reject(error);
//   }
// );

export default api;
