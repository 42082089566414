import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Drawer,
  IconButton,
} from '@material-ui/core';

import NewHeader from '~/Components/NewHeader';
import Footer from '~/Components/Footer';

import useStyles from './style';
import Slider from '@material-ui/core/Slider';
import api from '~/Services/api';
import CourseCard from '~/Components/CourseCard';
import BoxFilter from '~/Components/BoxFilter';
import SkeletonCourse from '~/Utils/Skeleton/SkeletonCourse';
import Pagination from '@material-ui/lab/Pagination';
import { toast } from 'react-toastify';
import { CloseRounded } from '@material-ui/icons';
import history from '~/Services/history';

const AllCourses = () => {
  const paramsUrl = new URLSearchParams(window.location.search);

  const [expanded, setExpanded] = useState(true);
  const [expandedDrawer, setExpandedDrawer] = useState(false);
  const [active, setActive] = useState(false);
  const [typeCourse, setTypeCourse] = useState('');
  const [slider, setSlider] = useState([0, 1000]);
  const [categories, setCategories] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [showCourses, setShowCourses] = useState(false);
  const [periods, setPeriods] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 0,
    limit: 24,
  });

  const getCategories = async () => {
    const { data } = await api.get('view/category');

    const categories = data.map(category => {
      let obj = {
        type: 'category',
        id: category.id,
        name: category.name,
        checked: false,
      };

      [...paramsUrl.entries()].map(parameter => {
        if (category.name === parameter[1]) {
          obj.checked = true;
        }
      });

      return obj;
    });
    setCategories(categories);
  };

  const getPeriods = async () => {
    const { data } = await api.get('view/periods');

    const newPeriods = data.map(period => {
      let obj = {
        id: period.id,
        name: period.name,
        type: 'period',
        checked: false,
      };

      [...paramsUrl.entries()].map(parameter => {
        if (period.name === parameter[1]) {
          obj.checked = true;
        }
      });
      return obj;
    });

    setPeriods(newPeriods);
  };

  const getTeachers = async () => {
    const { data } = await api.get('/view/teacher');

    const newTeacheres = data.map(teacher => {
      let obj = {
        type: 'teacher',
        id: teacher.id,
        name: teacher.name,
        checked: false,
      };
      [...paramsUrl.entries()].map(parameter => {
        if (teacher.name === parameter[1]) {
          obj.checked = true;
        }
      });
      return obj;
    });

    setTeachers(newTeacheres);
  };

  const getCourses = async () => {
    setShowCourses(false);
    let search = [];
    let page = '';
    let type = '';
    let order = '';
    if ([...paramsUrl.entries()].length > 0) {
      [...paramsUrl.entries()].map(parameter => {
        setFilter(oldsFilter => [
          ...oldsFilter,
          { [parameter[0]]: parameter[1] },
        ]);

        if (parameter[0] === 'page') {
          page = parameter[1];
        }

        if (parameter[0] === 'tipo') {
          type = parameter[1];
          setTypeCourse(parameter[1]);
          setActive(true);
        }

        if (parameter[0] === 'order') {
          order = parameter[1];
          setOrderBy(parameter[1]);
        }

        search.push({ [parameter[0]]: parameter[1] });
      });

      if (window.innerWidth >= 600) {
        setTimeout(() => {
          setExpanded(true);
        }, 500);
      }
    }

    try {
      const res = await api.get(
        `view/courses?page=${page}&limit=${
          pagination.limit
        }&filter=${JSON.stringify(
          search
        )}&slider=${slider}&typeCourse=${type}&order=${order}`
      );

      if (!res.data.courses.length > 0) {
        setShowCourses(true);
        setNotFound(true);
        setCourses([]);
        setTotal(0);

        return;
      }

      setCourses(res.data.courses);
      setPagination({
        page: parseInt(res.data.page),
        pages: parseInt(res.data.pages),
        limit: res.data.limit,
      });

      setTotal(res.data.courses.length);
      setShowCourses(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getPriceCourse = () => {
    if (paramsUrl.toString().includes('price')) {
      const paramaterUrlFormated = paramsUrl.get('price');
      const price = {
        gratis: [0, 0],
        '0&39': [0, 39],
        '40&99': [40, 99],
        '100': [100, 1000],
      }[paramaterUrlFormated || [0, 1000]];

      setSlider(price);
    }
  };

  const clearFilter = () => {
    [...paramsUrl.entries()].map(parameter => paramsUrl.delete(parameter[0]));
    history.push({ ...history, search: (history.location.search = '') });
    periods.map(period => (period.checked = false));
    categories.map(category => (category.checked = false));
    teachers.map(teacher => (teacher.checked = false));
    setFilter([]);
    setTypeCourse('');
    setOrderBy('');
    setSlider([0, 1000]);
    setNotFound(false);
    getCourses();
  };

  const handleChangeSelect = e => {
    const querySearch = [...paramsUrl.entries()];

    const order = querySearch.find(parameter => parameter[0] === 'order');

    if (order) {
      setOrderBy(e.target.value);
      paramsUrl.set('order', e.target.value);
      history.push({
        ...history,
        search: decodeURI(paramsUrl.toString()),
      });

      return;
    }

    paramsUrl.append('order', e.target.value);
    history.push({
      ...history,
      search: decodeURI(paramsUrl.toString()),
    });
    setOrderBy(e.target.value);
  };

  const handleChange = (e, sliderValues) => {
    setSlider(sliderValues);
  };

  const handleExpansion = () => {
    setExpanded(!expanded);
  };

  const handleExpansionMobile = () => {
    setExpandedDrawer(!expandedDrawer);
  };

  const handleActive = e => {
    const querySearch = [...paramsUrl.entries()];

    const type = querySearch.find(parameter => parameter[0] === 'tipo');

    if (type) {
      setActive(!active);
      setTypeCourse(e.target.name);
      paramsUrl.set('tipo', e.target.name);

      history.push({
        ...history,
        search: decodeURI(paramsUrl.toString()),
      });

      return;
    }

    setActive(!active);
    setTypeCourse(e.target.name);
    paramsUrl.append('tipo', e.target.name);

    history.push({
      ...history,
      search: decodeURI(paramsUrl.toString()),
    });
  };

  const handleChangeFilter = (e, type) => {
    const { value, checked, id } = e.target;

    setFilter([
      ...filter,
      {
        [id]: value,
      },
    ]);

    paramsUrl.append(id, value);

    history.push({
      ...history,
      search: decodeURI(paramsUrl.toString()),
    });

    if (type === 'period') {
      const newPeridosCheckds = periods.map(period => {
        if (period.name === value) {
          period.checked = checked;
        }
        return period;
      });
      setPeriods(newPeridosCheckds);
    }

    if (type === 'category') {
      const newCategoriesCheckds = categories.map(category => {
        if (category.name === value) {
          category.checked = checked;
        }
        return category;
      });
      setCategories(newCategoriesCheckds);
    }

    if (type === 'teacher') {
      const newTeachersCheckds = teachers.map(teacher => {
        if (teacher.name === value) {
          teacher.checked = checked;
        }
        return teacher;
      });
      setTeachers(newTeachersCheckds);
    }

    if (checked === false) {
      const newFilter = filter.filter(filters => filters[id] !== value);

      const newQueryUrlFilter = newFilter.reduce((accumulator, query) => {
        accumulator += `${Object.keys(query)}=${Object.values(query)}&`;
        return accumulator;
      }, '');

      history.push({
        ...history,
        search: newQueryUrlFilter,
      });
      setFilter(newFilter);
    }
  };

  const handleChangePagination = async (e, value) => {
    const querySearch = [...paramsUrl.entries()];

    const page = querySearch.find(parameter => parameter[0] === 'page');

    if (page) {
      setPagination({
        ...pagination,
        page: parseInt(value),
      });

      paramsUrl.set('page', value);
      history.push({
        ...history,
        search: decodeURI(paramsUrl.toString()),
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });

      return;
    }

    setPagination({
      ...pagination,
      page: parseInt(value),
    });

    paramsUrl.append('page', value);
    history.push({
      ...history,
      search: decodeURI(paramsUrl.toString()),
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = e => {
    e.preventDefault();

    try {
      getCourses();
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getCategories();
    getTeachers();
    getPeriods();
    getCourses();
    getPriceCourse();
  }, []);

  useEffect(() => {
    const elementSlider = document.querySelector('#sliderValues');
    if (elementSlider) {
      const valueSlider = elementSlider.children[0];
      if (slider[0] === 0) {
        valueSlider.textContent = 'Grátis';
      } else {
        valueSlider.textContent = `R$ ${slider[0]}`;
      }
    }
  }, [slider]);

  useEffect(() => {
    console.info('pagination.page');
    getCourses();
  }, [pagination.page]);

  useEffect(() => {
    if (orderBy) {
      console.info('orderby');
      getCourses();
    }
  }, [orderBy]);

  const style = useStyles();

  return (
    <Grid className={style.container}>
      <NewHeader />
      <Grid className={style.content}>
        <h1 className={style.title}>Cursos</h1>

        <Grid className={style.containerFilter}>
          <Box className={style.filter}>
            <Box className={style.expanded}>
              <Box className={style.expandedDesk}>
                <span onClick={() => handleExpansion()}>Filtro</span>
                <button onClick={() => handleExpansion()}>
                  {expanded ? '-' : '+'}
                </button>
              </Box>

              <Box className={style.BoxExpandedMobile}>
                <span onClick={() => handleExpansionMobile()}>Filtro</span>
                <button
                  className={style.expandedMobile}
                  onClick={() => handleExpansionMobile()}
                >
                  {expandedDrawer ? '-' : '+'}
                </button>
              </Box>
            </Box>
            <Box className={style.result}>
              <span>{total} resultados</span>
              <FormControl variant="filled" className={style.select}>
                <InputLabel htmlFor="filled-age-native-simple">
                  Ordernar por{' '}
                </InputLabel>
                <Select native value={orderBy} onChange={handleChangeSelect}>
                  <option aria-label="None" value="" />
                  <option value="DESC">Data maior</option>
                  <option value="ASC">Data menor</option>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ display: { sm: 'none', md: 'block' } }}>
            <Collapse in={expanded}>
              <Box className={style.containerFilter}>
                <form onSubmit={handleSubmit}>
                  <Box className={`${style.contentFilter} area_carousel`}>
                    <BoxFilter
                      title="Período"
                      datas={periods}
                      handleChangeFilter={e => handleChangeFilter(e, 'period')}
                    />

                    <BoxFilter
                      title="Categorias"
                      datas={categories}
                      handleChangeFilter={e =>
                        handleChangeFilter(e, 'category')
                      }
                    />

                    <BoxFilter
                      title="Professores"
                      datas={teachers}
                      handleChangeFilter={e => handleChangeFilter(e, 'teacher')}
                    />

                    <Box className={style.boxFilter}>
                      <Box className={style.slider}>
                        <p>Preço</p>
                        <Slider
                          value={slider}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          componente="input"
                          max={500}
                        />
                        <Box id="sliderValues" className={style.sliderValues}>
                          <span>R$ {slider[0]}</span>
                          <span>R$ {slider[1]}</span>
                        </Box>
                      </Box>
                    </Box>

                    <Box className={style.boxFilter}>
                      <p>Tipo</p>
                      <Box className={style.button}>
                        <Box className={style.buttonType}>
                          <button
                            name="teorico"
                            type="button"
                            className={
                              active && typeCourse === 'teorico' ? 'active' : ''
                            }
                            onClick={handleActive}
                          >
                            Teórico
                          </button>
                          <button
                            name="pratico"
                            type="button"
                            className={
                              active && typeCourse === 'pratico' ? 'active' : ''
                            }
                            onClick={handleActive}
                          >
                            Prático
                          </button>
                        </Box>
                        <Box className={style.buttonClear}>
                          <button type="button" onClick={clearFilter}>
                            Limpar filtros
                          </button>
                          <button type="button" onClick={handleSubmit}>
                            Aplicar filtros
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Collapse>
          </Box>

          <Box sx={{ width: '50%', display: { sm: 'block', md: 'none' } }}>
            <Drawer anchor="left" open={expandedDrawer}>
              <Box role="presentation">
                <form onSubmit={handleSubmit}>
                  <Box className={style.containerFilterMobile}>
                    <Box className={style.closeMobile}>
                      <span>Filtro</span>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <IconButton
                          onClick={handleExpansionMobile}
                          className="btn_close_drawer"
                        >
                          <CloseRounded />
                        </IconButton>
                      </Box>
                    </Box>

                    <Box className={style.buttonClearMobile}>
                      <button type="button" onClick={clearFilter}>
                        Limpar filtros
                      </button>
                    </Box>

                    <Box
                      className={`${style.contentFilterMobile} area_carousel`}
                    >
                      <Box className={style.boxFilter}>
                        <Box className={style.slider}>
                          <p>Preço</p>
                          <Slider
                            value={slider}
                            onChange={handleChange}
                            id="slider"
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            componente="input"
                            max={1000}
                          />
                          <Box className={style.sliderValues}>
                            <span>R$ {slider[0]}</span>
                            <span>R$ {slider[1]}</span>
                          </Box>
                        </Box>
                      </Box>

                      <Box className={style.boxFilter}>
                        <p>Tipo</p>
                        <Box className={style.buttonType}>
                          <button
                            name="teorico"
                            type="button"
                            className={
                              active && typeCourse === 'teorico' ? 'active' : ''
                            }
                            onClick={handleActive}
                          >
                            Teorico
                          </button>
                          <button
                            name="pratico"
                            type="button"
                            className={
                              active && typeCourse === 'pratico' ? 'active' : ''
                            }
                            onClick={handleActive}
                          >
                            Pratico
                          </button>
                        </Box>
                      </Box>

                      <BoxFilter
                        title="Período"
                        datas={periods}
                        handleChangeFilter={e =>
                          handleChangeFilter(e, 'period')
                        }
                      />

                      <BoxFilter
                        title="Categorias"
                        datas={categories}
                        handleChangeFilter={e =>
                          handleChangeFilter(e, 'category')
                        }
                      />

                      <BoxFilter
                        title="Professores"
                        datas={teachers}
                        handleChangeFilter={e =>
                          handleChangeFilter(e, 'teacher')
                        }
                      />
                    </Box>
                    <Box className={style.applyFilterMobile}>
                      <button onClick={handleSubmit}>Aplicar filtros</button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Drawer>
          </Box>
        </Grid>
        <Grid
          className={`${style.containerCourse} ${
            expanded ? style.animationTop : style.animationBottom
          } `}
        >
          {showCourses &&
            courses.map(course => (
              <CourseCard key={course.id} course={course} />
            ))}

          {!showCourses && (
            <>
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
            </>
          )}

          {notFound && (
            <p className={style.messageError}>Curso não encontrado</p>
          )}
        </Grid>
        {showCourses && !notFound && (
          <Box className={style.pagination}>
            <Pagination
              count={pagination.pages}
              page={pagination.page}
              onChange={handleChangePagination}
            />
          </Box>
        )}
      </Grid>
      <Footer />
    </Grid>
  );
};

export default AllCourses;
