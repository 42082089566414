import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import useStyles from './style';

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import api from '~/Services/api';
import { store } from '~/store';
import { addDays, format, differenceInDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { signInExpired } from '~/store/modules/auth/actions';
import { useDispatch } from 'react-redux';

DateTime.local().setZone('America/Sao_Paulo');

function CourseCard({ course, pageCourse }) {
  const profile = store.getState().user.profile;
  const dispatch = useDispatch();

  const [configCard, setConfigCard] = useState({
    label: 'Inscreva-se',
    bgButton: '#b01010',
    borderColor: '#b01010',
    labelColor: '#fff',
  });

  const style = useStyles(configCard);
  const [activeCanceled, setActiveCanceled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const inputRef = useRef(null);

  const dayCourseFormated =
    course.date_of_course &&
    format(addDays(new Date(course.date_of_course), 1), 'dd/MM/yyyy', {
      locale: ptBR,
    });

  const toCamelCase = string => {
    return string
      .split(' ')
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  async function handleCard() {
    const diff = differenceInDays(
      addDays(new Date(course.date_of_course), 1),
      new Date()
    );

    if (profile) {
      try {
        const sub = await api.get(
          `course_subscribe/${course.id}/student/${profile.id}`
        );
        if (
          sub.data.message === 'registered' &&
          sub.data.status_pratical !== 1 &&
          course.is_pratical !== 0
        ) {
          setConfigCard({
            label: 'Aguardando confirmação',
            bgButton: '#ffabab',
            borderColor: '#ffabab',
            labelColor: '#fff',
            buttonDisabled: true,
          });
          return;
        }

        if (sub.data.message === 'registered') {
          setConfigCard({
            label: 'Você está inscrito',
            bgButton: '#419a58',
            borderColor: '#419a58',
            labelColor: '#fff',
          });
        }

        if (
          course.totalQuantity - course.quantity <= 10 &&
          sub.data.message !== 'registered'
        ) {
          setConfigCard({
            label: 'Últimas vagas',
            bgButton: '#d91718',
            borderColor: '#d91718',
            labelColor: '#fff',
          });
          gifLabel();
        }
      } catch (error) {
        dispatch(signInExpired());
      }
    }

    if (diff > course.days_for_publication) {
      setConfigCard({
        label: 'Inscrições em breve',
        bgButton: '#ffabab',
        borderColor: '#ffabab',
        labelColor: '#fff',
      });
    }

    if (course.quantity === course.totalQuantity) {
      setConfigCard({
        label: 'Esgotado',
        bgButton: '#fff',
        borderColor: '#b01010',
        border_button: '1px solid #D01717',
        labelColor: '#b01010',
      });
    }

    if (course.status === 'canceled') {
      setConfigCard({
        label: 'Cancelado',
        bgButton: '#d4d2d2',
        borderColor: '#d4d2d2',
        labelColor: '#fff',
      });
    }
  }

  function gifLabel() {
    let gif = true;
    setInterval(() => {
      gif = !gif;
      if (document.querySelector('#gif_label_card')) {
        document.querySelector('#gif_label_card').innerHTML = gif
          ? 'Últimas vagas'
          : 'Inscreva-se';
      }
    }, 2000);
  }

  const handleSlug = name => {
    name = name.replace(/[' '&\/\\#,+()$~%.'":*?<>{}@]/g, '-').toLowerCase();
    return `${name}-${course.id}`;
  };

  const handleCanceledCourse = () => {
    setActiveCanceled(!activeCanceled);
  };

  const handleChange = () => {
    if (inputRef.current.value.length > 10) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSubmitCanceledCourse = async e => {
    e.preventDefault();
    const reasonCanceled = inputRef.current.value;
    const obj = {
      student_id: profile.id,
      course_id: course.id,
      reason: reasonCanceled,
      renunciation: '1',
    };

    const { data } = await api.get(`student/my_subscribes/${profile.id}`);
    const { subscribeID } = data.course[0];

    if (subscribeID) {
      const canceledCourse = await api.put(`subscribe/${subscribeID}`, obj);
      setButtonDisabled(true);
      if (canceledCourse.data) {
        setTimeout(() => {
          setConfigCard({
            label: 'Inscreva-se',
            bgButton: '#d81717',
            borderColor: '#d81717',
            labelColor: '#fff',
          });
          setActiveCanceled(false);
          inputRef.current.value = '';
        }, 1000);
      }
    }
  };

  useEffect(() => {
    handleCard();
  }, []);

  return (
    <>
      <Box className={style.root} position="relative">
        <Box className={`${style.boxCourse} ${activeCanceled ? 'active' : ''}`}>
          <Box display="flex" justifyContent="center" position="relative">
            <Box className={style.photoTeacher}>
              <img src={course.path_image} alt="" />
            </Box>
            {pageCourse === 'cursos em andamento' && (
              <Box
                onClick={handleCanceledCourse}
                className={`${style.unsubscribeButton} ${
                  activeCanceled ? 'active' : ''
                }`}
              >
                {activeCanceled ? <CloseIcon /> : <DeleteOutlineIcon />}
              </Box>
            )}
          </Box>
          <Grid
            component="a"
            href={`/curso/${handleSlug(course.name)}`}
            className={style.BoxInfoCourse}
          >
            <Typography className={style.nameTeacher}>
              {toCamelCase(course.fullname)}
            </Typography>

            <Typography className={style.nameCourse}>{course.name}</Typography>
            <Box className={style.tag_course}>
              {course.is_pratical === 0 ? 'Curso Teórico' : 'Curso Prático'}
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              className={style.boxCategories}
            >
              {course.categories_names?.split(',').map(cat => (
                <Typography key={cat} className={style.nameCategory}>
                  {toCamelCase(cat)}
                </Typography>
              ))}
            </Box>

            <Box display="flex" className={style.boxInfosCourse}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className="box"
              >
                <Typography className="date_course">
                  {DateTime.fromSQL(course.date_of_course).toFormat(
                    'dd/MM/yyyy'
                  )}
                </Typography>
                <Typography className="day_week_course">
                  {DateTime.fromSQL(course.date_of_course)
                    .setLocale('pt')
                    .toFormat('EEEE')}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className="box"
              >
                <Typography>
                  <span className="label_start">início:</span>
                  <span className="hour_start_course">
                    {DateTime.fromSQL(course.hour_start)
                      .setLocale('pt')
                      .toFormat('HH:mm')}
                  </span>
                </Typography>
                <Typography>
                  <span className="label_end">término:</span>
                  <span className="hour_end_course">
                    {DateTime.fromSQL(course.hour_end)
                      .setLocale('pt')
                      .toFormat('HH:mm')}
                  </span>
                </Typography>
              </Box>
            </Box>

            <Typography className={style.priceCourse}>
              {course.donation ? 'Grátis' : course.price}
            </Typography>

            <Box className={style.btnCardCourse}>
              <Typography
                className="label_primary"
                id={
                  configCard.label === 'Últimas vagas' ? 'gif_label_card' : ''
                }
              >
                {configCard.label}
              </Typography>
              <Typography
                className={`label_second ${
                  configCard.label === 'Aguardando confirmação'
                    ? 'label_confirmation'
                    : ''
                }`}
              >
                saiba mais
              </Typography>
            </Box>
          </Grid>
          <Grid
            className={`${style.canceledCourse} ${
              activeCanceled ? 'active' : ''
            }`}
          >
            <form onSubmit={handleSubmitCanceledCourse}>
              <Box
                className={`${style.boxCanceled} ${
                  activeCanceled ? 'active' : ''
                }`}
              >
                <h3>Cancelar Inscrição?</h3>
                <p>Ao cancelar, essa vaga ficará disponível em nosso site. </p>
                <TextField
                  multiline
                  rows={6}
                  onChange={handleChange}
                  inputRef={inputRef}
                  placeholder="Escreva aqui o motivo do cancelamento para confirmar."
                />
                <Button disabled={buttonDisabled} type="submit">
                  Sim, quero cancelar
                </Button>
              </Box>
            </form>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CourseCard;
