import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  areaMenuProfile: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    boxShadow: '0px 0px 6px -1px #ccc',
    zIndex: 999,
    '& .Mui-selected': {
      color: '#b01010'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
}))

export default useStyles
