import React, { useState } from 'react';
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Table, Box, Avatar } from '@material-ui/core';
import { useEffect } from 'react';
import api from '~/Services/api';
import { Edit } from '@material-ui/icons';
import useStyles from './style'

export default function TableCategory() {

  const [categories, setCategories] = useState([])

  const classes = useStyles()

  useEffect(() => {
    getCategories()
  }, [])

  async function getCategories() {
    const res = await api.get('category')
    setCategories(res.data)
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Imagem</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Editar categoria</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map(cat => (
            <TableRow key={cat.id}>
              <TableCell><Avatar src={cat.image_url} /></TableCell>
              <TableCell>{cat.name}</TableCell>
              <TableCell>
                <a href={`/admin/editar-categoria/${cat.id}`} className={classes.iconEdit}><Edit /></a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
