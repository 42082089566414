import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import Slider from 'react-slick'

const SkeletonCarouselProffesor = () => {

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          infinite: true,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 5,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 3,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(t => (
        <Box key={t}>
          <Box>
            <Skeleton width={150} height={350} />
          </Box>
        </Box>
      ))}
    </Slider>
  )
}

export default SkeletonCarouselProffesor
