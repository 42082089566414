import React from 'react'
import useStyles from './style'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { AccountCircle, School, Home } from '@material-ui/icons'
import history from '~/Services/history'

const NavigationProfile = ({ page }) => {
  const classes = useStyles()
  return (
    <>
      <BottomNavigation
        value={page ? page : ""}
        onChange={(e, value) => {
          history.push(value)
        }}
        showLabels
        className={classes.areaMenuProfile}
      >
        <BottomNavigationAction value={"/area-do-aluno/meus-cursos"} label="Meus Cursos" icon={<School />} />
        <BottomNavigationAction value={"/"} label="Início" icon={<Home />} />
        <BottomNavigationAction value={"/area-do-aluno/meu-perfil"} label="Perfil" icon={<AccountCircle />} />
      </BottomNavigation>
    </>
  )
}

export default NavigationProfile
