import { makeStyles, createTheme } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  containerStudant: {
    background: '#fff',
  },

  container: {
    maxWidth: 1240,
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },

  title: {
    fontWeight: '700',
    fontSize: 32,
    color: '#d01717',
    padding: 48,
    textAlign: 'center'
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 95,

    [responsive.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      height: 'max-content',
      justifyContent: 'center',
    },
  },

  containerProfile: {
    margin: '0 49px 0 16px',


    [responsive.breakpoints.down('sm')]: {
      marginBottom: 30,
      marginLeft: 16,
      marginRight: 0,
      width: '95%',
    }
  },

  profile: {
    display: 'flex',
    alignItems: 'flex-end',
    width: 150,
    height: 64,
    marginBottom: 24,
    margin: '10px 10px 10px 0',

  },

  acesses: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    maxWidth: 517,

    '& a': {
      marginBottom: 24,
      color: '#A9A5A5',
      fontSize: 18,
      fontWeight: '400',
      paddingBottom: 5,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      width: 'max-content',


      '& .MuiButton-label': {
        justifyContent: 'start'
      },


    },

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'row',
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      // maxWidth: 383,
      margin: '0 10px 32px 0',

      '& a': {
        marginRight: 24,
        width: 'max-content',
        marginBottom: 5,
        marginTop: 10,
      },

      '&::-webkit-scrollbar': {
        width: '2px !important',
        height: '2px !important',
      }
    },

  },

  useName: {
    marginBottom: 24,

    '& :first-child': {
      display: 'block',
      color: '#271E1E',
    },

    '& :last-child': {
      fontSize: 24,
      color: '#271E1E',
      fontWeight: '700',
    },

    [responsive.breakpoints.down('sm')]: {
      '& :first-child': {
        display: 'inline-block',
        marginRight: 6,
      }
    }
  },

  active: {
    color: '#d81717 !important',
    fontWeight: '700 !important',
    position: 'relative',

    "&:before": {
      content: '""',
      width: '100%',
      borderBottom: '3px solid #d81717',
      position: 'absolute',
      bottom: 0,
      left: 0,
      transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
    },

    '&:hover:before': {
      animation: `$animationBottom .8s`,
    },
  },

  "@keyframes animationBottom": {
    '0%': {
      width: '100%'
    },
    '100%': {
      width: '0%'
    },
  },

  containerModal: {
    minWidth: 372,
    width: '100%',
    minHeight: 210,
    display: "flex",
    flexDirection: 'column',
    padding: 24,

    '& span': {
      fontSize: 18,
      fontWeight: '400',
      color: '#5D5656',
      marginBottom: 32
    },

    [responsive.breakpoints.down('sm')]: {
      minWidth: 328,
    }
  },

  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 45,
    marginBottom: 25,

    '& img': {
      width: 18,
      height: 18,
    }
  },

  buttonsModal: {
    width: '100%',
    height: 54,
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      maxWidth: 150,
      width: '100%',
      height: 38,
      borderRadius: 8,
      border: 'none',
      outline: 'none',
      fontSize: 12,
      fontWeight: '400',
      letterSpacing: 2,
      cursor: 'pointer',

      [responsive.breakpoints.down('sm')]: {
        maxWidth: 128,
      }
    },

    '& :nth-child(1)': {
      background: '#d81717',
      color: "#fff",
      textAlign: 'center',

      '&:hover': {
        background: '#ee2526',
      }
    },

    '& :nth-child(2)': {
      background: '#fff',
      border: '1px solid #d81717',
      textAlign: 'center',
      color: '#d81717',

      '&:hover': {
        background: '#d81717',
        color: "#fff",
        cursor: 'pointer'
      }
    }
  },

  messageProfile: {
    width: '79%',
    height: 50,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'end',
    background: '#fd9843',
    margin: '0 16px 16px 16px',
    borderRadius: 8,
    animation: `$pulse-animation 2s infinite`,

    [responsive.breakpoints.down('sm')]: {
      width: '93%',
    },

    '& span': {
      color: '#fff',
      fontSize: 18,
      margin: '0 5px',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 14,
      }
    },

    '& a': {
      color: '#000',
      fontSize: 18,
      textDecoration: 'underline',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 14,
      }
    }
  },

  iconWarning: {
    fill: '#fd9843',
    margin: '0 10px',
    height: 25,
    width: 25,
    borderRadius: '50%',
    animation: `$pulse-animation 2s infinite`,
  },

  "@keyframes pulse-animation": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.2)",
      transform: 'scale(0.97)',
    },
    "100%": {
      boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)",
    }
  }
}))

export default useStyles
