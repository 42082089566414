import { makeStyles, createTheme } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 435,
    margin: '0 24px 24px 24px',

    '& label': {
      fontSize: 16,
      fontWeight: '400',
      marginBottom: 4,
      color: '#5D5656',
    },

    '& .MuiFormControl-root': {
      marginTop: 4
    },

    '& .MuiOutlinedInput-adornedEnd': {
      marginTop: 4
    },

    '& input': {
      borderRadius: 8,
      outline: 'none',
      padding: '12.98px 0 12.04px 8px',
      fontSize: 14,
      fontWeight: '400',
      color: '#5D5656',
      marginTop: 4,
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d81717',
      borderWidth: 1,
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '0 10px 32px 10px',
    }
  },

  formMask: {
    maxWidth: 435,
    margin: '0 24px 24px 24px',

    '& label': {
      fontSize: 16,
      fontWeight: '400',
      marginBottom: 4,
      color: '#5D5656',
    },

    '& input': {
      borderRadius: 4,
      outline: 'none',
      padding: '12.98px 0 12.04px 8px',
      fontSize: 14,
      fontWeight: '400',
      color: '#5D5656',
      width: '100%',
      marginTop: 4,
      border: '1px solid rgba(0, 0, 0, 0.23)',

      '&:focus:not(:read-only)': {
        borderColor: '#d81717'
      },

      '&[readonly]': {
        opacity: 0.5,
      }
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '0 10px 32px 10px',
    }
  },

  checkBox: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      color: '#5D5656',
    }
  },

  error: {
    color: '#b01010',
    fontSize: 11
  }
}))

export default useStyles
