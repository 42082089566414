import { makeStyles } from "@material-ui/core";

const useStylesCard = makeStyles(theme => ({
  card: {
    width: 304,
    margin: '0 auto',
    '& *': {
      fontFamily: 'Roboto',
    },
    borderTop: '5px solid',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    transition: 'linear .2s',
    '&.canceled': {
      borderColor: '#ccc',
      "& $btnCourseCard, $btnReadMore": {
        background: '#ccc',
      }
    },
    '&.full': {
      borderColor: 'rgba(216,23,23,.5)',
      "& $btnCourseCard, $btnReadMore": {
        background: 'rgba(216,23,23,.5)',
      }
    },
    '&.coming_soon': {
      borderColor: '#ffd9a0',
      "& $btnCourseCard, $btnReadMore": {
        background: '#ffd9a0',
      }
    },
    '&.lastVacancies': {
      borderColor: "#ffc53a",
    },
    '&.public': {
      borderColor: "#d81717",
      "& $btnCourseCard, $btnReadMore": {
        background: '#d81717',
      }
    },
    '&:hover': {
      boxShadow: '0px 2px 10px 0px rgba(0,0,0,.4)',
      '& $btnReadMore': {
        height: 30,
        paddingBottom: 17,
      },
      '& img': {
        transform: 'scale(1.2)'
      }
    },
  },
  avatarCard: {
    width: '142px !important',
    height: '142px !important',
    border: '5px solid #f5f5f5',
    backgroundColor: '#fff',
    position: 'absolute !important',
    marginTop: -75,
    '& img': {
      transition: 'transform  .2s'
    }
  },
  contentCard: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px 0 !important',
    paddingBottom: '0 !important',
    '& p, div': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
    }
  },
  nameProfCard: {
    fontSize: 22,
    paddingTop: 80,
  },
  nameCourseCard: {
    fontSize: 16,
    paddingTop: 18,
    height: 67,
  },
  areaCategories: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 80,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  nameCategoryCard: {
    width: '50% !important',
    padding: '5px 10px',
    '& span': {
      display: 'block',
      borderRadius: 15,
      padding: '5px 0',
      color: "#b01010",
      background: '#f5f5f5',
      width: '100%',
    }
  },
  infosDateTimeCard: {
    display: 'flex',
    paddingTop: 23,
    '& div': {
      display: 'flex',
      flexWrap: 'wrap',
    }
  },
  dateCard: {
    '& p:first-child': {
      fontSize: 22,
      fontWeight: 'bold',
    },
    '& p:last-child': {
      textTransform: 'lowercase',
    }
  },
  timeCard: {
    '& p:first-child span': {
      fontSize: 22,
      fontWeight: 'bold',
    },
    '& p:last-child span': {
      fontWeight: 'bold',
    }
  },
  priceCard: {
    fontSize: 32,
    color: '#d81717',
    paddingTop: 19,
    paddingBottom: 22,
    fontWeight: 'bold !important',
  },
  btnCourseCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 52,
    fontSize: 22,
    background: "#d81717",
    color: "#fff",
    transition: 'linear 1s'
  },
  btnReadMore: {
    fontSize: 16,
    overflow: 'hidden',
    height: 0,
    color: '#fff',
    transition: 'linear .2s',
  }
}))

export default useStylesCard
