import { Box } from '@material-ui/core'
import React, { useContext, useEffect, useRef } from 'react'
import FormV2 from '~/Components/Forms/FormV2'
import Input from '~/Components/Inputs/Input'

import * as yup from 'yup'

import useStyles from './style'
import InputMask from '../Inputs/InputMask'
import { toast } from 'react-toastify'
import api from '~/Services/api'
import Password from '../Inputs/Password'

import { Context } from '~/Context/AuthContext'

function EditProfile(props) {
  const formRef = useRef(null)
  const formPasswordRef = useRef(null)
  const { data } = props
  const { id } = data

  const { setValidateDataUser } = useContext(Context)

  const validateFields = async () => {
    if (data.id !== '') {
      try {
        const schema = yup.object().shape({
          fullname: yup.string().test('fullname', 'Insira nome e sobrenome', function (value) {
            if (value.split(" ").length >= 2 && value.split(" ")[1].length >= 2) {
              console.log('aqui')
              return true;
            } else {
              return false;
            }
          }),
          email: yup.string().email("Formato de email inválido!").required("Campo Obrigatório!"),
          telephoneOne: yup.string().when('telephoneTwo', (telephoneTwo, field) =>
            data.telephoneTwo !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone Incompleto")
          ),
          telephoneTwo: yup.string().when((telephoneOne, field) =>
            data.telephoneOne !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone incompleto")
          ),
        })

        await schema.validate(data, {
          abortEarly: false
        })

      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errorMessage = {};

          err.inner.forEach(error => {
            errorMessage[error.path] = error.message
          })

          formRef.current.setErrors(errorMessage)
          setValidateDataUser(true)
        }
      }
    }
  }

  const handleSubmit = async (data, { reset }, event) => {

    try {
      const schema = yup.object().shape({
        fullname: yup.string().test('fullname', 'Insira nome e sobrenome', function (value) {
          if (value.split(" ").length >= 2 && value.split(" ")[1].length >= 2) {
            return true;
          } else {
            return false;
          }
        }),
        email: yup.string().email("Formato de email inválido!").required("Campo Obrigatório!"),
        telephoneOne: yup.string().when('telephoneTwo', (telephoneTwo, field) =>
          data.telephoneTwo !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone Incompleto")
        ),
        telephoneTwo: yup.string().when((telephoneOne, field) =>
          data.telephoneOne !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone incompleto")
        ),
      })

      await schema.validate(data, {
        abortEarly: false
      })

      const obj = {
        fullname: data.fullname,
        email: data.email,
        telephoneOne: data.telephoneOne.replace(/[^\d]+/g, ''),
        telephoneTwo: data.telephoneTwo.replace(/[^\d]+/g, ''),
      }

      try {
        await api.put(`users/${id}`, obj)
        toast.success('Dados alterado com sucesso')
        formRef.current.setErrors({})
        setValidateDataUser(false)
      } catch (error) {
        toast.error(error)
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message
        })

        formRef.current.setErrors(errorMessage)
      }
    }
  }

  const handleSubmitPassword = async (data, { reset }, event) => {

    try {
      const schema = yup.object().shape({
        oldPassword: yup.string().required("Campo Obrigatório!").min(6, "Mínimo 6 dígitos!"),
        password: yup.string().required("Campo Obrigatório!").min(6, "Mínimo 6 dígitos!"),
        confirmPassword: yup.string().when('password', (confirmPassword, field) =>
          confirmPassword !== '' ? field.oneOf([yup.ref('password'), null], 'Senhas não conferem!').required("Campo Obrigatório !") : field
        ),
      })

      await schema.validate(data, {
        abortEarly: false
      })

      const obj = {
        oldPassword: data.oldPassword,
        password: data.password,
      }

      try {
        await api.put(`users/${id}`, obj)
        toast.success('Dados alterado com sucesso')
        formPasswordRef.current.setErrors({})
        reset()
      } catch (error) {
        if (error) {
          toast.error("Senha antiga não existente")
        }
      }
    } catch (err) {
      const errorMessage = {}
      err.inner.forEach(error => {
        errorMessage[error.path] = error.message
      })
      formPasswordRef.current.setErrors(errorMessage)
    }
  }

  useEffect(() => {
    formRef.current.setData(data)
  }, [data])

  useEffect(() => {
    validateFields()
  }, [data])

  const style = useStyles()
  return (
    <>
      <Box className={style.datas}>
        <h1>Dados pessoais</h1>

        <FormV2
          formRef={formRef}
          handleSubmit={handleSubmit}
        >
          <Input name="fullname" label="Nome completo" variant="outlined" fullWidth />
          <InputMask mask="999.999.999-99" name="cpf" label="Cpf" readOnly />
          <Input name="email" label="Email" variant="outlined" fullWidth />
          <InputMask mask="(99) 99999-9999" name="telephoneOne" label="Telefone 1" />
          <InputMask mask="(99) 99999-9999" name="telephoneTwo" label="Telefone 2" />
        </FormV2>

      </Box>

      <Box className={style.password}>
        <h1>Senha</h1>
        <FormV2
          formRef={formPasswordRef}
          handleSubmit={handleSubmitPassword}
        >
          <Password
            name="oldPassword"
            label="Senha Antiga"
            placeholder="Digite a senha antiga"
            fullWidth
          />
          <Password
            name="password"
            label="Nova Senha"
            placeholder="Digite a nova senha"
            fullWidth
          />
          <Password
            name="confirmPassword"
            label="Repita a nova senha"
            placeholder="Repita a nova senha"
            fullWidth
          />

        </FormV2>
      </Box>
    </>

  )
}

export default EditProfile
