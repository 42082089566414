import { Box, Dialog, Grid, Slide } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import globalStyles from '~/Assets/css/globalStyles'
import Footer from '~/Components/Footer'

import close from '../../Assets/img/close.png'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import useStyles from './style'
import { signOut } from '~/store/modules/auth/actions'
import { useDispatch } from 'react-redux'
import NewHeader from '../NewHeader'
import { store } from '~/store'
import { Context } from '~/Context/AuthContext'

const Profile = ({ children, title }) => {
  const nameUser = store.getState().user.profile.fullname
  const type_user = store.getState().user.profile.type_user
  const idProfile = store.getState().user.profile.id

  const { validateDataUser } = useContext(Context)

  const pattern = globalStyles()
  const style = useStyles()

  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)
  const titles = ['Meus Dados', 'Editar perfil', 'Area do professor', 'Editar dados']

  const linksArea = {
    myData: type_user === 'student' ? '/area-do-aluno' : '/area-do-professor',
    OngoingCourse: type_user === 'student' ? '/area-do-aluno/meus-cursos/cursos-andamento' : '/area-do-professor/meus-cursos/cursos-andamento',
    completedCourses: type_user === 'student' ? '/area-do-aluno/meus-cursos/cursos-completos' : '/area-do-professor/meus-cursos/cursos-completos',
    coursesITaught: '/area-do-professor/meus-cursos/cursos-que-ensinei',
    CoursesITeach: '/area-do-professor/meus-cursos/Cursos-para-ensinar',
  }

  function logout() {
    dispatch(signOut())
  }

  return (
    <Grid className={style.containerStudant}>
      <NewHeader />
      <Grid className={`${pattern.content} ${style.container}`}>
        <h1 className={style.title}>{title}</h1>
        {nameUser === '' && validateDataUser ?
          <Box className={style.messageProfile}>
            <span>Atualize seu cadastro!</span>
            <a href={`/area-do-aluno/meu-perfil/${idProfile}`}>Clique aqui para atualizar seu perfil</a>
          </Box>
          : ''
        }
        <Grid className={style.content}>

          <Box className={style.containerProfile}>

            <Box className={style.useName}>
              <span>Olá,</span>
              <span><b>{nameUser.split(' ')[0]}</b></span>
            </Box>

            <Box className={`${style.acesses} area_carousel`}>
              <a href={linksArea.myData}
                className={titles.includes(title) ? style.active : ''}>
                Meus Dados
                {nameUser === '' && validateDataUser ?
                  <ErrorOutlineOutlinedIcon className={style.iconWarning} />
                  : ''}
              </a>{type_user === 'student' &&
                <>
                  <a href={linksArea.OngoingCourse} className={title === 'Cursos em andamento' ? style.active : ''}>Cursos em andamento</a>
                  <a href={linksArea.completedCourses} className={title === 'Cursos realizados' ? style.active : ''}>Cursos realizados</a>
                </>
              }
              {type_user === 'teacher' &&
                <>
                  <a href={linksArea.CoursesITeach} className={title === 'Próximas aulas' ? style.active : ''}>Cursos em andamento</a>
                  <a href={linksArea.coursesITaught} className={title === 'Aulas realizadas' ? style.active : ''}>Cursos realizados</a>
                  <a href={'/area-do-professor/area-do-aluno'} className={title === 'Área do Aluno' ? style.active : ''}>Área do Aluno</a>

                  <a
                    href="https://cursos.santolabs.com.br/uploads/Manual-de-Regras-Sala-de-Curso-Pratica-Loja-Santo-Antonio.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Manual do professor</a>
                </>
              }
              <a onClick={() => setOpenModal(true)}>Sair</a>
            </Box>

          </Box>
          {children}
        </Grid>
      </Grid>
      <Footer />
      <ModalClose
        openModal={openModal}
        setOpenModal={setOpenModal}
        logout={logout}
        style={style}
      />
    </Grid >
  )
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalClose = ({ openModal, setOpenModal, style, logout }) => {

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
    >
      <Grid className={style.containerModal}>
        <Box className={style.headerModal}>
          <h1>Sair</h1>
          <img src={close || ''} alt="close" onClick={() => setOpenModal(!openModal)} />
        </Box>
        <span>Deseja realmente sair? </span>
        <Box className={style.buttonsModal}>
          <button onClick={() => setOpenModal(!openModal)}>Não</button>
          <button onClick={logout}>Sim</button>
        </Box>
      </Grid>
    </Dialog>
  )
}



export default Profile
