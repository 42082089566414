import React, { useEffect, useState } from 'react';
import DashAdmin from '~/Components/DashAdmin';
import FormUser from '~/Components/Forms/FormUser';
import api from '~/Services/api';

// import { Container } from './styles';

export default function AddEditTeacher({ match }) {
  const [config, setConfig] = useState({
    id: false,
    title: 'Adicionar Professor',
    data: false
  })

  useEffect( () => {
    requestTeacher()
  }, [])

  async function requestTeacher() {
    if (match.params.id) {
      const res = await api.get(`users/${match.params.id}`)
      setConfig({ id: match.params.id, title: 'Atualizar Professor', data: res.data})
    }
  }

  return (
    <>
      <DashAdmin content={<FormUser handleDrawer={() => { }} values={config.data} form={'teacher'} user={'admin'} />} title={config.title} width={'600px'} />
    </>
  );
}
