import React, { useContext, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from './style';
import { Context } from '~/Context/AuthContext';

function MyProfile(props) {
  const { data, linksAreaEditProfile } = props
  const { setValidateDataUser } = useContext(Context)

  const configStyle = {
    position: 'absolute'
  }

  const validadeFieldsData = (data) => {
    Object.keys(data).map((prop) => {
      if (data[prop] === '') {
        setValidateDataUser(true)
      }
    })
  }

  useEffect(() => {
    validadeFieldsData(data)
  }, [data])

  const style = useStyles(data.typeUser === 'teacher' && configStyle)
  return (
    <>
      <h1>Dados pessoais</h1>
      <Box className={style.contentDados}>
        <Box className={style.dados}>
          <h4>Nome completo</h4>
          <p>{data.fullname}</p>
        </Box>
        <Box className={style.dados}>
          <h4>Cpf</h4>
          <p>{data.cpf}</p>
        </Box>
        <Box className={style.dados}>
          <h4>Email </h4>
          <p>{data.email}</p>
        </Box>
        <Box className={style.dados}>
          <h4>Telefone</h4>
          <p>{data.telephoneOne}</p>
        </Box>
        {data.typeUser === 'teacher' &&
          <Box className={`${style.dados} descriptionTeacher`}>
            <h4>Descrição do professor</h4>
            <p>{data.resum_teacher}</p>
          </Box>
        }
      </Box>
      <Button href={linksAreaEditProfile} className={style.edit} >Editar</Button>

    </>
  )
}

export default MyProfile;
