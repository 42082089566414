const { makeStyles, createTheme } = require("@material-ui/core/styles");

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({

  contentDados: {
    maxWidth: 419,
    width: '100%',
    minHeight: 170,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: 10,
  },

  dados: {
    flex: '1 1 204px',
    maxWidth: '100%',
    width: '100%',
    height: 62,
    marginBottom: 17,

    [responsive.breakpoints.down('sm')]: {
      width: 0,
    },

    '&.descriptionTeacher': {

      [responsive.breakpoints.down('sm')]: {
        width: 100,
        maxWidth: '100%',
      },
    },

    '& h4': {
      fontSize: 16,
      fontWeight: '700',
      color: '#271E1E',
      marginBottom: '16.98px',


      [responsive.breakpoints.down('md')]: {
        marginBottom: 6,
        textAlign: 'initial'
      },
    },

    '& p': {
      fontSize: 14,
      fontWeight: '400',
      color: '#271E1E',
      marginLeft: 8,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '350vmax',
      minHeight: '10vmin',
      maxHeight: 54,

      [responsive.breakpoints.down('md')]: {
        marginBottom: '16.98px',
      },

    },

    [responsive.breakpoints.down('md')]: {
      maxWidth: '100%',
      flex: '1 1 143px',
      marginLeft: 16,
    },

  },

  edit: {
    alignSelf: 'flex-end',
    margin: '0 32px 32px 0',
    padding: '6px 0',
    position: props => props.position ? props.position : '',
    bottom: 0,

    [responsive.breakpoints.down('sm')]: {
      position: 'relative !important',
    },

    '& :nth-child(1)': {
      letterSpacing: 2,
      color: '#D81717',
      fontWeight: 'bold',
      lineHeight: 1,
      textTransform: 'initial',
      fontSize: 18,

      "&:before": {
        content: '""',
        width: '100%',
        borderBottom: '3px solid #D81717',
        position: 'absolute',
        bottom: 0,
        left: 0,
        transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
      },

      '&:hover:before': {
        animation: `$animationBottom .8s`,
      },
    },

    '&:hover': {
      background: 'none',
    }
  },

  "@keyframes animationBottom": {
    '0%': {
      width: '100%'
    },
    '100%': {
      width: '0%'
    },
  },
}))

export default useStyles
