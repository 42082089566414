import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import TableDepoiment from '~/Components/Tables/TableDepoiment';

function ListDepoiment() {

  return (
    <DashAdmin content={<TableDepoiment />} title={'Lista de Depoimentos'} width={'100%'} />
  )
}

export default ListDepoiment;
