import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import useStyles from './style'

const SkeletonCourse = () => {

    const style = useStyles()
    return (
        <Box className={style.root} component='a' href='#'>
            <Box className={style.boxCourse}>
                <Box display='flex' justifyContent='center' position='relative' >
                    <Box className={style.photoTeacher}>
                        <Skeleton variant='circle' width={142} height={142} />
                    </Box>
                </Box>

                <Typography className={style.nameTeacher}><Skeleton /></Typography>

                <Typography className={style.nameCourse}><Skeleton /></Typography>

                <Box display='flex' flexWrap='wrap' justifyContent='center' className={style.boxCategories}>
                    <Typography className={style.nameCategory}><Skeleton width={1} /></Typography>
                    <Typography className={style.nameCategory}><Skeleton width={1} /></Typography>
                </Box>

                <Box display='flex' className={style.boxInfosCourse}>

                    <Box display='flex' flexDirection='column' alignItems='center' className='box'>
                        <Typography className='date_course'><Skeleton width={80} /></Typography>
                        <Typography className='day_week_course'><Skeleton width={80} /></Typography>
                    </Box>

                    <Box display='flex' flexDirection='column' alignItems='center' className='box'>
                        <Typography>
                            <Skeleton width={80} />
                        </Typography>
                        <Typography>
                            <Skeleton width={80} />
                        </Typography>
                    </Box>
                </Box>

                <Typography className={style.priceCourse}><Skeleton height={50} /></Typography>

                <Box className={style.btnCardCourse}>
                    <Typography className='label_primary'><Skeleton width={100} /></Typography>
                    <Typography className='label_second'><Skeleton /></Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default SkeletonCourse
