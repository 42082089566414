import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import FormBanner from '~/Components/Forms/FormBanner';

export default function Banner({ match }) {
  return (
    <DashAdmin
      content={<FormBanner id={match.params.id ? match.params.id : false} />}
      title={`${match.params.id ? 'Atualizar' : 'Adicionar'} Banner`}
      width={'600px'}
    />
  );
}
