import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

  content: {
    paddingTop: '0 !important'
  },


  title: {
    fontSize: 40,
    color: '#d01717',
    textAlign: 'center',
    margin: '48px 0',

    [theme.breakpoints.down('sm')]: {
      fontSize: 30
    }
  },

  expansionPanel: {
    '& .MuiExpansionPanelSummary-content': {
      '& p': {
        fontSize: 22,
      }
    },

    '& .MuiCollapse-root': {
      '& .MuiTypography-root': {
        fontSize: 20,
      }
    }
  }

}))

export default useStyles
