import React from 'react';
import Header from '~/Components/Header';
import globalStyles from '~/Assets/css/globalStyles';
import { Container, Typography } from '@material-ui/core';
import FormDepoiment from '~/Components/Forms/FormDepoiment';

export default function Depoiment({ match }) {
  const pattern = globalStyles()

  return (
    <>
      <Header />
      <div className={pattern.content}>
        <Container maxWidth='sm'>
          <Typography className={pattern.titleForm}>Compartilhe sua Experiência</Typography>
         <FormDepoiment sub={match.params.id} />
        </Container>
      </div>
    </>
  );
}
