import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import globalStyles from '~/Assets/css/globalStyles';
import {
  InputLabel,
  OutlinedInput,
  FormHelperText,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import api from '~/Services/api';
import { CustomButton } from '../Custom';

const FormBannerEdit = ({ id }) => {
  const pattern = globalStyles();

  const [banner, setBanner] = useState({
    name: '',
    description: '',
    status: 0,
    banner_url: '',
    image: [],
    link: '',
  });

  const [btnSubmit, setBtnSubmit] = useState(false);

  const [msg, setMsg] = useState({
    name: '',
    description: '',
  });

  const [status, setStatus] = useState({
    name: '',
    description: '',
  });

  const [alertModal, setAlertModal] = useState({
    show: false,
    title: '',
    content: '',
  });

  useEffect(() => {
    console.log(id);
    if (id) {
      getBanner();
    }
  }, [id]);

  async function getBanner() {
    const res = await api.get(`banners/${id}`);
    setBanner({
      ...banner,
      name: res.data.name,
      description: res.data.description,
      status: res.data.status,
      banner_url: res.data.banner_url || '',
      link: res.data.link || '',
    });
    console.log(res);
  }

  let schema = yup.object().shape({
    name: yup
      .string()
      .required('Campo Obrigatório')
      .min(4, 'Mínimo 4 Letras!'),
    description: yup.string().required('Campo Obrigatório'),
    banner_url: yup.string(),
    image: yup.string(),
    link: yup
      .string()
      .matches(/^(ftp|http|https):\/\/[^ "]+$/i, 'Link inválido')
      .nullable(),
  });

  const handleValues = (field, e) => {
    setBanner({ ...banner, [field]: e.target.value });
    valid(field, { ...banner, [field]: e.target.value });
  };

  const valid = (field, obj) => {
    schema
      .validateAt(field, obj)
      .then(() => {
        setMsg({ ...msg, [field]: '' });
        setStatus({ ...status, [field]: 'isValid' });
      })
      .catch(err => {
        setMsg({ ...msg, [field]: err.errors });
        setStatus({ ...status, [field]: 'invalid' });
      });
    schema
      .validate(obj)
      .then(() => {
        setBtnSubmit(true);
      })
      .catch(err => {
        setBtnSubmit(false);
      });
  };

  const uploadImage = e => {
    const val = e.target.files[0] ? e.target.files[0] : [];
    setBanner({ ...banner, image: val });
    valid('image', { ...banner, image: e.target.files[0] });
  };

  function handleModal() {
    setAlertModal({ ...alertModal, show: !alertModal.show });
  }

  const updateBanner = async e => {
    e.preventDefault();
    const obj = new FormData();
    obj.append('name', banner.name);
    obj.append('description', banner.description);
    obj.append('status', banner.status);
    obj.append('file', banner.image);
    obj.append('link', banner.link);

    try {
      if (id) {
        await api.put(`banners/${id}`, obj);
        setAlertModal({
          show: !alertModal.show,
          title: `Atualização de banner`,
          content: `Banner atualizado com sucesso !`,
        });
        getBanner();
        return;
      }

      await api.post(`banners/`, obj);
      setAlertModal({
        show: !alertModal.show,
        title: `Cadastro de banner`,
        content: `Banner cadastrado com sucesso !`,
      });
      setBanner({
        name: '',
        description: '',
        status: 0,
        banner_url: '',
        image: [],
      });
      setMsg({ name: '', description: '' });
      setStatus({ name: '', description: '' });
      setBtnSubmit(false);
    } catch (err) {
      setAlertModal({
        show: !alertModal.show,
        title: `Cadastro de banner`,
        content: `Erro ao cadastrar banner!`,
      });
      setBtnSubmit(false);
    }
  };

  return (
    <>
      <form onSubmit={updateBanner} className={pattern.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={pattern.circleImage}>
              {id ? (
                <img src={banner.banner_url} alt={`Banner ${banner.name}`} />
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 className={pattern.titleForm}>
              {id ? 'Editar' : 'Adicionar'} Banner{' '}
            </h4>
          </Grid>

          {/* Nome Banner */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small" className={status.name}>
              <InputLabel id="nameBanner" variant="outlined">
                Nome Banner
              </InputLabel>
              <OutlinedInput
                value={banner.name}
                htmlFor="nameBanner"
                labelWidth={120}
                onChange={e => handleValues('name', e)}
                onBlur={e => handleValues('name', e)}
              />
              <FormHelperText>{msg.name}</FormHelperText>
            </FormControl>
          </Grid>
          {/* Descrição */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small" className={status.description}>
              <InputLabel id="descriptionBanner" variant="outlined">
                Descrição Banner
              </InputLabel>
              <OutlinedInput
                value={banner.description}
                htmlFor="descriptionBanner"
                labelWidth={140}
                onChange={e => handleValues('description', e)}
                onBlur={e => handleValues('description', e)}
              />
              <FormHelperText>{msg.description}</FormHelperText>
            </FormControl>
          </Grid>
          {/* link banner */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small" className={status.link}>
              <InputLabel id="linkBanner" variant="outlined">
                Link
              </InputLabel>
              <OutlinedInput
                value={banner.link}
                htmlFor="linkBanner"
                labelWidth={120}
                onChange={e => handleValues('link', e)}
                onBlur={e => handleValues('link', e)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className={pattern.inputFile}>
              <label htmlFor="imageBanner">
                <Button variant="contained" component="span">
                  Imagem
                </Button>
              </label>
              <input
                onChange={e => uploadImage(e)}
                accept="image/*"
                style={{ display: 'none' }}
                id="imageBanner"
                type="file"
              />
              <label htmlFor={'imageBanner'} className={`labelNameImage`}>
                {banner.image.name
                  ? banner.image.name
                  : 'Selecionar imagem ...'}
              </label>
            </div>
          </Grid>
          <Grid item xs={12}>
            <CustomButton
              type="submit"
              id={'btnAddBanner'}
              fullWidth
              disabled={!btnSubmit}
              bg={'#419a58'}
              bg_hover={'#419a58cc'}
            >
              {id ? 'Atualizar Banner' : 'Cadastrar Banner'}
            </CustomButton>
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={alertModal.show}
        onClose={handleModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {alertModal.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{alertModal.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModal}
            color="primary"
            style={{
              background: 'green',
              color: '#fff',
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormBannerEdit;
