import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { Grid, Button, FormControl, InputLabel, OutlinedInput, FormHelperText, IconButton, InputAdornment, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, FormGroup, Switch, Typography } from '@material-ui/core'
import MaskedInput from 'react-text-mask';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import globalStyles from '~/Assets/css/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { signReset, signUpRequest } from '~/store/modules/auth/actions';
import api from '~/Services/api';
import { CustomButton } from '../Custom';
import { useParams } from 'react-router-dom';


function cpfMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function telMask(props) {
  const { inputRef, value, ...other } = props;

  let mask = []

  if (value) {
    if (value[0] === '(') {
      if (value[5] === '9') {
        mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      } else {
        mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      }
    } else {
      if (value[2] === '9') {
        mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      } else {
        mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      }
    }
  } else {
    mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      value={value}
      guide={false}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function formataCPF(cpf) {
  if (cpf !== undefined) {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
}

function formataTel(tel) {
  if (tel !== undefined && tel !== null) {
    tel = tel.replace(/[^\d]/g, "");
    if (tel.length > 10) {
      return tel.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
    } else {
      return tel.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
  }
}

const FormUser = ({ handleDrawer, values, ...props }) => {
  const dispatch = useDispatch()
  const signed = useSelector(state => state.auth.signed)
  const alert = useSelector(state => state.auth.alertSignUp)
  const pattern = globalStyles()
  const { id } = useParams()

  const [alertModal, setAlertModal] = useState({
    show: false,
    title: '',
    content: ''
  })

  const obj = {
    cpf: "",
    fullname: "",
    email: "",
    telephoneOne: "",
    telephoneTwo: "",
    password: "",
    confirmPassword: "",
    path_image: '',
    image: [],
  }

  const [events, setEvents] = useState({
    showPassword: false,
    showConfirmPass: false,
    telephoneOne: false,
    telephoneTwo: false
  })

  const [btnSubmit, setBtnSubmit] = useState(false)
  const [checked, setChecked] = useState(false)
  const [previewPhoto, setPreviewPhoto] = useState('')

  const [data, setData] = useState(obj)

  useEffect(() => {
    if (values) {
      alterData()
    }
  }, [values])

  function alterData() {
    const object = {
      cpf: formataCPF(values.cpf),
      fullname: values.fullname,
      email: values.email,
      telephoneOne: formataTel(values.telephoneOne),
      telephoneTwo: formataTel(values.telephoneTwo),
      resum_teacher: !values.resum_teacher ? '' : values.resum_teacher,
      path_image: !values.path_image ? '' : values.path_image,
      image: []
    }
    valid(null, object)
    setData(object)
  }

  const [msg, setMsg] = useState(obj)

  const [status, setStatus] = useState(obj)

  const schema = yup.object().shape({
    cpf: yup.string().required("Campo Obrigatório!").min(14, "CPF incompleto"),
    fullname: yup.string().test('fullname', 'Insira nome e sobrenome', function (value) {
      if (value.split(" ").length >= 2 && value.split(" ")[1].length >= 2) {
        return true;
      } else {
        return false;
      }
    }),
    email: yup.string().email("Formato de email inválido!").required("Campo Obrigatório!"),
    telephoneOne: yup.string().when('telephoneTwo', (telephoneTwo, field) =>
      telephoneTwo !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone Incompleto")
    ),
    telephoneTwo: yup.string().when((telephoneOne, field) =>
      data.telephoneOne !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone incompleto")
    ),
    resum_teacher: yup.string().when((teacher, field) =>
      props.form === 'teacher' ? field.min(6, "Mínimo 6 dígitos!").required("Campo Obrigatório!") : field
    ),
    path_image: yup.string(),
    image: yup.string(),
    password: yup.string().when((p, field) =>
      props.user === 'admin' ? field : field.min(6, "Mínimo 6 dígitos!").required("Campo Obrigatório!")
    ),
    confirmPassword: yup.string().when((p, field) =>
      props.user === 'admin' ? field : field.oneOf([yup.ref('password'), null], 'Senhas não conferem!').required("Campo Obrigatório !")
    ),
  })

  const valid = (field = null, obj) => {
    if (field) {
      schema.validateAt(field, obj).then(() => {
        setStatus({ ...status, [field]: "isValid" })
        setMsg({ ...msg, [field]: "" })
      }).catch(err => {
        setMsg({ ...msg, [field]: err.errors })
        setStatus({ ...status, [field]: "invalid" })
      })
    }

    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  const handleChangeTeacher = ({ target }) => {
    setChecked(target.checked)
  }

  const handleValues = (field, e) => {
    if (field === 'fullname') {
      let str = e.target.value
      if (str.indexOf("  ") !== -1) {
        str = str.replace(/\s{2,}/g, " ")
      }
      e.target.value = str
    }

    setData({ ...data, [field]: e.target.value })
    valid(field, { ...data, [field]: e.target.value })
  }

  const handleClickShowPassword = field => {
    setEvents({ ...events, [field]: !events[field] });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (signed) {
      handleDrawer("register")
    }
  }, [signed])

  const uploadImage = e => {
    const val = e.target.files[0] ? e.target.files[0] : []
    setPreviewPhoto(URL.createObjectURL(val))
    setData({ ...data, image: val })
    valid('image', { ...data, image: e.target.files[0] })
  }

  function handleModal() {
    setAlertModal({ ...alertModal, show: !alertModal.show })
  }

  const add = async e => {
    e.preventDefault()
    let obj;

    if (props.form === 'teacher') {
      obj = new FormData()
      obj.append('cpf', data.cpf.replace(/[^a-z0-9]/gi, ''))
      obj.append('fullname', data.fullname)
      obj.append('email', data.email)
      obj.append('telephoneOne', data.telephoneOne?.replace(/[^a-z0-9]/gi, ''))
      obj.append('telephoneTwo', data.telephoneTwo?.replace(/[^a-z0-9]/gi, ''))
      obj.append('resum_teacher', data.resum_teacher)
      obj.append('type_user', 'teacher')
      obj.append('file', data.image)
    } else {
      if (checked) {
        obj = new FormData()
        obj.append('cpf', data.cpf.replace(/[^a-z0-9]/gi, ''))
        obj.append('fullname', data.fullname)
        obj.append('email', data.email)
        obj.append('telephoneOne', data.telephoneOne?.replace(/[^a-z0-9]/gi, ''))
        obj.append('telephoneTwo', data.telephoneTwo?.replace(/[^a-z0-9]/gi, ''))
        obj.append('resum_teacher', data.resum_teacher)
        obj.append('type_user', 'teacher')
        obj.append('file', data.image)
      } else {
        obj = {
          cpf: data.cpf.replace(/[^a-z0-9]/gi, ''),
          fullname: data.fullname,
          email: data.email,
          telephoneOne: data.telephoneOne?.replace(/[^a-z0-9]/gi, ''),
          telephoneTwo: data.telephoneTwo?.replace(/[^a-z0-9]/gi, ''),
          password: data.password,
          type_user: 'student',
          resum_teacher: '',
        }
      }
    }


    if (props.form === 'teacher') {
      // Atualiza o professor
      if (id) {
        await api.put(`users/${values.id}`, obj)
        setAlertModal({
          show: !alertModal.show,
          title: `Atualização de ${props.form === 'teacher' ? 'professor' : 'aluno'}`,
          content: `${props.form === 'teacher' ? 'professor' : 'aluno'} atualizado com sucesso !`,
        })
        window.location = '/admin/listar-professores'
      } else {
        // Cria um novo professor
        const res = await api.post(`/users`, obj)
        if (res.data.error) {
          setAlertModal({
            show: !alertModal.show,
            title: 'Error ao  cadastrar',
            content: `O usuário **${data.fullname}** está cadastrado como  ${res.data.type_user === 'teacher' ? "Professor" : 'Aluno'}!
            ${res.data.type_user === 'student' ? `\ncaso queria adicionar-lo como professor, entre no cadastro do aluno **${data.fullname}** e troque para professor! ` : ''}`
          })
        } else {
          setAlertModal({ show: !alertModal.show, title: 'Cadastro de professor', content: 'Professor cadastrado com sucesso !' })
          window.location = '/admin/listar-professores'
        }

      }


    } else {
      // Atualiza dos aluno e altera o tipo para professor
      if (checked) {

        const res = await api.put(`/users/${id}`, obj)
        if (res.data.error) {
          setAlertModal({
            show: !alertModal.show,
            title: 'Error ao  cadastrar',
            content: `O usuário **${data.fullname}** está cadastrado como  ${res.data.type_user === 'teacher' ? "Professor" : 'Aluno'}!
            ${res.data.type_user === 'student' ? `\ncaso queria adicionar-lo como professor, entre no cadastro do aluno **${data.fullname}** e troque para professor! ` : ''}`
          })

        } else {
          setAlertModal({
            show: !alertModal.show,
            title: `Atualização de ${props.form === 'teacher' ? 'professor' : 'aluno'}`,
            content: `${props.form === 'teacher' ? 'professor' : 'aluno'} atualizado com sucesso !`,
          })
          window.location = '/admin/listar-alunos'
        }

      } else {
        // Atualiza o aluno
        await api.put(`users/${values.id}`, obj)
        setAlertModal({
          show: !alertModal.show,
          title: `Atualização de ${props.form === 'teacher' ? 'professor' : 'aluno'}`,
          content: `${props.form === 'teacher' ? 'professor' : 'aluno'} atualizado com sucesso !`,
        })
        window.location = '/admin/listar-alunos'
        dispatch(signUpRequest(obj.fullname, obj.cpf, obj.email, obj.telephoneOne, obj.telephoneTwo, obj.password))
        setTimeout(() => {
          dispatch(signReset())
        }, 3000)
      }

    }
  }

  return (
    <>
      <form onSubmit={e => add(e)} className={pattern.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {alert ? (
              <div className={`${pattern.alert} ${alert.status ? alert.status : ""}`}>
                {alert.msg ? alert.msg : ""}
              </div>
            ) : ""}
          </Grid>

          {props.form === 'teacher' ?
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <div style={{ overflow: 'hidden', width: 150, height: 150, borderRadius: 75, margin: '10px auto' }}>
                <img src={previewPhoto || data.path_image} alt="" style={{ width: '100%' }} />
              </div>
            </Grid>
            : ''}

          {props.form === 'student' &&
            <>
              {previewPhoto || data.path_image ?
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <div style={{ overflow: 'hidden', width: 150, height: 150, borderRadius: 75, margin: '10px auto' }}>
                    <img src={previewPhoto || data.path_image} alt="" style={{ width: '100%' }} />
                  </div>
                </Grid>
                : ""}
            </>
          }



          <Grid item xs={12}>
            <FormControl
              className={status.cpf}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="cpf" variant="outlined">CPF</InputLabel>
              <OutlinedInput
                value={data.cpf}
                onChange={e => handleValues('cpf', e)}
                onBlur={e => handleValues('cpf', e)}
                id="cpf"
                inputComponent={cpfMask}
                placeholder="123.456.789-00"
                labelWidth={35}
              />
              <FormHelperText>{msg.cpf}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              className={status.fullname}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="fullname" variant="outlined">Nome Completo</InputLabel>
              <OutlinedInput
                value={data.fullname}
                onChange={e => handleValues('fullname', e)}
                onBlur={e => handleValues('fullname', e)}
                id="fullname"
                labelWidth={120}
              />
              <FormHelperText>{msg.fullname}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              className={status.email}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="emailForm" variant="outlined">E-mail</InputLabel>
              <OutlinedInput
                value={data.email}
                onChange={e => handleValues('email', e)}
                onBlur={e => handleValues('email', e)}
                id="emailForm"
                labelWidth={50}
              />
              <FormHelperText>{msg.email}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              className={status.telephoneOne}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="telephoneOne" variant="outlined">Telefone 1</InputLabel>
              <OutlinedInput
                value={data.telephoneOne}
                onChange={e => handleValues('telephoneOne', e)}
                onBlur={e => handleValues('telephoneOne', e)}
                id="telephoneOne"
                inputComponent={telMask}
                labelWidth={70}
              />
              <FormHelperText>{msg.telephoneOne}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              className={status.telephoneTwo}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="telephoneTwo" variant="outlined">Telefone 2</InputLabel>
              <OutlinedInput
                value={data.telephoneTwo}
                onChange={e => handleValues('telephoneTwo', e)}
                onBlur={e => handleValues('telephoneTwo', e)}
                id="telephoneTwo"
                inputComponent={telMask}
                labelWidth={70}
              />
              <FormHelperText>{msg.telephoneTwo}</FormHelperText>
            </FormControl>
          </Grid>

          {props.user === 'admin' ? "" :
            <Grid item xs={12}>
              <Grid item xs={12} md={12}>
                <FormControl
                  className={status.password}
                  size="small"
                  fullWidth
                >
                  <InputLabel htmlFor="password" variant="outlined">Senha</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={events.showPassword ? 'text' : 'password'}
                    value={data.password}
                    onChange={e => handleValues('password', e)}
                    onBlur={e => handleValues('password', e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword('showPassword')
                          }
                          onMouseDown={() => handleMouseDownPassword}
                          edge="end"
                        >
                          {events.showPasswordForm ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={45}
                  />
                  <FormHelperText>{msg.password}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl
                  className={status.confirmPassword}
                  size="small"
                  fullWidth
                >
                  <InputLabel htmlFor="confirmPass" variant="outlined" >Confirmar Senha</InputLabel>
                  <OutlinedInput
                    id="confirmPass"
                    type={events.showConfirmPass ? 'text' : 'password'}
                    value={data.confirmedPassword}
                    onChange={e => handleValues('confirmPassword', e)}
                    onBlur={e => handleValues('confirmPassword', e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword('showConfirmPass')
                          }
                          onMouseDown={() => handleMouseDownPassword}
                          edge="end"
                        >
                          {events.showConfirmPass ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={120}
                  />
                  <FormHelperText>{msg.confirmPassword}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          }

          {
            props.form === 'teacher' ?
              <Grid item xs={12}>
                <Grid item xs={12} >
                  <FormControl variant='outlined' fullWidth className={`${status.resum_teacher} Mui - focused`}>
                    <InputLabel shrink style={{ width: 155, background: '#fff' }}>Resumo do Professor</InputLabel>
                    <OutlinedInput
                      id="resum_teacher"
                      labelWidth={150}
                      value={data.resum_teacher}
                      onChange={e => handleValues('resum_teacher', e)}
                      onBlur={e => handleValues('resum_teacher', e)}
                      multiline={true}
                      rows={5}
                      style={{ background: '#fff' }}
                    />
                    <FormHelperText>
                      {msg.resum_teacher}
                    </FormHelperText>
                    {/* Button */}
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: '15px', marginTop: 15 }}>
                  <div className={pattern.inputFile}>
                    <label htmlFor="imageTeacher">
                      <Button variant="contained" component="span">Imagem</Button>
                    </label>
                    <input
                      onChange={e => uploadImage(e)}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="imageTeacher"
                      type="file"
                    />
                    <label
                      htmlFor={'imageTeacher'}
                      className={`labelNameImage`}
                    >
                      {data.image.name ? data.image.name : "Selecionar imagem ..."}
                    </label>
                  </div>
                </Grid>
              </Grid>
              :
              ''
          }

          {props.form === 'student' &&
            <Grid item xs={12}>
              <FormGroup>
                <Typography>Alterar aluno para professor</Typography>
                <Switch
                  checked={checked}
                  onChange={handleChangeTeacher}
                />
              </FormGroup>
            </Grid>
          }

          {props.form === 'student' && checked ?
            <Grid item xs={12}>
              <Grid item xs={12} >
                <FormControl variant='outlined' fullWidth className={`${status.resum_teacher} Mui - focused`}>
                  <InputLabel shrink style={{ width: 155, background: '#fff' }}>Resumo do Professor</InputLabel>
                  <OutlinedInput
                    id="resum_teacher"
                    labelWidth={150}
                    value={data.resum_teacher}
                    onChange={e => handleValues('resum_teacher', e)}
                    onBlur={e => handleValues('resum_teacher', e)}
                    multiline={true}
                    rows={5}
                    style={{ background: '#fff' }}
                  />
                  <FormHelperText>
                    {msg.resum_teacher}
                  </FormHelperText>
                  {/* Button */}
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: '15px', marginTop: 15 }}>
                <div className={pattern.inputFile}>
                  <label htmlFor="imageTeacher">
                    <Button variant="contained" component="span">Imagem</Button>
                  </label>
                  <input
                    onChange={e => uploadImage(e)}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="imageTeacher"
                    type="file"
                  />
                  <label
                    htmlFor={'imageTeacher'}
                    className={`labelNameImage`}
                  >
                    {data.image.name ? data.image.name : "Selecionar imagem ..."}
                  </label>
                </div>
              </Grid>
            </Grid>
            : ""
          }

          <Grid item xs={12}>
            <CustomButton
              disabled={!btnSubmit}
              bg="#419a58"
              bg_hover="#419a58cc"
              type="submit"
              fullWidth
            >
              {values ? 'atualizar' : 'cadastrar'}
            </CustomButton>
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={alertModal.show}
        onClose={handleModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{alertModal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alertModal.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModal}
            color="primary"
            style={{
              background: 'green',
              color: '#fff'
            }}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

FormUser.propTypes = {
  handleDrawer: PropTypes.func.isRequired
}

cpfMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default FormUser
