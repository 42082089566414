import React, { useEffect } from 'react';
import globalStyles from '~/Assets/css/globalStyles';
import { Box } from '@material-ui/core';


import foto1 from '~/Assets/img/fotos-antigas-quem-somos-02.jpg'
import foto2 from '~/Assets/img/foto2.JPG'
import foto3 from '~/Assets/img/foto3.JPG'
import foto4 from '~/Assets/img/foto4.jpeg'
import foto5 from '~/Assets/img/foto5.jpeg'
import foto6 from '~/Assets/img/foto6.jpeg'
import foto7 from '~/Assets/img/foto7.jpeg'

import useStyles from './style';
import NewHeader from '~/Components/NewHeader';
import Footer from '~/Components/Footer';


export default function AboutUs() {
  const pattern = globalStyles()
  const classes = useStyles()



  return (
    <>
      <NewHeader />
      <div className={`${pattern.content} ${classes.content}`} >

        <h1 className={classes.title} > Sobre a Loja Santo Antonio </h1>

        <div className={classes.contentAboutUs}>
          <Box className={classes.division}>
            <div className={'divisionText'}>
              <p>
                Iniciamos uma longa jornada de trabalho e dedicação dia 12 de Outubro de 1990 no Bairro do Tatuapé!
              </p>

              <p>
                Tudo começou com a Meire, uma mãe que queria trabalhar e ao mesmo tempo ficar perto dos seus filhos
                pequenos. Então ela resolveu abrir em um quarto de sua casa a Loja Santo Antonio.
              </p>

              <p>
                No inicio era só uma pequena loja de varejo e hoje conta com uma grande área de venda e uma escola
                de culinária cheia de profissionais qualificados no ramo da Culinária e Confeitaria.
              </p>
            </div>

            <div className={'divisionImg'}>
              <img src={foto1} alt="" />
            </div>

          </Box>

          <Box className={classes.division}>
            <div className="divisionText fullWidth">
              <p>
                Os “Produtos para Fabricação Caseira” passaram a ser evidência na vida das pessoas que procuram ter
                como fonte de renda o trabalho executado em sua própria cozinha. Por isso disponibilizamos de todo tipo
                de recurso necessário para montar uma pequena empresa em sua própria casa.
              </p>
            </div>
          </Box>

          <Box className={`${classes.division} ${classes.imgCourses}`}>
            <div className="divisionImg">
              <img src={foto2} alt="" />
            </div>
            <div className="divisionImg">
              <img src={foto3} alt="" />
            </div>
          </Box>

          <Box className={classes.division}>
            <div className="divisionText fullWidth">
              <p>
                Contamos com uma sala de Cursos para aumentar o campo de conhecimento de nossos clientes na área da
                culinária e Confeitaria. Procuramos abranger diferentes tipos de público, como donas de casa, confeiteiros,
                varejistas, micro empreendedores, entre outros.
              </p>
            </div>
          </Box>

          <Box className={`${classes.division} ${classes.divisionMobile}`}>
            <div className="divisionImg" style={{ width: '25%' }}>
              <img src={foto4} alt="" />
            </div>
            <div className="divisionImg" style={{ width: '25%' }}>
              <img src={foto5} alt="" />
            </div>
            <div className="divisionImg" style={{ width: '25%' }}>
              <img src={foto6} alt="" />
            </div>
            <div className="divisionImg" style={{ width: '25%' }}>
              <img src={foto7} alt="" />
            </div>
          </Box>


          <Box className={classes.division} >
            <div className={"divisionText fullWidth"}>
              <p>
                Localizada no bairro do Tatuapé, a Loja Santo Antonio conta com mais de 30.000 mil itens entre produtos
                de culinária, confeitaria, artigos de decoração para festas, miniaturas para cestas, caixas, fitas, além
                de atacado e varejo de doces e guloseimas.
              </p>
            </div>
            <div className={"divisionText"}>
              <p>
                Quem é que nunca ouviu algo parecido com "minha esposa faz salgadinhos em casa para ajudar no orçamento…" ou
                "..minha mãe faz doces e bolos.." ?
              </p>
              <p>
                Para quem pensa em culinária, confeitaria e festas, na loja encontra tudo!!!
              </p>
              <p>
                Loja Santo Antonio, faça de sua cozinha a sua empresa.
              </p>
              <p>
                Rua Serra de Juréa, 736 – Tatuapé – São Paulo/ SP
              </p>
            </div>

            <div className={`${classes.frame} divisionText`}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.494486674021!2d-46.572820685543384!3d-23.550677567081745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e969718ba07%3A0x185cdeaff8f17631!2sR.%20Serra%20de%20Jur%C3%A9a%2C%20736%20-%20Tatuap%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003323-020!5e0!3m2!1spt-BR!2sbr!4v1584217293046!5m2!1spt-BR!2sbr"
                width="600"
                height="450"
                frameBorder="0"
                title="Map"
                style={{ border: 0, width: '100%', height: '100%' }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </Box>

        </div>
      </div>
      <Footer />
    </>
  );
}
