import React, { useState, useEffect } from 'react';
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Table, TableFooter, Grid, FormControl, InputLabel, Select } from '@material-ui/core';

import api from '~/Services/api';
import { Pagination, Rating } from '@material-ui/lab';
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';


const styles = makeStyles(theme => (
  {
    root: {
      '& thead': {
        '& tr': {
          '& th': {
            fontWeight: 'bold'
          }
        }
      },

      '& .icon-show': {
        color: '#cecece',

        '&.show-true': {
          color: 'green'
        }
      }
    },
    tableHeader: {

      '& .MuiTableRow-head': {
        background: '#d01717',

        '& .MuiTableCell-head': {
          color: '#fff',
          fontSize: 18,
          fontWeight: '400',
        }
      },
    },

    pagination: {

      '& .MuiPagination-root': {
        width: 360,
        margin: '0 auto',

        '& .MuiPagination-ul': {
          width: 360,
          padding: '15px 0',
        }
      }

    },

    limit: {
      maxWidth: 150,
      width: '100%',
      margin: 15,
    }
  }

))


export default function TableDepoiment() {

  const classes = styles()

  const [data, setDepoiments] = useState({
    depoiments: []
  })
  const [page, setPage] = useState({
    page: 1,
    pages: 0,
    limit: 10
  });

  const handleChangePagination = (event, value) => {
    setPage({
      ...page,
      page: value,
    });
  };

  const getDepoiments = async () => {
    const { data } = await api.get(`depoiment?page=${page.page}&pages=${page.pages}&limit=${page.limit}`)

    setPage({
      page: parseInt(data.page),
      pages: parseInt(data.pages),
      limit: parseInt(data.limit)
    })
    setDepoiments(data)
  }

  const handleShowDepoiment = async (id) => {
    const index = data.depoiments.findIndex(d => d.id === id)

    if (data.depoiments[index].is_approved !== 1) {
      data.depoiments[index].is_approved = 1

      await api.put(`depoiment/${id}`, {
        is_approved: true
      })

    } else {
      data.depoiments[index].is_approved = 0

      await api.put(`depoiment/${id}`, {
        is_approved: false
      })

    }
    setDepoiments({ ...data, depoiments: data.depoiments })
  }

  const handleChangeLimit = ({ target }) => {
    setPage({
      ...page,
      limit: parseInt(target.value)
    })
  }

  useEffect(() => {
    getDepoiments()
  }, [])

  useEffect(() => {
    getDepoiments()
  }, [page.page])

  useEffect(() => {
    getDepoiments()
  }, [page.limit])

  return (
    <TableContainer component={Paper} className={classes.root}>
      <FormControl variant="outlined" className={classes.limit}>
        <InputLabel htmlFor="outlined-age-native-simple">limite</InputLabel>
        <Select
          native
          value={page.limit}
          onChange={handleChangeLimit}
          label="Limite"
        >
          <option aria-label="None" value="" />
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Select>
      </FormControl>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Aluno</TableCell>
            <TableCell>Professor</TableCell>
            <TableCell>curso</TableCell>
            <TableCell>Data do curso</TableCell>
            <TableCell>Data de avaliação</TableCell>
            <TableCell>Avaliações</TableCell>
            <TableCell>Depoimento do professor</TableCell>
            <TableCell>Depoimento do curso</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.depoiments.map((depoiment) => (
            <TableRow key={depoiment.id}>
              <TableCell>{depoiment.fullname}</TableCell>
              <TableCell>{depoiment.name_teacher}</TableCell>
              <TableCell>{depoiment.name_course}</TableCell>
              <TableCell>{format(new Date(depoiment.date_of_course), 'dd/MM/yyyy')}</TableCell>
              <TableCell>{format(new Date(depoiment.createdAt), 'dd/MM/yyyy')}</TableCell>
              <TableCell>
                <p>
                  <span>Professor:</span>
                  <span><Rating name="read-only" value={depoiment.rating_teacher} readOnly /></span>
                </p>
                <p>
                  <span>Curso:</span>
                  <span><Rating name="read-only" value={depoiment.rating_course} readOnly /></span>
                </p>
              </TableCell>
              <TableCell>{depoiment.about_teacher}</TableCell>
              <TableCell>{depoiment.about_course}</TableCell>
              <TableCell>
                {depoiment.is_approved ?
                  <CheckCircle className='icon-show show-true' onClick={e => handleShowDepoiment(depoiment.id)} />
                  : <CheckCircleOutline className='icon-show' onClick={e => handleShowDepoiment(depoiment.id)} />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid className={classes.pagination}>
        <Pagination count={page.pages} page={page.page} onChange={handleChangePagination} />
      </Grid>
    </TableContainer>
  );
}
