import { createTheme, makeStyles } from "@material-ui/core/styles";
import setaNext from '~/Assets/img/seta-next.jpg'
import setaPrev from '~/Assets/img/seta-prev.jpg'


const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f4f4f4',
  },

  content: {
    width: '100%',
    margin: '0 auto',
  },

  BackgroundHeader: {
    background: '#B01010',
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'center',
    minHeight: 175,
    height: 'auto',
  },

  headerCourse: {
    maxWidth: 1240,
    width: '100%',
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'space-between',

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '24px 16px',
    }

  },

  photo: {
    width: 125,
    height: 125,
    minWidth: 125,
    maxWidth: 125,
    minHeight: 125,
    maxHeight: 125,
    margin: 24,

    '& img': {
      width: '100%',
      display: 'block',
      borderRadius: '50%',
    },

    [responsive.breakpoints.down('sm')]: {
      margin: '0 16px 41px 0',
    }
  },

  course: {
    maxWidth: 576,
    width: '100%',
    margin: '27px 0',

    [responsive.breakpoints.down('sm')]: {
      margin: '0 0 27px 16px',
    },

    '& h1': {
      color: '#fff',
      fontSize: '2.0vw',
      fontWeight: '700',
      marginBottom: 4,

      [responsive.breakpoints.down('sm')]: {
        fontSize: '18px',
      }
    },

    '& p': {
      fontSize: 14,
      fontWeight: '400',
      color: '#fff',

      '& > b': {
        textDecoration: 'underline',
        letterSpacing: 2,
        marginLeft: 8,
      },
    },

    '& p:nth-of-type(1)': {
      marginBottom: 4,
    },
    '& p:nth-of-type(2)': {
      marginBottom: 16,
    },



  },

  hour: {

    '& span': {
      marginTop: 8,
      fontSize: 24,
      color: '#fff',
      fontWeight: '700',
      marginRight: 32,
      display: 'inline-block',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 18,
      }
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: 220,
      width: '100%',

      '& :first-child': {
        width: 120,
      },

      '& :last-child': {
        display: 'inline-block',
      },
    }
  },


  contentSubscribe: {
    maxWidth: 436,
    width: '100%',
    height: 119,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: "#fff",
    borderRadius: 8,
    marginRight: 16,

    '& > p': {
      fontSize: 16,
      fontWeight: '700',
      margin: '24px 0 16px 0',

      '& span': {
        color: '#D01717',
        letterSpacing: 2,

        '&:first-child': {
          color: '#000'
        }
      }
    },

    [responsive.breakpoints.down('sm')]: {
      marginRight: 0,
      padding: '0 16px',
      maxWidth: 565,
    }
  },

  subscribe: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,

    '& > p': {
      fontSize: 16,
      fontWeight: '700',
      color: '#D01717',
      letterSpacing: 2,
      margin: '0 20px 0 20px',
      whiteSpace: 'nowrap',

      [responsive.breakpoints.down('sm')]: {
        margin: '0 24px 0 0 ',
      }
    },

    '& button': {
      minWidth: 171,
      maxWidth: 240,
      width: 240,
    }


  },

  contentDetails: {
    maxWidth: 1280,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    minHeight: 450,
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    marginBottom: 32,

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 16px',
    },

    '& button': {
      margin: '20px 0px',
      minWidth: 171,
      '& span': {
        color: '#fff'
      }
    }
  },

  aboutTeacher: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    [responsive.breakpoints.down('sm')]: {
      width: '100%',
    },

    '&  h2': {
      margin: '48px 32px',
      fontSize: 24,
      color: '#5D5656',

      [responsive.breakpoints.down('sm')]: {
        margin: '48px 0',
      },
    },

    '& p': {
      maxWidth: 690,
      fontSize: 14,
      color: '#5D5656',
      lineHeight: '1.5',
      fontWeight: '400',
      marginLeft: 16,

      [responsive.breakpoints.down('sm')]: {
        padding: '0 0',
        textAlign: 'left',
        margin: '0',
      }
    },


  },

  resumTeacher: {
    padding: '0 16px 16px',

    '& p':{
      fontSize: 16,
    },

    [responsive.breakpoints.down('sm')]: {
      padding: 0,
    }
  },

  modality: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    [responsive.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 32,
    },

    '&  h2': {
      margin: '48px 32px',
      fontSize: 24,
      color: '#5D5656',

      [responsive.breakpoints.down('sm')]: {
        margin: '48px 0',
      },
    },

    '& p': {
      maxWidth: 690,
      fontSize: 25,
      color: '#5D5656',
      lineHeight: 1.4,
      fontWeight: '400',
      margin: '0 32px 0 32px',

      [responsive.breakpoints.down('sm')]: {
        padding: '0 0',
        textAlign: 'left',
        margin: '0',
      }
    },

    '& h3': {
      fontSize: 24,
      fontWeight: '400',
      color: "#5D5656",
      margin: '48px 16px 8px 32px ',

      [responsive.breakpoints.down('sm')]: {
        margin: '48px 0',
      },
    },

    '& h4': {
      fontSize: 18,
      color: "#5D5656",
      fontWeight: '400',
      margin: '0 16px 32px 32px',

      [responsive.breakpoints.down('sm')]: {
        margin: '16px 0',
      },
    },

    '& span': {
      maxWidth: 608,
      width: '100%',
      display: 'block',
      fontSize: 14,
      color: '#5D5656',
      lineHeight: '22.4px',
      margin: '0 32px 0 32px',

      [responsive.breakpoints.down('sm')]: {
        padding: '0 0',
        margin: '0',
      }
    },
  },

  lineDivisor: {
    maxHeight: 450,
    height: 'calc(100vh - 10vh)',
    width: 3,
    background: '#c4c4c4',
    alignSelf: 'center',
    marginTop: 16,

    [responsive.breakpoints.down('sm')]: {
      height: 3,
      width: '100%',
      marginTop: 60,
    }
  },

  similarCourses: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: '700',
    color: '#54080B',
    marginBottom: 32,
  },

  boxBigSquare: {
    maxWidth: 1240,
    width: '100%',
    height: 590,
    borderRadius: 5,
    overflow: 'hidden',
    background: '#f4f4f4',
    position: 'relative',
    transition: 'linear .1s',
    margin: '0 auto',

    '& .slick-slide': {
      padding: '55px 24px 0 24px',
    },

    [responsive.breakpoints.between('md', 'lg')]: {
      width: '90%'
    },

    [responsive.breakpoints.down('sm')]: {

      '&:nth-child(2)': {
        marginTop: 20,
      }
    },

    '& .slick-arrow': {
      width: 50,
      height: 72,
      background: '#fff',
      zIndex: 1,
      opacity: .9,
      transition: 'linear .2s',

      '&:before': {
        color: 'transparent',
        display: 'flex',
        width: 50,
        height: 72,
        backgroundPosition: 'center',
      },

      '&.slick-next': {

        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        right: 0,

        '&:before': {
          backgroundImage: `url('${setaNext}')`,
          backgroundRepeat: 'no-repeat'
        }
      },

      '&.slick-prev': {

        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        left: 0,

        '&:before': {
          backgroundImage: `url('${setaPrev}')`,
          backgroundRepeat: 'no-repeat',

        }
      },

      '&:hover': {
        opacity: 1,
      }
    }
  },

  boxCarouselMobile: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'scroll',
    width: '100%',
    margin: '0 16px 24px 16px',
  },

  carouselMobile: {
    display: 'flex',
    maxWidth: 420,
    width: '100%',
  },

  boxBanner: {
    maxWidth: 270,
  },

  capitalized: {
    textTransform: 'capitalize',
  },

  login: {

    '& .drawer_content': {
      width: 500,
      minHeight: '100vh',

      '& .btn_close_drawer': {
        '& svg': {
          fontSize: '3rem',
          fill: '#271e1e',
        }
      },

      '& .navigation_mobile': {
        '& .label *': {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#271e1e'
        },

        '& .box_btns': {
          '& .label *': {
            color: '#d81717',
          }
        },
      },

      '& .content_drawer': {
        padding: '0 20px',
      },

      [responsive.breakpoints.down('sm')]: {
        width: '100vw'
      }
    }
  },

  unsubscribeButton: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    background: '#d01717',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '.8',
    cursor: 'pointer',
    padding: 10,
    marginLeft: 10,

    '&.active': {
      background: '#fff',

      '& .MuiSvgIcon-root': {
        color: '#020202',
      },
    },

    '&:hover': {
      opacity: .8,
    },

    '& .MuiSvgIcon-root': {
      color: '#fff',
      fontSize: '1.8rem',
    }
  },

  button: {
    fontWeight: '400',
    textTransform: 'initial',
    fontSize: 18,
    letterSpacing: '0.00938em',
    lineHeight: '1.5',
    padding: '10px 20px',
    cursor: 'pointer !important',

    '&.typeUser': {
      pointerEvents: 'none'
    },

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,

      '&  span': {
        cursor: 'pointer',
        fontSize: 12,

        '&  a': {
          marginLeft: 5,
          color: '#419a58',
          pointerEvents: 'auto'
        },

      }
    }
  },

  gifButton: {
    width: 240,
    maxWidth: 240,
    minWidth: 171,
    cursor: 'pointer',

    '& img': {
      width: '100%',
      display: 'block',
    }
  }

}))

export default useStyles
