import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  // imgPrincipal: {
  //   margin: '48px 0',
  //   display: 'flex',

  //   [theme.breakpoints.down('sm')]:{
  //     margin: '48px 0 24px 0',
  //   },

  //   '& h1': {
  //     color: "#d01717",
  //     fontWeight: 'bold',
  //     fontSize: 40,
  //     fontFamily: 'Roboto',

  //     [theme.breakpoints.down('sm')]: {
  //       fontSize: 30,
  //       textAlign: 'center',
  //     },
  //   }
  // },

  title: {
    fontSize: 40,
    color: '#d01717',
    textAlign: 'center',
    margin: '48px 0',

    [theme.breakpoints.down('sm')]: {
      fontSize: 30
    }
  },

  content: {
    paddingTop: '0 !important'
  },

  contentAboutUs: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  division: {
    maxWidth: 1080,
    display: 'flex',
    flexWrap: 'wrap',


    '& .divisionText.fullWidth': {
      width: '100%',
    },

    '& .divisionText': {
      width: '50%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },

      '& p': {
        fontSize: 20,
        color: '#271E1E'
      }
    },

    '& .divisionText, .divisionText p': {
      padding: 10,
    },

    '& .divisionImg': {
      width: '50%',
      padding: 20,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        textAlign: 'center',
        padding: 10,
      },

      '& img': {
        maxWidth: '100%',
        height: 'auto',
      }
    }
  },

  divisionMobile: {

    [theme.breakpoints.down('sm')]: {
      '& .divisionImg': {
        width: '50%!important'
      }
    }
  },

  imgCourses: {
    display: 'flex',
    flexWrap: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },

  frame: {
    [theme.breakpoints.down('sm')]: {
      height: 400,
      margin: '0 16px',
    }
  }
}))

export default useStyles
