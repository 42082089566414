import { Box } from '@material-ui/core'
import CheckBox from '~/Components/Inputs/CheckBox';
import React from 'react'

import useStyles from './style'

const BoxFilter = (props) => {
  const { title, datas, handleChangeFilter } = props

  const style = useStyles()
  return (
    <Box className={`${style.boxFilter} area_carousel`}>
      <p>{title}</p>
      <Box className="area_carousel" style={{ height: 130, overflowX: 'hidden' }}>
        {datas.map((data) => (
          <CheckBox
            key={data.id}
            id={data.type}
            label={data.name}
            name={data.name}
            checked={data.checked}
            handleChangeFilter={handleChangeFilter}
          />
        ))}
      </Box>
    </Box>
  )
}

export default BoxFilter
