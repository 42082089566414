import { createTheme, makeStyles } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({


  BackgroundHeader: {
    background: '#B01010',
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'center',
    minHeight: 175,
    height: 'auto',
  },

  headerCourse: {
    maxWidth: 1240,
    width: '100%',
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'space-between',

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '24px 16px',
    }
  },

  course: {
    maxWidth: 576,
    width: '100%',
    margin: '27px 0',

    [responsive.breakpoints.down('sm')]: {
      margin: '0 0 27px 16px',
    },

    '& h1': {
      color: '#fff',
      fontSize: '2.0vw',
      fontWeight: '700',
      marginBottom: 4,

      [responsive.breakpoints.down('sm')]: {
        fontSize: '18px',
        margin: '0 0 27px 16px',
      }
    },

    '& p': {
      fontSize: 12,
      fontWeight: '400',
      color: '#fff',

      '& > b': {
        textDecoration: 'underline',
        letterSpacing: 2,
        marginLeft: 8,
      },
    },

    '& p:nth-of-type(1)': {
      marginBottom: 4,
    },
    '& p:nth-of-type(2)': {
      marginBottom: 16,
    },
  },

  hour: {

    '& span': {
      marginTop: 16,
      fontSize: 24,
      color: '#fff',
      fontWeight: '700',
      marginRight: 32,
      display: "initial",

      [responsive.breakpoints.down('sm')]: {
        fontSize: 18,
        marginBottom: 4,
      }
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: 220,
      width: '100%',

      '& :last-child': {
        display: 'inline-block',
      },
    }
  },

  contentSubscribe: {
    maxWidth: 436,
    width: '100%',
    height: 119,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: "#fff",
    borderRadius: 8,
    marginRight: 16,

    '& > p': {

      margin: '24px 0 16px 0'
    },

    [responsive.breakpoints.down('sm')]: {
      margin: '0 16px',
      maxWidth: 565,
    }
  },

  photo: {
    width: 125,
    height: 125,
    minWidth: 125,
    maxWidth: 125,
    minHeight: 125,
    maxHeight: 125,
    margin: 24,

    '& img': {
      width: '100%',
      display: 'block',
      borderRadius: '50%',
    },

    [responsive.breakpoints.down('sm')]: {
      margin: '0 16px 41px 0',
    }
  },


  subscribe: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,

    '& > p': {

      margin: '0 24px 0 24px',

      [responsive.breakpoints.down('sm')]: {
        margin: '0 24px 0 0 ',
      }
    },

    '& button': {
      borderRadius: 8,
      border: 'none',
      outline: 'none',
      padding: '12px 55px',
      cursor: 'pointer',
    }
  },

  contentDetails: {
    textAlign: 'center',

    '&  h2': {
      margin: '48px 32px',

    },

    '& p': {
      maxWidth: 1240,
      margin: '0 auto',

      [responsive.breakpoints.down('sm')]: {
        padding: '0 16px',
        textAlign: 'left'
      }
    },

    '& h3': {

      margin: '48px 16px 8px 16px ',
    },

    '& h4': {

      margin: '0 16px 32px 16px',
    },

    '& span': {
      maxWidth: 608,
      width: '100%',
      display: 'block',
      margin: '0 auto',
      lineHeight: '22.4px',

      [responsive.breakpoints.down('sm')]: {
        padding: '0 16px',
      }
    },

    '& button': {
      padding: '12px 24px',
      borderRadius: 8,

      textAlign: 'center',
      border: 'none',
      outline: 'none',
      margin: '32px 16px 48px 16px',
      cursor: 'pointer',

      [responsive.breakpoints.down('sm')]: {
        maxWidth: '90%',
        width: '100%',
      }
    }
  },

  similarCourses: {
    textAlign: 'center',
    margin: '24px 0',
  },

  boxBigSquare: {
    width: '100%',
    height: 590,
    borderRadius: 5,
    overflow: 'hidden',
    background: '#fff',
    position: 'relative',
    transition: 'linear .1s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  boxCard: {
    display: 'flex',
  }

}))

export default useStyles
