import { createTheme, withStyles } from '@material-ui/core/styles'
import { Button, Container, FormControl, IconButton, Typography } from '@material-ui/core'

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const CustomButton = withStyles((theme) => ({
  root: {
    height: 40,
    padding: 10,
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    color: props => props.color_bt ? props.color_bt : '#fff',
    background: props => props.bg ? props.bg : '#b01010',
    border: props => props.border_button ? props.border_button : '',
    '&:hover': {
      background: props => props.bg_hover ? props.bg_hover : props.bg ? props.bg : '#ee2526'
    },

    '&[disabled]': {
      background: props => props.bg_disabled_btn ? props.bg_disabled_btn : '#f5f5f5',
      color: props => props.color_disabled_btn ? props.color_disabled_btn : '#ccc',
      cursor: 'not-allowed',
    }
  }
}))(Button)

const InputForm = withStyles((theme) => ({
  root: {

    '& .MuiFormHelperText-contained': {
      margin: '5px 3px 0',
    },

    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
    },

    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 17px) scale(1)',
    },

    '& label.Mui-focused': {
      color: '#271e1e'
    },

    '& div.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#271e1e'
      }
    },

    '&.is_valid': {
      '& label, label.Mui-focused': {
        color: 'green'
      },

      '& .MuiOutlinedInput-notchedOutline, .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green'
      }
    },

    '&.invalid': {
      '& label:not(.Mui-focused), .MuiFormHelperText-root': {
        color: 'red'
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red'
      },
    }
  }
}))(FormControl)

const TitleForm = withStyles((theme) => ({
  root: {
    fontSize: 22,
    padding: '5px 0',
    color: '#b01010',

  }
}))(Typography)

const TitleTables = withStyles((theme) => ({
  root: {
    fontSize: 30,
    color: '#f4393c'
  }
}))(Typography)

const ContainerApp = withStyles((theme) => ({
  root: {
    maxWidth: 1280,
    padding: '20px 2px',

    [responsive.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 10px',
    }
  }
}))(Container)

const CustomIconButtom = withStyles((theme) => ({
  root: {
    color: props => props.icon_color ? props.icon_color : '#000'
  }
}))(IconButton)

export { CustomButton, InputForm, TitleForm, TitleTables, ContainerApp, CustomIconButtom }
