import React, { useState } from 'react';

/*** Estilização ***/
import useStyles from './style'

/*** Libs e Utils ***/
import { useDispatch } from 'react-redux';
import { signOut } from '~/store/modules/auth/actions';

/*** Componentes ***/
import logo from '../../Assets/img/logo-positivo.jpg'
import { Box, Drawer, Hidden, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { Close, CloseRounded, MenuRounded } from '@material-ui/icons';
import FormLogin from '../Formularios/FormLogin';
import FormUser from '../Formularios/FormUser';
import { ContainerApp } from '~/Components/Custom'
import { store } from '~/store';



function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function NewHeader() {

  const menuHeader = [
    {
      href: '/cursos',
      name: 'PROGRAMAÇÃO COMPLETA'
    },

    {
      href: '/quem-somos',
      name: 'QUEM SOMOS'
    },

    {
      href: '/duvidas',
      name: 'DÚVIDAS'
    },

    {
      href: 'https://www.lojasantoantonio.com.br',
      name: 'LOJA VIRTUAL'
    },
  ]

  const style = useStyles()
  const dispatch = useDispatch()
  const profile = store.getState().user.profile
  const [drawer, setDrawer] = useState({
    menu: false,
    login: false,
    add: false
  })

  const [btnActive, setBtnActive] = useState(true)

  function handleDrawer(type) {
    if (type === 'login') {
      setBtnActive(true)
    }
    if (type === 'add') {
      setBtnActive(false)
    }
    setDrawer({ ...drawer, [type]: !drawer[type] })
  }

  function logout() {
    dispatch(signOut())
  }

  return (
    <>
      <Box className={`${style.header} header`}>
        <ContainerApp>
          <Box className="area_header" display="flex" justifyContent='space-between'>
            <Box className="logo">
              <a href="/">
                <img src={logo} alt="" />
              </a>
            </Box>

            <Hidden smDown>
              <Box className="navigation">
                {menuHeader.map(m => (
                  <a href={m.href} className={style.menuItem} key={m.name}>
                    <span className='label'>{m.name}</span>
                    <span className="underline"></span>
                  </a>
                ))}
              </Box>
            </Hidden>

            <Box display='flex' alignItems='center'>

              {profile !== null ?
                profile.type_user === 'admin' ?
                  <a href="/admin" className={style.areaStudent}>
                    <span>ÁREA ADMINISTRATIVA</span>
                  </a>
                  :
                  profile.type_user === 'teacher' ?
                    <a href="/area-do-professor" className={style.areaStudent}>
                      <span>ÁREA DO PROFESSOR</span>
                    </a>
                    :
                    <a href="/area-do-aluno" className={style.areaStudent}>
                      <span>ÁREA DO ALUNO</span>
                    </a>
                :
                <Box className="btn_in_up_out" display='flex' alignItems='center'>
                  <Box className={style.btnSignUpIn} display='flex'>
                    <span className={`${btnActive ? 'active' : 'inative'} switch`}></span>
                    <span className={`${!btnActive ? 'active' : 'inative'} label`} onClick={e => handleDrawer('add')} >CADASTRE-SE</span> <span className={`${btnActive ? 'active' : 'inative'} label`} onClick={e => handleDrawer('login')}>ENTRE</span>
                  </Box>
                </Box>
              }

              <Hidden mdUp>
                <Box className={style.btnMenuMobile}>
                  <IconButton onClick={e => handleDrawer('menu')}>
                    <MenuRounded />
                  </IconButton>
                </Box>
              </Hidden>
            </Box>
          </Box>
        </ContainerApp>
      </Box>

      <Drawer className={style.drawer} anchor='left' open={drawer.menu} onClose={e => handleDrawer('menu')}>
        <Box role='presentation' className='drawer_content'>
          <Box display='flex' justifyContent='flex-end' alignItems='center' style={{ height: 80 }}>
            <IconButton onClick={e => handleDrawer('menu')} className='btn_close_drawer'>
              <CloseRounded />
            </IconButton>
          </Box>
          <Box className='content_drawer'>
            <List component="nav" className='navigation_mobile'>
              {menuHeader.map(menu => (
                <ListItemLink key={menu.name} href={menu.href} className="label">
                  <ListItemText primary={menu.name} />
                </ListItemLink>
              ))}

              <Box className='box_btns'>
                {profile ?
                  <>
                    <ListItem className="label" onClick={logout}>
                      <ListItemText primary={'SAIR'} />
                    </ListItem>
                  </>
                  :
                  <>
                    <ListItem className="label" onClick={e => handleDrawer('add')}>
                      <ListItemText primary={'CADASTRE-SE'} />
                    </ListItem>

                    <ListItem className="label" onClick={e => handleDrawer('login')}>
                      <ListItemText primary={'ENTRE'} />
                    </ListItem>
                  </>
                }
              </Box>
            </List>
          </Box>
        </Box>
      </Drawer>

      <Drawer className={style.drawer} anchor={window.innerWidth < 960 ? 'bottom' : 'right'} open={drawer.add} onClose={e => handleDrawer('add')}>
        <Box role='presentation' className='drawer_content'>
          <Box display='flex' justifyContent='flex-end' alignItems='center' style={{ height: 80 }}>
            <IconButton onClick={e => handleDrawer('add')} className='btn_close_drawer'>
              <Close />
            </IconButton>
          </Box>
          <Box className="content_drawer">
            <FormUser handleDrawer={handleDrawer} />
          </Box>
        </Box>
      </Drawer>

      <Drawer className={style.drawer} anchor={window.innerWidth < 960 ? 'bottom' : 'right'} open={drawer.login} onClose={e => handleDrawer('login')}>
        <Box role='presentation' className='drawer_content'>
          <Box display='flex' justifyContent='flex-end' alignItems='center' style={{ height: 80 }}>
            <IconButton onClick={e => handleDrawer('login')} className='btn_close_drawer'>
              <Close />
            </IconButton>
          </Box>

          <Box className="content_drawer">
            <FormLogin handleDrawer={handleDrawer} />
          </Box>

        </Box>
      </Drawer>
    </>
  );
}

export default NewHeader;
