import React, { useEffect, useState } from 'react';

import useStyles from './style';
import globalStyles from '~/Assets/css/globalStyles';

import DashAdmin from '~/Components/DashAdmin';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Paper from '@material-ui/core/Paper';
import api from '~/Services/api';

import moment from 'moment';
moment.locale('pt-br');

const TableList = () => {
  const classes = useStyles();
  const pattern = globalStyles();

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    requestCoursesNow();
  }, []);

  const requestCoursesNow = async () => {
    const res = await api.get('coursecurrent');
    setCourses(res.data);
  };

  return (
    <>
      <TableContainer component={Paper} className={pattern.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell align="center">Nome do curso</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Horário</TableCell>
              <TableCell align="center">List de presença</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map(c => (
              <TableRow key={c.id}>
                <TableCell align="center">{c.name}</TableCell>
                <TableCell align="center">
                  {moment(c.date_of_course).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center">{`${c.hour_start} ás ${c.hour_end}`}</TableCell>
                <TableCell align="center">
                  <a href={`/admin/lista-de-presenca/${c.id}`}>
                    <PlaylistAddCheckIcon
                      className={classes.linkListPresence}
                    />
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default function TablePresence() {
  return (
    <DashAdmin width={1200} title={'Cursos de Hoje'} content={<TableList />} />
  );
}
