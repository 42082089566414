import React, { createContext, useState } from 'react'
import api from '~/Services/api'
import history from '~/Services/history'

const Context = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    cpf: '',
    password: ''
  })
  const [validateDataUser, setValidateDataUser] = useState(false)

  const [createUser, setCreateUser] = useState({
    cpf: '',
    fullname: '',
    email: '',
    telephoneOne: '',
    telephoneTwo: '',
    password: '',
    confirmPassword: ''
  })

  const [alertMessage, setAlertMessage] = useState({
    status: '',
    msg: ''
  })

  const getMessage = (status, msg) => {
    setAlertMessage({
      status: status,
      msg: msg
    })

    setTimeout(() => {
      setAlertMessage({
        msg: '',
        status: ''
      })
    }, 3000)

  }

  const signIn = async () => {
    const obj = {
      cpf: user.cpf.replace(/[^0-9]/g, ''),
      password: user.password
    }

    try {
      const { data } = await api.post('sessions', obj)

      if (data.user) {
        getMessage('success', 'Login efetuado com sucesso!')

        localStorage.setItem('persist:cursos', JSON.stringify({
          user: JSON.stringify({
            profile: data.user
          }),
          auth: JSON.stringify({
            token: data.token,
            signed: true
          })
        }))

        setTimeout(() => {
          if (history.location.state !== undefined) {
            window.location.href = window.location.href
          }

          if (data.user.type_user === 'student' || data.user.type_user === 'teacher') {
            window.location.href = window.location.href
          }

          if (data.user.type_user === 'admin') {
            window.location.href = '/admin'
          }
        }, 500)
      }

      if (data.message) {
        getMessage('error', 'Usuário bloqueado')
      }

    } catch (error) {
      getMessage('error', 'Login ou senha inválidos!')
    }

  }

  const signUp = async () => {
    const obj = {
      cpf: createUser.cpf.replace(/[^0-9]/g, ''),
      fullname: createUser.fullname,
      email: createUser.email,
      telephoneOne: createUser.telephoneOne.replace(/[^0-9]/g, ''),
      telephoneTwo: createUser.telephoneTwo.replace(/[^0-9]/g, ''),
      password: createUser.password,
    }

    try {
      const { data } = await api.post('users', obj)
      console.log(data)

      if (data.error) {
        getMessage('error', 'Usuário já cadastrado!')
        return
      }

      getMessage('success', 'Cadastro realizado com sucesso!')
    } catch (error) {

      getMessage('error', 'Não foi possível realizar seu cadastro!')
    }
  }

  const handleSignUp = (e) => {
    e.preventDefault()
    signUp()
  }

  const handleSignIn = (e) => {
    e.preventDefault()
    signIn()
  }

  return (
    <Context.Provider value={{
      user, setUser, handleSignIn,
      createUser, setCreateUser, handleSignUp,
      validateDataUser, setValidateDataUser,
      alertMessage,
    }}>
      {children}
    </Context.Provider>
  )
}

export { Context, AuthProvider }
