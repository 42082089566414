import React, { useState, useEffect } from 'react';
import * as yup from 'yup'
import { Grid, FormControl, InputLabel, OutlinedInput, FormHelperText, Button, makeStyles, Typography, Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import globalStyles from '~/Assets/css/globalStyles';
import api from '~/Services/api';
import history from '~/Services/history';

const useStyles = makeStyles(theme => ({
  stars: {
    '& label span:first-child': {
      fontSize: 25,
    }
  }
}))

export default function FormDepoiment({ sub }) {
  const pattern = globalStyles()
  const classes = useStyles()

  useEffect( () => {
    requestSubscribe()
  }, [])

  const [btnSubmit, setBtnSubmit] = useState(false)
  const [status, setStatus] = useState({
    about_course: '',
    about_teacher: '',
    rating_teacher: '',
  })

  const [msg, setMsg] = useState({
    about_course: '',
    about_teacher: '',
    rating_teacher: '',
  })

  const [depoiment, setDepoiment] = useState({
    subscribe_id: sub,
    course_id: null,
    about_course: '',
    about_teacher: '',
    rating_teacher: null,
  })

  async function requestSubscribe(){
    const res = await api.get(`subscribe/${sub}`)
    setDepoiment({ ...depoiment, course_id: res.data.course_id, subscribe_id: res.data.id})
  }

  const handleValues = (field, e) => {
    setDepoiment({ ...depoiment, [field]: e.target.value })
    valid(field, { ...depoiment, [field]: e.target.value })
  }

  const schema = yup.object().shape({
    subscribe_id: yup.number().required(),
    course_id: yup.number().required(),
    about_course: yup.string().required("Campo Obrigatório!").min(10, 'Mínimo 10 caracteres'),
    about_teacher: yup.string().required("Campo Obrigatório!").min(10, 'Mínimo 10 caracteres'),
    rating_teacher: yup.number().required("Campo Obrigatório!").min(1, '').max(5, ''),
  })


  const valid = (field, obj) => {
    schema.validateAt(field, obj).then(() => {
      setMsg({ ...msg, [field]: "" })
      setStatus({ ...status, [field]: "isValid" })
    }).catch(err => {
      setMsg({ ...msg, [field]: err.errors })
      setStatus({ ...status, [field]: "invalid" })
    })

    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  const addDepoiment = async (e) => {
    e.preventDefault()
    console.log(depoiment)
    const res = await api.post('depoiment', depoiment)
    // if(res.data[0].id){
    //   history.push(`/certificado/${sub}`)
    // }
    console.log(res)
  }

  return (
    <>
      <form onSubmit={e => addDepoiment(e)} className={pattern.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              className={status.about_course}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="about_course" variant="outlined">Depoimento sobre o Curso</InputLabel>
              <OutlinedInput
                defaultValue={depoiment.about_course}
                onChange={e => handleValues('about_course', e)}
                onBlur={e => handleValues('about_course', e)}
                multiline
                rows={6}
                id="about_course"
                labelWidth={195}
              />
              <FormHelperText>{msg.about_course}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              className={status.about_teacher}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="about_teacher" variant="outlined">Depoimento sobre o professor</InputLabel>
              <OutlinedInput
                defaultValue={depoiment.about_teacher}
                onChange={e => handleValues('about_teacher', e)}
                onBlur={e => handleValues('about_teacher', e)}
                id="about_teacher"
                multiline
                rows={6}
                labelWidth={215}
              />
              <FormHelperText>{msg.about_teacher}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Typography component="legend">Avalie o professor</Typography>
              <Rating
                className={classes.stars}
                name="rating_teacher"
                defaultValue={depoiment.rating_teacher}
                onChange={(e, value) => {
                  setDepoiment({...depoiment, rating_teacher: value});
                  valid('rating_teacher', { ...depoiment, rating_teacher: value })
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth type="submit" disabled={!btnSubmit}>Enviar Depoimento</Button>
          </Grid>

        </Grid>
      </form>
    </>
  );
}
