import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

import NewHeader from '~/Components/NewHeader';
import { CustomButton } from '~/Components/Custom';
import FormLogin from '~/Components/Formularios/FormLogin';
import SubscribeModal from '~/Components/Modals/SubscribeModal';
import UnsubscribeModal from '~/Components/Modals/UnsubscribeModal';
import Footer from '~/Components/Footer';
import { singInExpired } from '~/store/modules/user/actions';
import { useDispatch } from 'react-redux';

import api from '~/Services/api';

import { store } from '~/store';

import ImgUser from '~/Assets/img/vazio.png';
import CourseWarning from '~/Assets/img/barra-de-regras-desk.png';
import CourseWarningMobile from '~/Assets/img/barra-de-regras-mobile.png';
import GifButton from '~/Assets/img/gif-button.gif';

import { addDays, differenceInDays, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { DateTime } from 'luxon';

import useStyles from './style';
import { toast } from 'react-toastify';
import ImageSlider from '~/Components/Slides/SlideImagesRecipe';

DateTime.local().setZone('America/Sao_Paulo');

const CourseV3 = props => {
  const parameterUrl = props.match.params.id;
  const idCourse = parameterUrl.slice(parameterUrl.lastIndexOf('-')).slice(1);
  const profile = store.getState().user.profile;
  const dispatch = useDispatch();

  const [course, setCourse] = useState({});
  const [courseImage, setCourseImage] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalUnsubscribe, setOpenModalUsubscribe] = useState(false);
  const [subscribeCourse, setSubscribeCourse] = useState('');
  const [subscribeID, setSubscribeID] = useState('');
  const [handleLogin, setHandleLogin] = useState(false);
  const [isPratical, setIsPratical] = useState(false);
  const [statusPratical, setStatusPratical] = useState('');
  const [btnSubmit, setBtnSubmit] = useState(true);
  const [configSubscribe, setConfigSubscribe] = useState({
    label: 'Inscreva-se',
    labelColor: '#b01010',
    bgButton: '#ffe769',
    buttonDisabled: false,
    subscribe: 'subscribe',
    isSubcribe: true,
  });
  const [activeStep, setActiveStep] = useState(1);
  const [confirmationButton, setConfirmationButton] = useState(true);

  const nameCourseFormated =
    course.name &&
    course.name.slice(0, 1).concat(course.name.substr(1).toLowerCase());
  const dayWeekCoruse =
    course.date_of_course &&
    format(addDays(new Date(course.date_of_course), 1), 'EEEE', {
      locale: ptBR,
    });
  const dayWeekCoruseFormated =
    dayWeekCoruse &&
    dayWeekCoruse
      .charAt(0)
      .toUpperCase()
      .concat(dayWeekCoruse.slice(1));
  const dayCourseFormated =
    course.date_of_course &&
    format(addDays(new Date(course.date_of_course), 1), 'dd/MM/yyyy', {
      locale: ptBR,
    });
  const startHourCourseFormated = course?.hour_start?.substr(0, 5);
  const EndtHourCourseFormated = course?.hour_end?.substr(0, 5);
  const isCourseFreeOrNot = course?.donation ? 'Grátis' : `${course.price}`;
  const donatioCourse = course.donation
    ?.slice(0, 1)
    .toUpperCase()
    .concat(course.donation.substr(1).toLowerCase());
  const isCoursePraticalOrNot =
    course.is_pratical === 0 ? 'Teórico' : 'Prático';
  const totalNumberSeatsAvaliable = parseInt(course?.totalQuantity);
  const quantityCourse = course.quantity && parseInt(course.quantity);
  const messageWhatsapp = `Olá **${course.fullname}** Quero me inscrever no curso ${course.name} do dia ${dayCourseFormated} na loja santo antonio, como eu faço para efetuar o pagamento?`;

  const courseWarningMessage =
    isCourseFreeOrNot === 'Grátis' ? (
      <span>
        Você não precisa pagar nada. Apenas pedimos para levar no dia uma
        contribuição simbólica de {donatioCourse}, para ajudar instituições
        carentes.
      </span>
    ) : (
      <span>
        Basta comparecer ao curso e efetuar o pagamento em dinheiro direto para
        o(a) {course.teacherName}(a).
      </span>
    );

  const diff = differenceInDays(
    addDays(new Date(course.date_of_course), 1),
    new Date()
  );

  const maskTelephone = telephone => {
    telephone = telephone.replace(/\D/g, ''); //Remove tudo o que não é dígito
    telephone = telephone.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    telephone = telephone.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return telephone;
  };

  const getCourse = async () => {
    const res = await api.get(`course/${idCourse}`);
    setCourse(res.data[0]);

    if (!res.data.error) {
      setCourse(res.data[0]);
    }
  };

  const getCourseImage = async () => {
    const res = await api.get(`course/${idCourse}/image`);
    setCourseImage(res.data); // obj { image, alt, url }

    if (!res.data.error) {
      setCourseImage(res.data);
    }
  };

  const getSubscribeCourse = async () => {
    if (profile) {
      try {
        const sub = await api.get(
          `course_subscribe/${idCourse}/student/${profile.id}`
        );
        setSubscribeCourse(sub.data.message);
        setSubscribeID(sub.data.subscribeID);
        setStatusPratical(sub.data.status_pratical);

        if (sub.data.message === 'registered') {
          setConfigSubscribe({
            ...configSubscribe,
            label: 'Você está inscrito',
            bgButton: '#419a58',
            labelColor: '#fff',
            buttonDisabled: true,
          });
          return;
        }
      } catch (error) {
        dispatch(singInExpired());
      }
    }
  };

  const handleOpenModal = () => {
    if (profile !== null) {
      setOpenModal(!openModal);
    } else {
      setHandleLogin(!handleLogin);
    }
  };

  const handleCloseLogin = () => {
    setHandleLogin(!handleLogin);
  };

  const handleSubmitCourse = async () => {
    try {
      const response = await api.post('subscribe', {
        student_id: profile.id,
        course_id: idCourse,
        status_pratical: isPratical,
      });

      setTimeout(() => {
        if (isCoursePraticalOrNot === 'Prático') {
          setConfigSubscribe({
            label: 'Aguardando confirmação',
            bgButton: '#fff',
            border_button: '1px solid #D01717',
            labelColor: '#D01717',
            buttonDisabled: true,
          });
        } else {
          setConfigSubscribe({
            label: 'Você está inscrito',
            bgButton: '#419a58',
            labelColor: '#fff',
            buttonDisabled: true,
          });
        }

        setSubscribeCourse('registered');
        setActiveStep(prevActiveStep => ++prevActiveStep);
      }, 3000);
    } catch (error) {
      if (error.request.status === 403) {
        setTimeout(() => {
          toast.warning(
            'Não foi possível realizar o seu cadastro, pois o curso está esgotado'
          );
          setOpenModal(false);
          setConfirmationButton(false);
          setConfigSubscribe({
            label: 'Esgotado',
            bgButton: '#fff',
            labelColor: '#B01010',
            buttonDisabled: true,
          });
        }, 2000);
      }
    }
  };

  const handleOpenUnsubcribeModal = () => {
    setOpenModalUsubscribe(!openModalUnsubscribe);
    setBtnSubmit(true);
  };

  useEffect(() => {
    if (profile !== null) {
      getSubscribeCourse();
    }
  }, [profile]);

  useEffect(() => {
    if (
      subscribeCourse === 'registered' &&
      statusPratical !== 1 &&
      course.is_pratical === 1
    ) {
      setConfigSubscribe({
        label: 'Aguardando confirmação',
        bgButton: '#ffabab',
        labelColor: '#fff',
        buttonDisabled: true,
      });
    }

    if (
      totalNumberSeatsAvaliable - quantityCourse <= 10 &&
      course.status !== 'canceled' &&
      subscribeCourse !== 'registered'
    ) {
      setConfigSubscribe({
        ...configSubscribe,
        label: 'Últimas vagas',
        bgButton: '#ffe769',
        labelColor: '#060505',
        dataset: 'ultimas vagas',
        gifButton: GifButton,
      });
    }

    if (totalNumberSeatsAvaliable <= quantityCourse) {
      setConfigSubscribe({
        label: 'Esgotado',
        bgButton: '#fff',
        labelColor: '#B01010',
        buttonDisabled: true,
      });
    }

    if (diff > course.days_for_publication) {
      setConfigSubscribe({
        label: 'Inscrições em breve',
        bgButton: '#ffabab',
        labelColor: '#fff',
        buttonDisabled: true,
      });
    }

    if (course.status === 'canceled') {
      setConfigSubscribe({
        label: 'Cancelado',
        bgButton: '#fff',
        labelColor: '#b01010',
        buttonDisabled: true,
      });
    }
  }, [course]);

  useEffect(() => {
    if (idCourse) {
      getCourse();
      getCourseImage();
    }
  }, []);

  const style = useStyles();
  return (
    <>
      <Grid container className={style.container}>
        <NewHeader />

        {Object.keys(course).length > 0 ? (
          <Grid container>
            <Grid item xs={12} className={style.boxSubscribe}>
              <Grid container className={`${style.dFlex} subscribe`}>
                <Grid item md={9}>
                  <Typography className={style.nameCourse}>
                    {nameCourseFormated}
                  </Typography>
                  <Typography component="span" className={style.nameCategories}>
                    {course?.categories_names?.includes(',')
                      ? 'Categorias: '
                      : 'Categoria: '}
                    {<b>{course?.categories_names}</b>}
                  </Typography>
                </Grid>

                <Grid item md={3} className={style.containerButtons}>
                  <CustomButton
                    className={`${style.buttonSubscribe}`}
                    bg={configSubscribe?.bgButton}
                    bg_disabled_btn={configSubscribe?.bgButton}
                    border_button={configSubscribe?.border_button}
                    color_bt={configSubscribe?.labelColor}
                    id="gif_label_subscribe"
                    data-subscribe={
                      configSubscribe?.label === 'Inscreva-se'
                        ? configSubscribe?.subscribe
                        : ''
                    }
                    disabled={configSubscribe?.buttonDisabled}
                    color_disabled_btn={configSubscribe?.labelColor}
                    onClick={handleOpenModal}
                    data-waiting_confirmation={configSubscribe.label}
                  >
                    {configSubscribe.label}
                  </CustomButton>
                  {subscribeCourse === 'registered' && (
                    <Button
                      onClick={handleOpenUnsubcribeModal}
                      className={style.buttonUnsubscribe}
                    >
                      Cancelar inscrição
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={`${style.dFlex} box_description`}>
              <Grid item xs={12} md={5}>
                <Grid className={`${style.period} date`}>
                  <Typography>Data: </Typography>
                  <Typography component="span">
                    {dayCourseFormated} - {dayWeekCoruseFormated}
                  </Typography>
                </Grid>
                <Grid className={`${style.period} hour`}>
                  <Typography>Horario: </Typography>
                  <Typography component="span">
                    {startHourCourseFormated} - {EndtHourCourseFormated}{' '}
                  </Typography>
                </Grid>
                <Grid className={`${style.period} price`}>
                  <Typography>Valor: </Typography>
                  <Typography component="span">
                    {isCourseFreeOrNot === 'Grátis'
                      ? `Grátis`
                      : isCourseFreeOrNot}{' '}
                  </Typography>
                  <Typography component="span">
                    {isCourseFreeOrNot === 'Grátis'
                      ? `Trazer: ${donatioCourse} para doação`
                      : ''}{' '}
                  </Typography>
                </Grid>
                {configSubscribe?.label === 'Aguardando confirmação' && (
                  <Grid className={`${style.period} telephone`}>
                    <Typography>Telefone do professor:</Typography>
                    <Typography component="span">
                      <a
                        href={`https://api.whatsapp.com/send?phone=+55${maskTelephone(
                          course?.telephoneOne
                        )}&text=${encodeURIComponent(messageWhatsapp)}`}
                      >
                        <b>{course?.telephoneOne}</b>
                      </a>
                    </Typography>
                  </Grid>
                )}
                <Grid className={style.descriptionCourse}>
                  <Typography>{course?.description}</Typography>
                  <Typography className={style.paymentAlert}>
                    <b>Curso: {isCoursePraticalOrNot}</b>
                  </Typography>
                </Grid>
                <Grid className={style.photoCourse}>
                  {courseImage.length > 0
                    ? <ImageSlider images={courseImage}/>
                    : null}
                </Grid>
              </Grid>

              <Grid className={style.divisor}></Grid>

              <Grid item xs={12} md={5}>
                <Box className={style.photo}>
                  <img
                    src={course?.path_image || ImgUser}
                    alt="imagem do professor"
                  />
                </Box>
                <Typography className={style.nameTeacher}>
                  {course?.fullname}
                </Typography>
                <Typography className={`${style.nameTeacher} about_course`}>
                  Sobre o professor:
                </Typography>
                <Typography className={style.descriptionTeacher}>
                  {course?.resum_teacher}
                </Typography>
              </Grid>
            </Grid>

            <Grid className={style.courseImageWarning}>
              <Box className="image_desk">
                <img src={CourseWarning} alt="Cuidados sobre o curso" />
              </Box>
              <Box className="image_mobile">
                <img
                  src={CourseWarningMobile}
                  alt="cuidados sobre o curso mobile"
                />
              </Box>
              <Typography className={style.messageWarningCourse}>
                <b>Atenção!</b> Após 3 faltas sua conta será automaticamente
                bloqueada e será necessário entrar em contato pelo número (11)
                2388-3378 para solicitar o desbloqueio.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} className={style.boxSubscribe}>
              <Grid container className={`${style.dFlex} subscribe`}>
                <Grid item md={9}>
                  <Typography className={style.nameCourse}>
                    <Skeleton
                      variant="rect"
                      width={210}
                      height={20}
                      style={{ margin: '10px 0' }}
                    />
                  </Typography>
                  <Typography className={style.nameCourse}>
                    <Skeleton
                      variant="rect"
                      width={210}
                      height={20}
                      style={{ margin: '10px 0' }}
                    />
                  </Typography>
                  <Typography className={style.nameCourse}>
                    <Skeleton
                      variant="rect"
                      width={100}
                      height={20}
                      style={{ margin: '10px 0' }}
                    />
                  </Typography>
                </Grid>

                <Grid item md={3} className={style.containerButtons}>
                  <CustomButton className={`${style.buttonSubscribe}`}>
                    <Skeleton variant="rect" width={210} height={50} />
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={`${style.dFlex} box_description`}>
              <Grid item xs={12} md={5}>
                <Grid className={`${style.period} date`}>
                  <Typography>
                    <Skeleton
                      variant="rect"
                      width={230}
                      height={20}
                      style={{ margin: '40px 0 20px 0' }}
                    />
                  </Typography>
                </Grid>
                <Grid className={`${style.period} hour`}>
                  <Typography>
                    <Skeleton
                      variant="rect"
                      width={230}
                      height={20}
                      style={{ margin: '20px 0' }}
                    />
                  </Typography>
                </Grid>
                <Grid className={`${style.period} price`}>
                  <Typography>
                    <Skeleton
                      variant="rect"
                      width={230}
                      height={20}
                      style={{ margin: '20px 0 60px 0' }}
                    />
                  </Typography>
                </Grid>
                <Grid>
                  <Typography style={{ marginTop: 40 }}>
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                    <Skeleton variant="text" style={{ margin: '10px 0' }} />
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={style.divisor}></Grid>

              <Grid item xs={12} md={5}>
                <Box className={style.photo}>
                  <Skeleton variant="circle" width={128} height={128} />
                </Box>
                <Typography className={style.nameTeacher}>
                  <Skeleton variant="rect" width={130} height={20} />
                </Typography>
                <Typography className={`${style.nameTeacher} about_course`}>
                  <Skeleton variant="rect" width={130} height={20} />
                </Typography>
                <Typography style={{ marginTop: 40 }}>
                  <Skeleton variant="text" style={{ margin: '10px 0' }} />
                  <Skeleton variant="text" style={{ margin: '10px 0' }} />
                  <Skeleton variant="text" style={{ margin: '10px 0' }} />
                  <Skeleton variant="text" style={{ margin: '10px 0' }} />
                  <Skeleton variant="text" style={{ margin: '10px 0' }} />
                  <Skeleton variant="text" style={{ margin: '10px 0' }} />
                </Typography>
              </Grid>
            </Grid>

            <Grid className={style.courseImageWarning}>
              <Skeleton
                variant="rect"
                width={380}
                height={128}
                style={{ margin: 'auto' }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Footer />

      {handleLogin !== false ? (
        <Drawer
          className={style.login}
          anchor={window.innerWidth < 960 ? 'bottom' : 'right'}
          open={handleLogin}
        >
          <Box role="presentation" className="drawer_content">
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              style={{ height: 80 }}
            >
              <IconButton
                onClick={handleCloseLogin}
                className="btn_close_drawer"
              >
                <Close />
              </IconButton>
            </Box>

            <Box className="content_drawer">
              <FormLogin handleDrawer={handleLogin} />
            </Box>
          </Box>
        </Drawer>
      ) : (
        ''
      )}

      <SubscribeModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        nameTeacher={course.fullname}
        nameCourse={nameCourseFormated}
        dayCourseFormated={dayCourseFormated}
        CoursePraticalOrNot={isCoursePraticalOrNot}
        courseFreeOrNot={isCourseFreeOrNot}
        donatioCourse={donatioCourse}
        telephoneTeacher={course.telephoneOne}
        handleSubmitCourse={handleSubmitCourse}
        setSubscribeCourse={setSubscribeCourse}
        setIsPratical={setIsPratical}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        confirmationButton={confirmationButton}
        setConfirmationButton={setConfirmationButton}
      />

      <UnsubscribeModal
        open={openModalUnsubscribe}
        handleDialog={handleOpenUnsubcribeModal}
        subscribe_id={subscribeID}
        student_id={profile?.id}
        course_id={course?.id}
        setUnsubscribe={setSubscribeCourse}
        btnSubmit={btnSubmit}
        setBtnSubmit={setBtnSubmit}
        setConfigSubscribe={setConfigSubscribe}
      />
    </>
  );
};

export default CourseV3;
