import React from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import useStyles from './style'
const CheckBox = ({ id, name, label, checked, handleChangeFilter, ...rest }) => {

  const style = useStyles()
  return (
    <FormGroup>
      <FormControlLabel
        className={style.checkBox}
        control={
          <Checkbox
            id={id}
            name={name}
            value={name}
            checked={checked}
            onChange={handleChangeFilter}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default CheckBox
