import { createTheme } from '@material-ui/core';
const { makeStyles } = require('@material-ui/styles');

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(theme => ({
  container: {
    background: '#f4f4f4',
  },

  boxSubscribe: {
    background: '#B01010',
    minHeight: 172,
    display: 'flex',

    [responsive.breakpoints.down('sm')]: {
      height: 121,
    },
  },

  paymentAlert: {
    marginTop: 20,
  },

  nameAndSubscribeCourse: {
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  nameCourse: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '3.0vw',
    lineHeight: 1.2,
    marginBottom: 5,
    maxWidth: 850,
    width: '100%',

    [responsive.breakpoints.down('sm')]: {
      fontSize: '5vw',
      lineHeight: 1.5,
      paddingRight: 5,
    },
  },

  nameCategories: {
    color: '#fff',
  },

  period: {
    '&.date': {
      marginBottom: 10,
    },
    '&.hour': {
      marginBottom: 10,
    },
    '&.price': {
      marginBottom: 10,
      fontSize: 18,

      '& span': {
        '&:last-child': {
          fontSize: 18,
          display: 'block',
        },
      },
    },
    '&.telephone': {
      marginBottom: 10,
      fontSize: 18,

      '& span': {
        '& a': {
          marginLeft: 5,
          color: '#574f4f',
          fontWeight: 'bold',
        },
      },

      [responsive.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },

    '& p': {
      display: 'inline',
      color: '#b01010',
      fontSize: 28,
      fontWeight: 'bold',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },

    '& span': {
      color: '#574f4f',
      fontSize: 28,
      fontWeight: 'bold',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  },

  descriptionCourse: {
    '& p': {
      fontSize: 28,
      fontWeight: '500',
      color: '#574f4f',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  },

  divisor: {
    width: 3,
    height: '100%',
    background: '#c7c7c7',

    [responsive.breakpoints.down('sm')]: {
      height: 4,
      width: '100%',
      margin: '40px 0 21px 0',
    },
  },

  photo: {
    width: 128,
    height: 128,
    borderRadius: '50%',
    marginBottom: 18,

    '& img': {
      border: '6px solid #b01010',
      width: '100%',
      display: 'block',
      borderRadius: '50%',
    },
  },

  photoCourse: {
    marginTop: 10,
    '& img': {
      width: 520,
      height: 'auto',
      maxHeight: 250,
      borderRadius: 10,
      objectFit: 'cover',

      [responsive.breakpoints.down('sm')]: {
        width: "100%",
        height: 'auto',
      },
      [responsive.breakpoints.down('md')]: {
        width: "100%",
        height: 'auto',
        maxHeight: 200,
      },
    },
  },

  nameTeacher: {
    fontSize: 28,
    color: '#B01010',
    fontWeight: 'bold',
    marginBottom: 18,

    [responsive.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    '&.about_course': {
      color: '#574f4f',
      marginBottom: 2,
    },
  },

  descriptionTeacher: {
    color: '#574f4f',
    fontSize: 28,

    [responsive.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },

  courseImageWarning: {
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',
    padding: '54px 20px 75px 20px',

    '& .image_desk': {
      display: 'block',

      [responsive.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    '& .image_mobile': {
      display: 'none',

      [responsive.breakpoints.down('sm')]: {
        display: 'block',
      },
    },

    '& img': {
      display: 'block',
      width: '100%',
    },
  },

  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonSubscribe: {
    maxWidth: 333,
    height: 63,
    width: '100%',
    borderRadius: 14,
    fontWeight: 'bold',
    fontSize: 27,
    textTransform: 'initial',
    marginBottom: 13,

    [responsive.breakpoints.down('sm')]: {
      height: 48,
      fontSize: 20,
    },

    '&:hover': {
      opacity: 0.9,
    },

    '&:not([data-waiting_confirmation="Inscreva-se"],[data-waiting_confirmation="Cancelado"],[data-waiting_confirmation="Esgotado"])': {
      [responsive.breakpoints.down('sm')]: {
        whiteSpace: 'initial',
        width: 145,
        maxWidth: 145,
        minWidth: 'initial',
        lineHeight: 1.1,
        height: 60,
      },
    },

    '&:[data-waiting_confirmation="Aguardando confirmação"])': {
      [responsive.breakpoints.down('sm')]: {
        whiteSpace: 'initial',
        width: 183,
        maxWidth: 183,
        minWidth: 'initial',
        lineHeight: 1.1,
        height: 60,
      },
    },
  },

  buttonUnsubscribe: {
    color: '#fff',

    '& > span': {
      textDecoration: 'underline',

      [responsive.breakpoints.down('sm')]: {
        whiteSpace: 'noWrap',
      },
    },
  },

  login: {
    '& .drawer_content': {
      width: 500,
      minHeight: '100vh',
      transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '& .btn_close_drawer': {
        '& svg': {
          fontSize: '3rem',
          fill: '#271e1e',
        },
      },

      '& .navigation_mobile': {
        '& .label *': {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#271e1e',
        },

        '& .box_btns': {
          '& .label *': {
            color: '#d81717',
          },
        },
      },

      '& .content_drawer': {
        padding: '0 20px',
      },

      [responsive.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
  },

  dFlex: {
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',
    padding: '0 20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&.box_description': {
      alignItems: 'start',
      marginTop: 68,

      [responsive.breakpoints.down('sm')]: {
        marginTop: 48,
      },
    },

    '&.subscribe': {
      flexWrap: 'nowrap',
    },
  },

  messageWarningCourse: {
    textAlign: 'center',
    color: '#574f4f',
    fontSize: 17,
    marginTop: 20,
  },
}));

export default useStyles;
