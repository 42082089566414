import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { format, isBefore } from 'date-fns'
import React, { useState, useEffect } from 'react'
import api from '~/Services/api'
import { store } from '~/store'
import GenerateCertificate from '~/Components/GenerateCertificate'
import Profile from '~/Components/Profile'

import useStyles from './style'

const CompletedCourses = () => {

  const { profile } = store.getState().user
  const [course, setCourse] = useState([])
  const [notFound, setNotFound] = useState(false)

  const getCompletedCourses = async () => {
    const res = await api.get(`/student/my_subscribes/${profile.id}?realized=true`)
    if (res.data.length > 0) {
      setCourse(res.data)
    } else {
      setTimeout(() => {
        setNotFound(true)
      }, 1000)
    }
  }

  useEffect(() => {
    getCompletedCourses()
  }, [])

  const style = useStyles()

  return (
    <Profile title="Cursos realizados">
      <Box className={style.container}>
        {
          course.length > 0 ?
            <Box className={style.content}>
              {course.map(course => (
                <GenerateCertificate key={course.id} course={course} />
              ))}
            </Box>
            :
            notFound ?
              <p className={style.message}>Nenhum Curso realizado</p>
              :
              <>
                {[1, 2, 3, 4, 5, 6].map(skeleton => (
                  <Box key={skeleton} className={style.skeleton}>
                    <p> <Skeleton variant="rect" /></p>
                    <p> <Skeleton variant="rect" /></p>
                    <p> <Skeleton variant="rect" /></p>
                    <button> <Skeleton variant="rect" /></button>
                  </Box>
                ))}
              </>
        }
      </Box>
    </Profile >
  )
}

export default CompletedCourses
