import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Box,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

import api from '~/Services/api';

const UnsubscribeModal = ({
  open,
  handleDialog,
  subscribe_id,
  student_id,
  course_id,
  setConfigSubscribe,
  setUnsubscribe,
  setSubscribeCourse,
  btnSubmit,
  setBtnSubmit,
}) => {
  const inputRef = useRef(null);
  const handleChange = () => {
    if (inputRef.current.value.length > 10) {
      setBtnSubmit(false);
    } else {
      setBtnSubmit(true);
    }
  };

  const renunciation = async e => {
    e.preventDefault();
    setBtnSubmit(true);

    try {
      const res = await api.put(`/subscribe/${subscribe_id}`, {
        renunciation: 1,
        reason: inputRef.current.value,
        course_id,
      });

      if (res.data) {
        setUnsubscribe('not subscribe');
        inputRef.current.value = '';
        handleDialog();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      console.error("Erro ao cancelar inscrição:", error);
    } finally {
      setBtnSubmit(false);
    }
  };

  const style = useStyles();
  return (
    <>
      <Dialog open={open}>
        <Grid className={`${style.canceledCourse} `}>
          <Box className={style.closeButtonModalUnsubscribe}>
            <CloseIcon onClick={handleDialog} />
          </Box>
          <form onSubmit={renunciation}>
            <Box className={`${style.boxCanceled}`}>
              <h3>Cancelar Inscrição?</h3>
              <p>Ao cancelar, essa vaga ficará disponível em nosso site. </p>
              <TextField
                multiline
                rows={6}
                name={'reason'}
                onChange={handleChange}
                inputRef={inputRef}
                placeholder="Escreva aqui o motivo do cancelamento para confirmar."
              />
              <Button disabled={btnSubmit} type="submit">
                Sim, quero cancelar
              </Button>
            </Box>
          </form>
        </Grid>
      </Dialog>
    </>
  );
};

export default UnsubscribeModal;
