import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, FormControl, InputLabel, OutlinedInput, FormHelperText, DialogTitle, DialogActions, Button, Modal } from '@material-ui/core';
import globalStyles from '~/Assets/css/globalStyles';

import PropTypes from 'prop-types'
import * as yup from 'yup'

import { updateProfileRequest, updateProfileFailure } from '~/store/modules/user/actions'
import api from '~/Services/api';
import { useDispatch } from 'react-redux';


function FirstAccess({ profile }) {

  const dispatch = useDispatch()
  const pattern = globalStyles()

  const obj = {
    id: '',
    fullname: ""
  }

  const [show, setShow] = useState(false)
  const [data, setData] = useState(obj)
  const [msg, setMsg] = useState(obj)
  const [status, setStatus] = useState(obj)
  const [btnSubmit, setBtnSubmit] = useState(false)

  useEffect(() => {
    if (profile.fullname === "") {
      setShow(true)
    }
    setData({...data, id: profile.id})
  }, [])

  const schema = yup.object().shape({
    id: yup.string(),
    fullname: yup.string().test('fullname', 'Insira nome e sobrenome', function (value) {
      if (value.split(" ").length >= 2 && value.split(" ")[1].length >= 2) {
        return true;
      } else {
        return false;
      }
    }),
  })

  function handleChange(field, e) {
    setData({ ...data, fullname: e.target.value })
    valid(field, { ...data, [field]: e.target.value })
  }

  const valid = (field = null, obj) => {
    if (field) {
      schema.validateAt(field, obj).then(() => {
        setStatus({ ...status, [field]: "isValid" })
        setMsg({ ...msg, [field]: "" })
      }).catch(err => {
        setMsg({ ...msg, [field]: err.errors })
        setStatus({ ...status, [field]: "invalid" })
      })
    }

    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  async function updateName() {
    dispatch(updateProfileRequest(data))
    setShow(false)
  }

  return (
    <>
      <Dialog
        className={pattern.root}
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Bem Vindo ao novo site de cursos</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component='div'>
            <FormControl variant='outlined' fullWidth className={status.fullname}>
              <InputLabel htmlFor={'fullname'}>Nome Completo</InputLabel>
              <OutlinedInput
                id="fullname"
                labelWidth={120}
                value={data.fullname}
                onChange={e => handleChange('fullname', e)}
                onBlur={e => handleChange('fullname', e)}
              />
              <FormHelperText>
                {msg.fullname}
              </FormHelperText>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateName} type='submit' autoFocus disabled={!btnSubmit}>CONFIRMAR</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FirstAccess;


FirstAccess.propTypes = {
  profile: PropTypes.object,
}
