import {
  Box,
  Button,
  Dialog,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';

import useStyles from './style';

import Slide from '@material-ui/core/Slide';
import api from '~/Services/api';
import * as yup from 'yup';

const generateID = () => Math.floor(Date.now() * Math.random()).toString(36);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddPhotoModal = props => {
  const {
    openModal,
    setOpenModal,
    selectedImage,
    setSelectedImage,
    setPreviewPhoto,
    previewPhoto,
  } = props;
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [alt, setAlt] = useState('');
  const [errors, setErrors] = useState({
    title: '',
    alt: '',
    image: '',
  });
  let schema = yup.object().shape({
    file: yup
      .mixed()
      .test({
        message: 'Adicione uma foto',
        test: (file, context) => {
          if (!file.name && !selectedImage.url) return context?.createError();
          return true;
        },
      })
      .test({
        message: 'Formato de imagem inválido',
        test: (file, context) => {
          const typeImagensValid = [
            'image/jpeg',
            'image/png',
            'image/jpg',
            'image/webp',
          ];

          if (!typeImagensValid.includes(file.type) && !selectedImage.url)
            return context?.createError();

          return true;
        },
      }),
    title: yup.string().required('Campo de titulo é obrigatorio'),
    alt: yup.string().required('Campo descrição é obrigatório'),
  });

  const uploadImage = e => {
    if (e.target.files.length > 0) {
      setImage({
        file: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const { file, title, alt } = e.target;

      await schema.validate({
        file: file.files[0],
        title: title.value,
        alt: alt.value,
      });

      if (selectedImage.id) {
        const formData = new FormData();

        formData.set('alt', alt.value);
        formData.set('title', title.value);
        formData.set('file', file.files[0]);

        await api.put(`/course/${selectedImage.id}/image`, formData);

        setOpenModal(false);
        setImage('');
        setTitle('');
        setAlt('');
        setSelectedImage({});

        const newPreviewPhoto = previewPhoto.map(photo => {
          if (photo.id === selectedImage.id) {
            if (file.name) {
              photo.file = file;
              photo.url = URL.createObjectURL(file);
            }
            photo.alt = alt.value;
            photo.title = title.value;
          }
          return photo;
        });
        setPreviewPhoto(newPreviewPhoto);
        return;
      }

      setPreviewPhoto(prev => [
        ...prev,
        {
          id: generateID(),
          url: URL.createObjectURL(file.files[0]),
          file: file.files[0],
          title: title.value,
          alt: alt.value,
        },
      ]);
      setOpenModal(false);
    } catch (error) {
      setErrors({ [error.path]: error.message });

      return;
    }
  };

  const style = useStyles();

  useEffect(() => {
    if (selectedImage) {
      setTitle(selectedImage.title);
      setAlt(selectedImage.alt);
      setImage(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (!openModal) {
      setImage('');
      setTitle('');
      setAlt('');
      setSelectedImage({});
    }
  }, [openModal]);

  return (
    <Dialog open={openModal} TransitionComponent={Transition} keepMounted>
      <form className={style.content} onSubmit={handleFormSubmit}>
        <Box className={style.headerModal}>
          <Box className={style.title}>
            <h2>Adicionar Foto</h2>
            <button
              type="button"
              className={style.buttonAddPhoto}
              onClick={() => setOpenModal(false)}
            >
              X
            </button>
          </Box>
        </Box>
        <Box className="previewImage">
          <img
            src={`${image.preview ??
              selectedImage?.url ??
              'https://dummyimage.com/170x100/FFFFFF/lorem-ipsum.png&text=preview'}`}
            width={'100%'}
            height={'100px'}
            alt="preview"
          ></img>
        </Box>
        <Box className={style.boxInput}>
          <label htmlFor="file">Imagem</label>
          <input
            className={style.inputModal}
            type="file"
            id="file"
            name="file"
            accept="image/*"
            onChange={uploadImage}
          />
          {errors.image && (
            <FormHelperText error>{errors.image}</FormHelperText>
          )}
          <input
            type="text"
            id="id"
            name="id"
            hidden
            value={selectedImage?.id || ''}
            readOnly
          />
        </Box>
        <Box className={style.bodyModal} style={{ width: '100%' }}>
          <FormGroup className={style.formGroupModal}>
            <Box className={style.boxInputModal}>
              <label htmlFor="title">Titulo:</label>
              <input
                className={style.ModalInput}
                value={title}
                type="text"
                id="title"
                name="title"
                placeholder="titulo"
                onChange={e => setTitle(e.target.value)}
              />
              {errors.title && (
                <FormHelperText error>{errors.title}</FormHelperText>
              )}
            </Box>
            <Box className={style.boxInputModal}>
              <label htmlFor="alt">Descrição:</label>
              <input
                className={style.ModalInput}
                type="text"
                id="alt"
                name="alt"
                placeholder="descrição"
                value={alt}
                onChange={e => setAlt(e.target.value)}
              />
              {errors.alt && (
                <FormHelperText error>{errors.alt}</FormHelperText>
              )}
            </Box>
          </FormGroup>
        </Box>
        <Box className={style.footerModal}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={style.button}
          >
            {selectedImage?.id !== '' ? 'Adicionar' : 'Editar'}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};

export default AddPhotoModal;
