import React from 'react';
import useStyles from './style'

import { Button, Box } from '@material-ui/core';

export default function MyCourses({ title, courses, linksAcesseOngoingCourse, linksAcesseCompletedCourse }) {
  const classes = useStyles()

  const { coursesProgress, completedCourses } = courses

  return (
    <>
      <h1>{title}</h1>
      <Box className={classes.courses}>
        <Box className={classes.coursesStatus}>
          <h3>Cursos em andamento</h3>
          <Box className={classes.coursesQuantaty}>
            {coursesProgress && coursesProgress}
          </Box>
          <Button href={linksAcesseOngoingCourse}>Ver cursos</Button>
        </Box>
        <Box className={classes.coursesStatus}>
          <h3>Cursos realizados</h3>
          <Box className={classes.coursesQuantaty}>
            {completedCourses && completedCourses}
          </Box>
          <Button href={linksAcesseCompletedCourse}>Ver cursos</Button>
        </Box>
      </Box>
    </>
  );
}
