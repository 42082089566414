import React, { useEffect, useState } from 'react';

import Header from '~/Components/Header';

import useStylesCard from '~/Components/CardCourse/style'
import globalStyles from '~/Assets/css/globalStyles';

import api from '~/Services/api';
// import useStyles from '~/Pages/Home/styles';
import { Grid, Avatar, CardContent, Card, Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CardCourse from '~/Components/CardCourse';

export default function CourseForCategory({ match }) {

  const [courses, setCourses] = useState([])
  const [notFound, setNotFound] = useState(false)
  const pattern = globalStyles()
  const classes = ''
  const classeCard = useStylesCard()

  useEffect(() => {
    requestCourses()
  }, [])

  const requestCourses = async () => {
    const res = await api.get(`view/filterPerCategory/${match.params.name}`);
    if (res.data.error) {
      return setNotFound(true)
    }
    for (let course of res.data) {
      const cat = await api.get(`view/categoryPerCourse/${course.id}`)
      course.categories = cat.data
    }
    setCourses(res.data)
  }

  return (
    <>
      <Header />
      <div className={pattern.content}>
        <Container maxWidth='lg' className={classes.containerCourse}>
          {notFound ?
            <div >
              <p> Nenhum curso encontrado com a categoria <strong>{match.params.name}</strong> ! </p>
            </div>
            :
            <Grid container className={classes.gridContainerCourse}>
              {courses.length > 0 ?
                courses.map(course => (
                  <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItemCourse} style={{ marginBottom: 130 }} key={course.id}>
                    <div style={{ width: '100%' }}>
                      <CardCourse course={course} />
                    </div>
                  </Grid>
                ))
                :
                [0, 1, 2, 3, 4, 5, 6, 7].map(course => (
                  <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItemCourse} key={course} style={{ marginBottom: 60 }}>
                    <div style={{ width: '100%' }}>
                      <Card className={`${classeCard.card}`} style={{ background: '#fff', height: 390, borderTopColor: '#ccc' }}>
                        <Avatar className={classeCard.avatarCard}>
                          <Skeleton variant='circle' width={140} height={140} />
                        </Avatar>
                        <CardContent className={classeCard.contentCard} style={{ flexDirection: 'column', position: 'relative', height: 'fit-content', top: 75 }}>
                          <div style={{ padding: '10px 20px', height: 'fit-content' }}>
                            <Skeleton variant='text' width={'100%'} height={60} />
                          </div>

                          <div style={{ padding: '0px 20px', height: 'fit-content' }}>
                            <Skeleton variant='text' width={'100%'} height={50} />
                          </div>

                          <div style={{ display: 'flex', height: 'fit-content' }}>
                            <div style={{ flex: 1, padding: '10px 20px' }}>
                              <p><Skeleton variant='text' height={35} /></p>
                              <p><Skeleton variant='text' height={22} /></p>
                            </div>
                            <div style={{ flex: 1, padding: '10px 20px' }}>
                              <p><span><Skeleton variant='text' height={35} /></span></p>
                              <p><span><Skeleton variant='text' height={22} /></span></p>
                            </div>
                          </div>

                          <div style={{ padding: '15px 60px', height: 'fit-content' }}>
                            <Skeleton variant='text' width={'100%'} height={50} />
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                ))}
            </Grid>
          }
        </Container>
      </div>
    </>
  );
}
