export function signInRequest(cpf, password) {
  return {
     type: '@auth/SIGN_IN_REQUEST',
     payload: { cpf, password}
  }
}

export function signInSuccess(token, user){
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user}
  }
}

export function signUpSuccess(token, user){
  return {
    type: '@auth/SIGN_UP_SUCCESS',
    payload: { token, user}
  }
}

export function signUpRequest(fullname, cpf, email, telephone, cellphone, password){
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      fullname,
      cpf,
      email,
      telephone,
      cellphone,
      password,
    }
  }
}

export function signFailure(){
  return {
    type: '@auth/SIGN_FAILURE',
  }
}

export function signUpFailure(){
  return {
    type: '@auth/SIGN_UP_FAILURE',
  }
}

export function signReset(){
  return {
    type: '@auth/SIGN_RESET',
  }
}

export function signOut(){
  return {
    type: '@auth/SIGN_OUT',
  }
}

export function signInExpired(){
  return {
    type: '@auth/SIGN_IN_EXPIRED',
  }
}
