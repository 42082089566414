import React from 'react'
import * as yup from 'yup'
import { useState } from 'react'
import globalStyles from '~/Assets/css/globalStyles'
import { Grid, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import api from '~/Services/api'
import { CustomButton, InputForm } from '../Custom'
import { makeStyles } from '@material-ui/styles'


const styles = makeStyles(theme => ({
  root: {
    '& .alertPassword': {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',

      '& > span': {
        display: 'block',
        overflow: 'hidden',
        padding: 0,
        borderRadius: 5,
        background: '#64cb64',
        color: '#fff',
        fontSize: 18,
        transition: 'width .5s',
        width: '0%',

        '&.show': {
          width: '100%',
          padding: 15,
        },

        '& span': {
          display: 'block',
          color: '#fff',
          whiteSpace: 'nowrap',

          '& a': {
            color: '#fff',
            fontWeight: 'bold',
            marginLeft: 5,

            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    }
  }
}))

const NewPassword = ({ token }) => {

  const pattern = globalStyles()
  const classes = styles()

  const [data, setData] = useState({
    password: "",
    confirmPassword: ""
  })

  const [alertPassword, setAlertPassword] = useState({ show: false })

  const [msg, setMsg] = useState({
    password: "",
    confirmPassword: ""
  })

  const [status, setStatus] = useState({
    password: "",
    confirmPassword: ""
  })

  const [btnSubmit, setBtnSubmit] = useState(false)

  const [events, setEvents] = useState({
    showPassword: false,
    showConfirmPassword: false,
  })

  const schema = yup.object(data).shape({
    password: yup.string().required("Campo Nova Senha obrigatório").min(6, "No mínimo 6 caracteres"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Senhas não conferem!').required("Campo Confirmar Nova Senha obrigatório").min(6, "No mínimo 6 caracteres")
  })

  const handleValues = (field, e) => {
    setData({ ...data, [field]: e.target.value })
    valid(field, { ...data, [field]: e.target.value })
  }

  const valid = (field, obj) => {
    schema.validateAt(field, obj).then(() => {
      setMsg({ ...msg, [field]: "" })
      setStatus({ ...status, [field]: "isValid" })
    }).catch(err => {
      setMsg({ ...msg, [field]: err.errors })
      setStatus({ ...status, [field]: "invalid" })
    })

    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  const handleClickShowPassword = field => {
    setEvents({ ...events, [field]: !events[field] });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const editPassword = async e => {
    e.preventDefault()
    const obj = { password: data.password, token }
    const res = await api.post('/reset_password', obj)

    if (res.data.message === "Password updated") {
      setAlertPassword({ show: true })
    } else {
      setAlertPassword({ show: false })
    }
  }

  return (
    <>
      <form onSubmit={editPassword} className={`${classes.root} ${pattern.root}`}>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <p className="alertPassword">
              <span className={`${alertPassword.show && 'show'}`}>
                <span>Senha Atualizada com sucesso.</span>
                <span>Volte para a tela de login clicando
                  <a href="/">AQUI</a></span>
              </span>
            </p>
          </Grid>

          <Grid item xs={12}>
            <h4 className={pattern.titleForm}>Recuperação de Senha</h4>
          </Grid>

          <Grid item xs={12} md={12}>
            <InputForm
              className={status.password}
              fullWidth
            >
              <InputLabel htmlFor="password" variant="outlined">Nova Senha</InputLabel>
              <OutlinedInput
                id="password"
                type={events.showPassword ? 'text' : 'password'}
                value={data.password}
                onChange={e => handleValues('password', e)}
                onBlur={e => handleValues('password', e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword('showPassword')
                      }
                      onMouseDown={() => handleMouseDownPassword}
                      edge="end"
                    >
                      {events.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
              />
              <FormHelperText>{msg.password}</FormHelperText>
            </InputForm>
          </Grid>

          <Grid item xs={12} md={12}>
            <InputForm
              className={status.confirmPassword}
              fullWidth
            >
              <InputLabel htmlFor="confirmPass" variant="outlined" >Confirmar Nova Senha</InputLabel>
              <OutlinedInput
                id="confirmPass"
                type={events.showConfirmPass ? 'text' : 'password'}
                value={data.confirmedPassword}
                onChange={e => handleValues('confirmPassword', e)}
                onBlur={e => handleValues('confirmPassword', e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword('showConfirmPass')
                      }
                      onMouseDown={() => handleMouseDownPassword}
                      edge="end"
                    >
                      {events.showConfirmPass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={165}
              />
              <FormHelperText>{msg.confirmPassword}</FormHelperText>
            </InputForm>
          </Grid>

          <Grid item xs={12}>
            <CustomButton bg_hover="#64cb64" bg="#6ab36a" type="submit" fullWidth disabled={!btnSubmit}> cadastrar nova senha </CustomButton>
          </Grid>

        </Grid>
      </form>
    </>
  )
}

export default NewPassword
