import React, { useState, useEffect } from 'react';

import api from '~/Services/api';
import {
  FormControl,
  InputLabel,
  Container,
  Grid,
  Select,
  OutlinedInput,
  Button,
  FormHelperText,
} from '@material-ui/core';
import globalStyles from '~/Assets/css/globalStyles';
import useStyles from './style';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import * as yup from 'yup';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { CustomButton } from '~/Components/Custom';

import AddPhotoModal from '~/Components/Modals/AddPhotoModal';
import { Edit,Close } from '@material-ui/icons';

moment.locale('pt-br');

const priceMask = props => {
  const { inputRef, value, ...other } = props;
  let mask = [];

  if (value.length >= 10) {
    mask = ['R', '$', ' ', /[0-9]/, '.', /\d/, /\d/, /\d/, ',', /\d/, /\d/];
  } else if (value.length === 9) {
    mask = ['R', '$', ' ', /[0-9]/, /\d/, /\d/, ',', /\d/, /\d/, /\d/];
  } else {
    mask = ['R', '$', ' ', /[0-9]/, /\d/, ',', /\d/, /\d/, /\d/, /\d/];
  }
  return (
    <MaskedInput
      value={value}
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      placeholderChar={'\u2000'}
      showMask
      mask={mask}
    />
  );
};

function FormCourses({ objData, images }) {
  const classes = useStyles();
  const globalClass = globalStyles();
  const [teacher, setTeacher] = useState([]);
  const [category, setCategory] = useState([]);
  const [labelBtn, setLabelBtn] = useState('...');
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState([]);

  const objDialog = {
    status: false,
    name: '',
    msg: '',
  };
  const [dialog, setDialog] = useState(objDialog);

  const obj = {
    name: '',
    description: '',
    date_of_course: '',
    hour_start: '',
    hour_end: '',
    is_pratical: '',
    price: '0',
    quantity: 0,
    totalQuantity: '',
    teacher_id: '',
    days_for_publication: '',
    period: '',
    donation: '',
    category: [],
    status: 'not-publicated',
    reason_canceled: '',
    file: [],
  };
  const [data, setData] = useState(obj);

  //Status for input
  const objStatus = {
    name: '',
    description: '',
    date_of_course: '',
    hour_start: '',
    hour_end: '',
    is_pratical: '',
    price: 'isValid',
    reason_canceled: '',
    totalQuantity: '',
    teacher_id: '',
    days_for_publication: '',
    period: '',
    donation: '',
    category: '',
  };
  const [status, setStatus] = useState(objStatus);

  //Message for inputs
  const objMsg = {
    name: '',
    description: '',
    date_of_course: '',
    hour_start: '',
    hour_end: '',
    is_pratical: '',
    price: '',
    reason_canceled: '',
    totalQuantity: '',
    teacher_id: '',
    days_for_publication: '',
    period: '',
    donation: '',
    category: '',
    file: [],
  };

  const [msg, setMsg] = useState(objMsg);

  const alterData = () => {
    setData(objData);
  };

  const handleClickOpen = (status, name, msg) => {
    if (status === 'sucess') {
      setDialog({ status: !dialog[status], name, msg });
    }
  };

  const handleClose = () => {
    setDialog(objDialog);
  };

  const handleChangeCategory = event => {
    setData({ ...data, category: event.target.value });
    valid('category', { ...data, category: event.target.value });
  };

  // functions for handle change inputs
  const handleChange = (field, e) => {
    setData({ ...data, [field]: e.target.value });
    valid(field, { ...data, [field]: e.target.value });
  };

  //Schema do yup
  let schema = yup.object().shape({
    name: yup.string().required('Este campo é obrigatório'),
    teacher_id: yup.string().required('Este campo é obrigatório'),
    days_for_publication: yup.string().required('Este campo é obrigatório'),
    description: yup.string().required('Este campo é obrigatório'),
    is_pratical: yup.string().required('Este campo é obrigatório'),
    price: yup.string().required('Este campo é obrigatório'),
    donation: yup
      .string()
      .when('price', (price, field) =>
        price === 'R$ 0' || price === ''
          ? field.required('Este campo é obrigatório')
          : field
      ),
    quantity: yup.string(),
    totalQuantity: yup.string().required('Este campo é obrigatório'),
    date_of_course: yup.string().required('Este campo é obrigatório'),
    hour_start: yup.string().required('Este campo é obrigatório'),
    hour_end: yup.string().required('Este campo é obrigatório'),
    period: yup.string().required('Este campo é obrigatório'),
    status: yup.string().required('Este campo é obrigatório'),
    reason_canceled: yup
      .string()
      .when('status', (status, f) =>
        status === 'canceled'
          ? f.required('Este campo é obrigatório').min(5, 'Mínimo 5 caracteres')
          : f
      ),
    category: yup.string().required('Este campo é obrigatório'),
    file: yup.string(),
  });

  // functions for teacher
  async function requestTeacher() {
    const res = await api.get('users?type_user=teacher&limit=all');
    setTeacher(res.data);
  }

  // functions for category
  async function requestCategory() {
    const res = await api.get('view/category/all');
    setCategory(res.data);
  }

  function createCourse(e) {
    e.preventDefault();
    schema.isValid(data).then(async function(valid) {
      if (valid) {
        const zeros = [
          'R$ ',
          'R$ 0',
          'R$ 00',
          'R$ 00,',
          'R$ 00,0',
          'R$ 00,00',
          'R$ 000,00',
          'R$ 0.000,00',
        ];
        if (zeros.includes(data.price)) {
          data.price = '0';
        }

        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('date_of_course', data.date_of_course);
        formData.append('hour_start', data.hour_start);
        formData.append('hour_end', data.hour_end);
        formData.append('is_pratical', data.is_pratical);
        formData.append('price', data.price);
        formData.append('quantity', data.quantity);
        formData.append('totalQuantity', data.totalQuantity);
        formData.append('teacher_id', data.teacher_id);
        formData.append('days_for_publication', data.days_for_publication);
        formData.append('period', data.period);
        formData.append('donation', data.donation);
        formData.append('category', data.category);
        formData.append('status', data.status);
        formData.append('reason_canceled', data.reason_canceled);

        Array.from(previewPhoto).forEach(image => {
          if (image?.file) {
            formData.append('file', image.file);
            formData.append('title', image.title);
            formData.append('alt', image.alt);
          }
        });

        if (objData && objData.length !== 0) {
          const res = await api.put(`course/${objData.id}`, formData);
          if (res.status === 200) {
            handleClickOpen('sucess', 'Curso Atualizado com sucesso', '');
            setPreviewPhoto(res.data.images);
          }
        } else {
          const res = await api.post('course', formData);
          setBtnSubmit(true);
          if (res.status === 200) {
            handleClickOpen(
              'sucess',
              'Curso Cadastrado com sucesso',
              `O curso ${res.data.name} foi cadastrado com sucesso`
            );
            // handleClickOpen('sucess', res.data.name, 'Top')
            setData(obj);
            setStatus(objStatus);
            setMsg(objMsg);
            setBtnSubmit(false);
          }
        }
      }
    });
  }

  async function handleDeleteImageById(id, imagepreview) {
    try {
      if (imagepreview?.file?.name) {
        setPreviewPhoto(prev => prev.filter(image => image.id !== id));
        return;
      }
      await api.delete(`course/${id}/image`);

      const imageData = previewPhoto.filter(image => image.id !== id);
      setPreviewPhoto(imageData);
    } catch (error) {
      console.log(error);
    }
  }

  const valid = (field, obj) => {
    schema
      .validateAt(field, obj)
      .then(() => {
        setMsg({ ...msg, [field]: '' });
        setStatus({ ...status, [field]: 'isValid' });
      })
      .catch(err => {
        setMsg({ ...msg, [field]: err.errors });
        setStatus({ ...status, [field]: 'invalid' });
      });

    schema
      .validate(obj)
      .then(() => {
        setBtnSubmit(true);
      })
      .catch(err => {
        setBtnSubmit(false);
      });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (images && images.length >= 0) {
      if (images.length === 0) {
        setPreviewPhoto('');
      } else {
        setPreviewPhoto(images);
      }
    }
  }, [images]);

  useEffect(() => {
    if (objData) {
      if (objData.id) {
        setLabelBtn('Atualizar');
        alterData();
        schema
          .validate(objData)
          .then(res => {
            setBtnSubmit(true);
          })
          .catch(err => setBtnSubmit(false));
      }
    } else {
      setLabelBtn('Cadastrar');
    }
  }, [objData]);

  useEffect(() => {
    requestTeacher();
    requestCategory();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <>
      <AddPhotoModal
        openModal={openModal}
        setPreviewPhoto={setPreviewPhoto}
        setOpenModal={setOpenModal}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        previewPhoto={previewPhoto}
      />
      <Container maxWidth="lg" className={classes.container}>
        <form
          onSubmit={e => createCourse(e)}
          className={`${classes.root} ${globalClass.root}`}
        >
          <Grid container spacing={3}>
            {/* Teacher */}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.teacher_id}
              >
                <InputLabel>Professor*</InputLabel>
                <Select
                  native
                  labelId="teacherCourse"
                  id="teacherCourse"
                  value={data.teacher_id}
                  onChange={e => handleChange('teacher_id', e)}
                  onBlur={e => handleChange('teacher_id', e)}
                  labelWidth={80}
                  className={classes.inputBg}
                >
                  <option aria-label="None" value="" />
                  {teacher.map(t => {
                    return (
                      <option key={t.id} value={t.id}>
                        {t.fullname}
                      </option>
                    );
                  })}
                </Select>
                <FormHelperText>{msg.teacher_id}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Name */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" className={status.name}>
                <InputLabel htmlFor="nameCourse">Nome do curso</InputLabel>
                <OutlinedInput
                  id="nameCourse"
                  labelWidth={110}
                  value={data.name}
                  onChange={e => handleChange('name', e)}
                  onBlur={e => handleChange('name', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.name}</FormHelperText>
              </FormControl>
            </Grid>

            {/* Days for publication */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.days_for_publication}
              >
                <InputLabel htmlFor="daysForPublication">
                  Dias para publicação
                </InputLabel>
                <OutlinedInput
                  id="daysForPublication"
                  labelWidth={150}
                  type="number"
                  value={data.days_for_publication}
                  onChange={e => handleChange('days_for_publication', e)}
                  onBlur={e => handleChange('days_for_publication', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.days_for_publication}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Category */}
            <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={status.category}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  Categoria*
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={data.category}
                  onChange={handleChangeCategory}
                  renderValue={selected => selected.join(', ')}
                  MenuProps={MenuProps}
                  labelWidth={80}
                  className={classes.inputBg}
                >
                  {category.map(c => (
                    <MenuItem
                      key={c.id}
                      value={`${c.name}`}
                      data-id={`${c.id}`}
                    >
                      <Checkbox
                        checked={data.category?.indexOf(`${c.name}`) > -1}
                      />
                      <ListItemText primary={c.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{msg.category}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Price */}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.price}
              >
                <InputLabel htmlFor="priceCourse">Preço*</InputLabel>
                <OutlinedInput
                  id="priceCourse"
                  labelWidth={50}
                  type="text"
                  value={data.price}
                  inputComponent={priceMask}
                  placeholder={'00,00'}
                  onChange={e => handleChange('price', e)}
                  onBlur={e => handleChange('price', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.price}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Donation */}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.donation}
              >
                <InputLabel htmlFor="donationCourse">
                  Item para doação
                </InputLabel>
                <OutlinedInput
                  id="donationCourse"
                  labelWidth={130}
                  value={data.donation}
                  onChange={e => handleChange('donation', e)}
                  onBlur={e => handleChange('donation', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.donation}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Data */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.date_of_course}
              >
                <InputLabel
                  htmlFor="dateCourse"
                  className={classes.bgLabel}
                  shrink
                >
                  data
                </InputLabel>
                <OutlinedInput
                  id="dateCourse"
                  labelWidth={40}
                  value={moment(data.date_of_course).format('YYYY-MM-DD')}
                  type="date"
                  onChange={e => handleChange('date_of_course', e)}
                  onBlur={e => handleChange('date_of_course', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.date_of_course}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Period */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.period}
              >
                <InputLabel>Período</InputLabel>
                <Select
                  native
                  labelId="periodCourse"
                  id="periodCourse"
                  labelWidth={80}
                  value={data.period}
                  onChange={e => handleChange('period', e)}
                  onBlur={e => handleChange('period', e)}
                  className={classes.inputBg}
                >
                  {' '}
                  <option aria-label="None" value="" />
                  <option value={`manha`}>Manhã</option>
                  <option value={`tarde`}>Tarde</option>
                  <option value={`sabado`}>Sábado</option>
                  <option value={`integral`}>Integral</option>
                </Select>
                <FormHelperText>{msg.period}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Quantity */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.totalQuantity}
              >
                <InputLabel htmlFor="numberQuantityCourse">
                  Número de vagas
                </InputLabel>
                <OutlinedInput
                  id="numberQuantityCourse"
                  labelWidth={150}
                  type="number"
                  value={data.totalQuantity}
                  onChange={e => handleChange('totalQuantity', e)}
                  onBlur={e => handleChange('totalQuantity', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.totalQuantity}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.status}
              >
                <InputLabel id="statusCourse">Status</InputLabel>
                <Select
                  native
                  labelId="statusCourse"
                  id="statusCourse"
                  labelWidth={50}
                  value={data.status}
                  onChange={e => handleChange('status', e)}
                  onBlur={e => handleChange('status', e)}
                  className={classes.inputBg}
                >
                  {' '}
                  <option aria-label="None" value="" />
                  <option value={`public`}>publicar</option>
                  <option value={`not-publicated`}>Despublicar</option>
                  <option value={`canceled`}>Cancelar</option>
                </Select>
                <FormHelperText>{msg.status}</FormHelperText>
              </FormControl>
            </Grid>

            {/* Description */}
            {data.status === 'canceled' ? (
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={status.reason_canceled}
                >
                  <InputLabel htmlFor="reason_canceled">
                    Motivo do Cancelamento
                  </InputLabel>
                  <OutlinedInput
                    id="reason_canceled"
                    labelWidth={140}
                    value={data.reason_canceled}
                    onChange={e => handleChange('reason_canceled', e)}
                    onBlur={e => handleChange('reason_canceled', e)}
                    multiline={true}
                    rows={5}
                    className={classes.inputBg}
                  />
                  <FormHelperText>{msg.description}</FormHelperText>
                  {/* Button */}
                </FormControl>
              </Grid>
            ) : (
              ''
            )}

            {/* Hour Start */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.hour_start}
              >
                <InputLabel
                  htmlFor="hourStartCourse"
                  className={classes.bgLabel}
                  shrink
                >
                  Horário de Início
                </InputLabel>
                <OutlinedInput
                  id="hourStartCourse"
                  labelWidth={140}
                  type="time"
                  value={data.hour_start}
                  onChange={e => handleChange('hour_start', e)}
                  onBlur={e => handleChange('hour_start', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.hour_start}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Hour End */}
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={status.hour_end}
              >
                <InputLabel
                  htmlFor="hourEndCourse"
                  className={classes.bgLabel}
                  shrink
                >
                  Horário de Termino
                </InputLabel>
                <OutlinedInput
                  id="hourEndCourse"
                  labelWidth={140}
                  type="time"
                  value={data.hour_end}
                  onChange={e => handleChange('hour_end', e)}
                  onBlur={e => handleChange('hour_end', e)}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.hour_end}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Is pratical */}
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                className={status.is_pratical}
              >
                <InputLabel>Curso prático?</InputLabel>
                <Select
                  native
                  labelId="praticalCourse"
                  id="praticalCourse"
                  labelWidth={110}
                  value={data.is_pratical}
                  onChange={e => handleChange('is_pratical', e)}
                  onBlur={e => handleChange('is_pratical', e)}
                  className={classes.inputBg}
                >
                  <option aria-label="None" value="" />
                  <option value={1}>Sim</option>
                  <option value={0}>Não</option>
                </Select>
                <FormHelperText>{msg.is_pratical}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                className={status.description}
              >
                <InputLabel shrink>Descrição do curso</InputLabel>
                <OutlinedInput
                  id="descriptionCourse"
                  labelWidth={140}
                  value={data.description}
                  onChange={e => handleChange('description', e)}
                  onBlur={e => handleChange('description', e)}
                  multiline={true}
                  rows={5}
                  className={classes.inputBg}
                />
                <FormHelperText>{msg.description}</FormHelperText>
                {/* Button */}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                className={status.description}
              >
                <button
                  type="button"
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className={classes.inputBg}
                >
                  Adicionar Foto
                </button>
              </FormControl>
            </Grid>
            {/* preview photo */}
            <Grid item xs={12} className={classes.containerRecipe}>
              {[...previewPhoto].map((image, index) => (
                <div
                  key={index}
                  className={classes.recipes}
                >
                  <img
                    src={image.url || image}
                    alt={image?.alt ? image?.alt : 'imagem da receita'}
                    title={image?.title ? image?.title : 'imagem da receita'}
                    className={classes.recipeImg}
                  />
                  <div
                    className={classes.recipeActions}
                  >
                    <button
                      className={{...classes.recipeAction,...classes.recipeActionDelete}}
                      type="button"
                      onClick={() => handleDeleteImageById(image.id, image)}
                    >
                      <Close width={5} height={5}/>
                    </button>
                    <button
                      className={{...classes.recipeAction,...classes.recipeActionEdit}}
                      type="button"
                      onClick={() => {
                        setOpenModal(true);
                        setSelectedImage(image);
                      }}
                    >
                      <Edit width={5} height={5} />
                    </button>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                bg="#419a58"
                bg_hover="#419a58cc"
                disabled={!btnSubmit}
                fullWidth
                type="submit"
              >
                {labelBtn}
              </CustomButton>
            </Grid>

            {/* Dialog */}
            <Dialog
              open={dialog.status}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{dialog.name}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialog.msg}
                </DialogContentText>
              </DialogContent>
              {objData && objData.length !== 0 ? (
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="primary"
                    autoFocus
                  >
                    Ok
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions>
                  <Button
                    href={'/admin/novo-curso'}
                    color="primary"
                    variant="contained"
                  >
                    Dashboard
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="secondary"
                    autoFocus
                  >
                    Novo cadastro
                  </Button>
                </DialogActions>
              )}
            </Dialog>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default FormCourses;
