import { Box, Hidden } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CourseCard from '~/Components/CourseCard'
import api from '~/Services/api'
import { store } from '~/store'
import Profile from '~/Components/Profile'

import useStyles from './style'

const OngoingCourses = (props) => {
  const style = useStyles()

  const { profile } = store.getState().user

  const [courses, setCourses] = useState([])

  const getOngoingCourses = async () => {
    const res = await api.get(`/student/my_subscribes/${profile.id}`)
    setCourses(res.data.course)
  }

  useEffect(() => {
    getOngoingCourses()
  }, [])

  return (
    <Profile title="Cursos em andamento">
      <Hidden smDown>
        <Box className={style.container}>
          {courses.map(course => <CourseCard key={course.id} course={course} pageCourse="cursos em andamento" />)}
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box className={style.carroselMobile}>
          <Box display='flex' className="area_carousel courses">
            {courses.map(c => (
              <CourseCard course={c} key={c.id} pageCourse="cursos em andamento" />
            ))}
          </Box>
        </Box>
      </Hidden>
    </Profile>
  )
}

export default OngoingCourses
