import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import globalStyles from '~/Assets/css/globalStyles';
import { InputLabel, OutlinedInput, FormHelperText, FormControl, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import api from '~/Services/api';
import { CustomButton } from '../Custom';

const FormCategory = ({ id }) => {
  const pattern = globalStyles();

  const [category, setCategory] = useState({
    name: "",
    image_url: "",
    image: []
  })

  const [btnSubmit, setBtnSubmit] = useState(false)

  const [msg, setMsg] = useState({
    name: "",
  })

  const [status, setStatus] = useState({
    name: "",
  })

  const [alertModal, setAlertModal] = useState({
    show: false,
    title: '',
    content: ''
  })

  useEffect(() => {
    if (id) {
      getCategory()
    }
  }, [])

  async function getCategory() {
    const res = await api.get(`category/${id}`)
    setCategory({ ...category, name: res.data.name, image_url: res.data.image_url || "" })
  }

  let schema = yup.object().shape({
    name: yup.string().required("Campo Obrigatório").min(4, "Mínimo 4 Letras!"),
    image_url: yup.string(),
    image: yup.string()
  });

  const handleValues = (field, e) => {
    setCategory({ ...category, [field]: e.target.value })
    valid(field, { ...category, [field]: e.target.value })
  }

  const valid = (field, obj) => {
    schema.validateAt(field, obj).then(() => {
      setMsg({ ...msg, [field]: "" })
      setStatus({ ...status, [field]: "isValid" })
    }).catch(err => {
      setMsg({ ...msg, [field]: err.errors })
      setStatus({ ...status, [field]: "invalid" })
    })
    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  const uploadImage = e => {
    const val = e.target.files[0] ? e.target.files[0] : []
    setCategory({ ...category, image: val })
    valid('image', { ...category, image: e.target.files[0] })
  }

  function handleModal() {
    setAlertModal({ ...alertModal, show: !alertModal.show })
  }

  const addCategory = async e => {
    e.preventDefault()
    const obj = new FormData()
    obj.append('name', category.name)
    obj.append('file', category.image)
    if (id) {
      const res = await api.put(`category/${id}`, obj)
      setAlertModal({
        show: !alertModal.show,
        title: `Atualização de categoria`,
        content: `Categoria atualizada com sucesso !`,
      })
      getCategory()
      return
    }
    const res = await api.post(`category/`, obj)
      .then(
        res => {
          setAlertModal({
            show: !alertModal.show,
            title: `Cadastro de categoria`,
            content: `Categoria cadastrada com sucesso !`,
          })
          setCategory({
            name: "",
            image_url: "",
            image: []
          })
          setMsg({ name: '' })
          setStatus({ name: '' })
          setBtnSubmit(false)
        }
      )
      .catch(err => {
        setAlertModal({
          show: !alertModal.show,
          title: `Cadastro de categoria`,
          content: `Categoria já existente!`,
        })
        setBtnSubmit(false)
      })
  }
  return (
    <>
      <form onSubmit={addCategory} className={pattern.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={pattern.circleImage}>
              {id ?
                <img src={category.image_url} alt={`Categoria ${category.name}`} />
                : ""}
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 className={pattern.titleForm}>{id ? "Editar" : "Adicionar"} Categoria </h4>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" className={status.name}>
              <InputLabel id="nameCategory" variant="outlined">Nome Categoria</InputLabel>
              <OutlinedInput
                value={category.name}
                htmlFor="nameCategory"
                labelWidth={120}
                onChange={e => handleValues("name", e)}
                onBlur={e => handleValues("name", e)}
              >
              </OutlinedInput>
              <FormHelperText>{msg.name}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '15px' }}>
            <div className={pattern.inputFile}>
              <label htmlFor="imageCategory">
                <Button variant="contained" component="span">Imagem</Button>
              </label>
              <input
                onChange={e => uploadImage(e)}
                accept="image/*"
                style={{ display: 'none' }}
                id="imageCategory"
                type="file"
              />
              <label
                htmlFor={'imageCategory'}
                className={`labelNameImage`}
              >
                {category.image.name ? category.image.name : "Selecionar imagem ..."}
              </label>
            </div>
          </Grid>
          <Grid item xs={12}>
            <CustomButton
              type="submit"
              id={'btnAddCategory'}
              fullWidth
              disabled={!btnSubmit}
              bg={"#419a58"}
              bg_hover={"#419a58cc"}
            >
              {id ? "Atualizar Categoria" : "Cadastrar Categoria"}
            </CustomButton>
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={alertModal.show}
        onClose={handleModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{alertModal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alertModal.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModal}
            color="primary"
            style={{
              background: 'green',
              color: '#fff'
            }}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default FormCategory
