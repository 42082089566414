import { createTheme, makeStyles } from "@material-ui/core/styles";

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles(theme => ({
  myCourses: {
    width: '100%',
    marginTop: 40,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: '0px 1px 6px -1px #ccc',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },

  titleMyCourses: {
    backgroundColor: '#b01010',
    color: '#fff',
    padding: 10,
    textAlign: 'center',
    fontSize: 25,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },

  itemMyCourse: {
    display: 'flex',
    padding: '5px 10px',
    justifyContent: 'space-between',
    '& span, a': {
      display: 'flex',
    }
  },

  dataMyCourse: {
    paddingRight: 10,
    fontSize: 20,
    fontWeight: 'bold'
  },

  nameMyCourse: {
    color: '#b01010',
    fontSize: 22,
  },

  courses: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: 419,
    width: '100%',
    height: 138,
  },

  coursesStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& h3': {
      fontSize: 18,
      fontWeight: '400',
      marginBottom: 16,
      color: '#271E1E',
      textAlign: 'center',

      [responsive.breakpoints.down('sm')]: {
        whiteSpace: 'pre-line',
        width: 90,

      }
    },

    '& a': {
      marginTop: 16,
      padding: '6px 0',

      '& :nth-child(1)': {
        letterSpacing: 2,
        color: '#D81717',
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'initial',
        fontSize: 18,

        "&:before": {
          content: '""',
          width: '100%',
          borderBottom: '3px solid #D81717',
          position: 'absolute',
          bottom: 0,
          left: 0,
          transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
        },

        '&:hover:before': {
          animation: `$animationBottom .8s`,
        },
      },

      '&:hover': {
        background: 'none',
      }
    }
  },

  "@keyframes animationBottom": {
    '0%': {
      width: '100%'
    },
    '100%': {
      width: '0%'
    },
  },

  coursesQuantaty: {
    minWidth: 70,
    minHeight: 70,
    borderRadius: '50%',
    textAlign: 'center',
    border: '4px solid #54080B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    fontSize: 32,
    fontWeight: '700',
    color: '#54080B',
  },

}))

export default useStyles
