import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  areaBrandMenu: {
    height: '100%',
  },
  btnRegister: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: "#b01010",
      textAlign: 'center',
      color: "#fff",
      padding: '15px 10px',
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      color: "#960d0d",
      '&:hover': {
        background: '#f5f5f5'
      }
    }
  },
  btnSignIn: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      right: 10,
      top: 8,
      padding: 5,
      fontWeight: 'bold',
      border: '2px solid #b01010',
      color: '#b01010',
      borderRadius: 5,
    },
    [theme.breakpoints.up('md')]: {
      background: '#b01010',
      color: "#fff",
      '&:hover': {
        background: '#960d0d'
      }
    }
  },
  btnMenuMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      position: 'fixed',
      color: '#b01010',
      left: 0,
      top: 2,
    }
  },
  brand: {
    width: 'fit-content',
    height: '100%',
    '& img': {
      maxWidth: '100%',
      height: '100%'
    }
  },
  areaBtns: {
    display: 'flex',
    height: '100%',
    '& span': {
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        padding: '0 10px'
      }
    }
  },
  areaBrandMenu: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },
  menu: {
    display: 'flex',
    '& a': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      textDecoration: 'none !important',
      color: '#960d0d',
      '&:hover': {
        background: '#f5f5f5'
      }
    }
  },
  itemMenuMobile: {
    color: "#960d0d",
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    fontSize: 18,
    justifyContent: 'center',
    '& span': {
      paddingLeft: 15
    }
  },
  btnMenuProfile: {
    [theme.breakpoints.only('sm')]: {
      position: 'fixed',
      height: 50,
      right: 0,
    }
  },
  menuProfileDesk: {
    '& div:nth-child(3)': {
      position: 'absolute',
      top: '50px !important',
      width: 180,
    }
  },
  btnLogoutMobile: {
    color: "#b01010",
    position: 'fixed',
    right: 0,
  },
  titleRegister: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '6vw',
    }
  }
}))

export default useStyles
