import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DateTime } from 'luxon';

/*** Images ***/
import bannerNewCourses from '~/Assets/img/banner-cursos-outubro.png';
import bannerWhoSells from '~/Assets/img/banner-quem-vende.png';
import firstBannerMay from '~/Assets/img/banner-site-de-curso-1-maio23.jpg';
import secondBannerMay from '~/Assets/img/banner-site-de-curso-2-maio23.jpg';
import banner from '~/Assets/img/foto-banner-teste.jpg';
import professor from '~/Assets/img/professora.jpg';
import categoria from '~/Assets/img/exemplo-categoria.jpg';
import arrowMoreCourses from '~/Assets/img/arrow-more-courses.jpg';
import imageDepoiment from '~/Assets/img/image-depoiment.png';
import cursosLivres from '~/Assets/img/cursos-livres.png';
import custoBeneficio from '~/Assets/img/custo-beneficio.png';
import chefsRenomados from '~/Assets/img/chefs-renomados.png';

import NewHeader from '~/Components/NewHeader';
import { ContainerApp } from '~/Components/Custom';
import { Box, Hidden, Typography } from '@material-ui/core';
import useStyles from './style';

import SlideCategory from '~/Components/Slides/SlideCategory';
import SlideTeachers from '~/Components/Slides/SlideTeachers';
import SliderInfra from '~/Components/Slides/SlideInfra';
import { Link } from 'react-router-dom';
import Footer from '~/Components/Footer';
import CourseCard from '~/Components/CourseCard';
import api from '~/Services/api';
import { store } from '~/store';
import { signInExpired } from '~/store/modules/auth/actions';

import SkeletonNextCourse from '~/Utils/Skeleton/SkeletonNextCourse';
import SkeletonCourse from '~/Utils/Skeleton/SkeletonCourse';
import SkeletonCarouselCategory from '~/Utils/Skeleton/SkeletonCarouselCategory';
import SkeletonCarouselProffesor from '~/Utils/Skeleton/SkeletonCarouselProffesor';
import { Skeleton } from '@material-ui/lab';
import { ptBR } from 'date-fns/locale';
import { addDays, format } from 'date-fns';

// import BannerPrincipal from '~/Assets/img/banner_1.png'
import BannerSecondary from '~/Assets/img/banner_2.png';
// import BannerFebruary from '~/Assets/img/banner-fevereiro.jpg'
import BannerFebruary from '~/Assets/img/banner-volta-aulas.jpg';
import { useDispatch } from 'react-redux';

import TemporaryBanner from '~/Components/TemporaryBanner'

DateTime.local().setZone('America/Sao_Paulo');

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  speed: 1000,
  autoplaySpeed: 3000,
  autoplay: true,
  infinite: true,
  swipeToSlide: true,
  useTransform: false,
  arrows: false,
  accessibility: false,
};

function Home() {
  const profile = store.getState().user.profile;
  const dispatch = useDispatch();
  const [banner, setBanner] = useState([]);
  const [nextCourse, setNextCourses] = useState({});
  const [courses, setCourses] = useState([]);
  const [courseNotFound, setCourseNotFound] = useState(false);
  const [categoryNotFound, setCategoryNotFound] = useState(false);
  const [teacherNotFound, setTeacherNotFound] = useState(false);
  const [categories, setCategories] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [configNextCourse, setConfigNextCourse] = useState({
    label: 'Inscreva-se',
    bgButton: '#d91718',
    labelColor: '#fff',
  });
  const [showNextCourse, setShowNextCourse] = useState(false);
  const [isSubcribeCourse, setIsSubscribeCourse] = useState('');
  const priceFormated = nextCourse?.donation ? 'Grátis' : `${nextCourse.price}`;
  const dayCourseFormated =
    nextCourse.date_of_course &&
    format(addDays(new Date(nextCourse.date_of_course), 1), 'dd/MM/yyyy', {
      locale: ptBR,
    });

  const dayCourse = DateTime.fromISO(nextCourse?.date_of_course);
  const today = DateTime.local();
  const diff = dayCourse.diff(today, ['months', 'days']);

  const [depoiments, setDepoiments] = useState([]);

  const slideBanners = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function gifLabel() {
    let gif = true;
    const elementLabel = document.querySelector(
      '[data-giflabel="gif_label_card"]'
    );
    setInterval(() => {
      gif = !gif;
      if (elementLabel) {
        elementLabel.innerHTML = gif ? 'Últimas vagas' : 'Inscreva-se';
      }
    }, 2000);
  }

  const toCamelCase = string => {
    return string
      .split(' ')
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  async function getCategories() {
    const res = await api.get('view/category');
    setCategories(res.data);
  }

  async function getCourses() {
    const { data } = await api.get('view/home');

    if (data.length > 0) {
      if (window.innerWidth > 600) {
        setNextCourses(data.shift());
        setShowNextCourse(true);
      }
      if (data.length > 0) {
        setCourses(data);
      }
    } else {
      setCourseNotFound(true);
    }
  }

  async function getTeachers() {
    const res = await api.get('view/teacher');
    if (res.data.length > 0) {
      setTeachers(res.data);
    } else {
      setTeacherNotFound(true);
    }
  }

  const getSubscribeCourse = async () => {
    let sub;
    try {
      if (profile && nextCourse?.id) {
        sub = await api.get(
          `course_subscribe/${nextCourse.id}/student/${profile?.id}`
        );

        if (
          sub.data.message === 'registered' &&
          nextCourse.status_pratical !== 1 &&
          nextCourse.is_pratical === 1
        ) {
          setConfigNextCourse({
            label: 'Aguardando confirmação',
            bgButton: '#ffabab',
            labelColor: '#fff',
            buttonDisabled: true,
          });
          return null;
        }

        if (sub.data.message === 'registered') {
          setIsSubscribeCourse(sub.data.message);
          setConfigNextCourse({
            label: 'Você está inscrito',
            bgButton: '#419a58',
            labelColor: '#fff',
          });
        } else {
          setIsSubscribeCourse(sub.data.message);
        }
      }
      if (nextCourse.status === 'canceled') {
        setConfigNextCourse({
          label: 'Cancelado',
          bgButton: '#fff',
          labelColor: '#B01010',
          borderButton: `1px solid #B01010`,
        });
        return setTimeout(() => {
          setShowNextCourse(true);
        }, 2000);
      }
    } catch (error) {
      dispatch(signInExpired());
    }

    if (parseInt(nextCourse.quantity) === parseInt(nextCourse.totalQuantity)) {
      setConfigNextCourse({
        label: 'Esgotado',
        bgButton: '#fff',
        labelColor: '#B01010',
        borderButton: `1px solid #B01010`,
      });

      return setTimeout(() => {
        setShowNextCourse(true);
      }, 2000);
    }

    if (
      parseInt(nextCourse?.totalQuantity) - parseInt(nextCourse?.quantity) <=
        10 &&
      sub?.data?.message !== 'registered'
    ) {
      setConfigNextCourse({
        label: 'Últimas vagas',
        bgButton: '#d91718',
        labelColor: '#fff',
      });

      return setTimeout(() => {
        setShowNextCourse(true);
        gifLabel();
      }, 2000);
    }

    if (diff.days > nextCourse.days_for_publication) {
      setConfigNextCourse({
        label: 'Inscrições em breve',
        bgButton: '#ffabab',
        labelColor: '#fff',
      });
      return setTimeout(() => {
        setShowNextCourse(true);
      }, 2000);
    }
  };

  const handleSlug = name => {
    name = name.replace(/[' '&\/\\#,+()$~%.'":*?<>{}@]/g, '-').toLowerCase();
    return `${name}-${nextCourse.id}`;
  };

  const getDepoiments = async () => {
    const { data } = await api.get('depoiment?topfive=true');
    setDepoiments(data);
  };

  async function getBanner() {
    try {
      const res = await api.get('banners_active');
      setBanner(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDepoiments();
    getCourses();
    getCategories();
    getTeachers();
    getBanner();
  }, []);

  useEffect(() => {
    getSubscribeCourse();
  }, [nextCourse]);

  const style = useStyles(configNextCourse);
  return (
    <>
      <NewHeader />
      <TemporaryBanner />
      <Footer />
    </>
  );
}

export default Home;
